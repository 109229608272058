import {BlockType, IBlock} from "../core/block/block.class";
import {RhineBlock} from "../core/RhineBlock";
import {FieldType} from "../core/block/arg.class";
import {JsOrderComplete} from "../core/compiler/js/js-compiler";

function textNumberValueInput() {
  return {value: ["str", "num"]}
}

const Order = JsOrderComplete;

const textBlocks: IBlock[] = [
  {
    name: "text_input",
    type: BlockType.Output,
    color: "#71dccc",
    lines: [
      [
        {text: "文本"},
        {field: FieldType.Text, default: "Hello"},
        {text: ""},
      ],
    ],
    compiler: "'$0'",
    level: Order.DIRECT,
  },
  {
    name: "text_json",
    type: BlockType.Output,
    color: "#71dccc",
    lines: [
      [
        {text: "字典 {"},
        {field: FieldType.Text, default: "red:0,green:0,blue:0"},
        {text: "}"},
      ],
    ],
    compiler: "{$0}",
    level: Order.DIRECT,
  },
  //新增文本和状态
  {
    name: "text_content",
    type: BlockType.Output,
    color: "#84c974",
    lines: [
      [
        {text: "文本"},
        {field: FieldType.Text, default: "100"},
        {text: ""},
      ],
    ],
    compiler: "$0",
    level: Order.DIRECT,
  },
  {
    name: "text_length",
    output: 'num',
    type: BlockType.Output,
    color: "#f35393",
    lines: [[textNumberValueInput(), {text: "的长度"}]],
    compiler: "$0.length",
    level: Order.DIRECT,
  },
  {
    name: "text_isempty",
    type: BlockType.Output,
    output: "bool",
    color: "#f35393",
    lines: [
      [
        {text: ""},
        textNumberValueInput(),
        {text: "是否为空  "}
      ]
    ],
    compiler: "$0.length === 0",
    level: Order.RELATIONAL,
  },
  {
    name: "text_reverse",
    type: BlockType.Output,
    color: "#f35393",
    lines: [[{text: "倒转文本"}, textNumberValueInput()]],
    compiler: "$0.split('').reverse().join('')",
    level: Order.DIRECT,
  },
  {
    name: "text_connect",
    type: BlockType.Output,
    color: "#84c974",
    lines: [[{text: "拼接"}, textNumberValueInput(), textNumberValueInput()]],
    compiler: "$0 + $1",
    level: Order.ADDITION,
  },
  {
    name: "text_to_number",
    type: BlockType.Output,
    color: "#f35393",
    lines: [[{text: "转换为数字"}, textNumberValueInput()]],
    compiler: "Number($0)",
    level: Order.FUNCTION_CALL,
  },
  {
    name: "text_to_string",
    type: BlockType.Output,
    color: "#f35393",
    lines: [[{text: "转换为文本"}, {value: "any"}]],
    compiler: "String($0)",
    level: Order.FUNCTION_CALL,
  },
  {
    name: "text_contain",
    type: BlockType.Output,
    output: "bool",
    color: "#84c974",
    lines: [[
      {text: ""},
      textNumberValueInput(),
      {
        field: FieldType.Dropdown,
        option: ["属于", "不属于", "包含", "不包含", "是", "不是"],
        default: 0,
      },
      textNumberValueInput(),
      {text: "  "},
    ]],
    compiler: "$0 $1 $2",
    level: Order.RELATIONAL,
  },
  {
    name: "text_case",
    type: BlockType.Output,
    color: "#f35393",
    lines: [
      [
        {text: "转为"},
        {field: FieldType.Dropdown, option: ["大写", "小写"], optionCode: ["toUpperCase", "toLowerCase"], default: 0},
        textNumberValueInput(),
      ],
    ],
    compiler: "$0.$1()",
    level: Order.FUNCTION_CALL,
  },
];

RhineBlock.registerBlocksData(textBlocks);

export default textBlocks;
