import {RhineBlock} from "../../RhineBlock";

// 设置代码的缩进量
export function setCodeIndent(code: string, indent: number) {
  let firstLineIndent = 0;
  for (let i = 0; i < code.length; i++) {
    if (code[i] === " ") {
      firstLineIndent++;
    } else {
      addCodeIndent(code, indent - firstLineIndent);
    }
  }
}

// 添加代码缩进量
export function addCodeIndent(code: string, indent: number = RhineBlock.Compiler.INDENT) {
  if (indent === 0) return code;
  const END = RhineBlock.Compiler.LINE_END;
  const lines = code.split(END);
  const addStr = " ".repeat(Math.abs(indent));
  for (let i = 0; i < lines.length; i++) {
    if (indent > 0) {
      lines[i] = " ".repeat(indent) + lines[i];
    } else {
      if (lines[i].startsWith(addStr)) {
        lines[i] = lines[i].substring(-indent);
      } else {
        for (let j = 0; j < lines[i].length; j++) {
          if (lines[i][j] !== " ") {
            lines[i] = lines[i].substring(j);
            break;
          }
        }
      }
    }
    if (i === lines.length - 1 && lines[i].trim().length === 0) lines.pop();
  }
  return lines.join(END) + END;
}


