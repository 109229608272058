import Block, {IBlock, Item, ItemValue} from "./block/block.class";
import {Graph} from "./view/graph/graph";
import BaseRender from "./render/base/base-render";
import Arg from "./block/arg.class";
import BaseCompiler from "./compiler/base/base-compiler";
import RX from "../../App/Editor/RX/RX";

export class RhineBlock {
  // 渲染器
  static Render: typeof BaseRender = BaseRender;
  // 编译器
  static Compiler: typeof BaseCompiler = BaseCompiler;

  // 注册渲染器
  static registerRender(render: typeof BaseRender) {
    this.Render = render;
  }
  // 注册编译器
  static registerCompiler(compiler: typeof BaseCompiler) {
    this.Compiler = compiler;
  }

  // 图形块外观参数
  static blocksData: IBlock[] = [];
  // 注册图形块外观参数
  static registerBlocksData(blocks: IBlock[]) {
    this.blocksData = this.blocksData.concat(blocks);
    // console.log(this.blocksData)
  }

  // 获取某个图形块外观参数
  static getBlockData(name: string): IBlock | null {
    return this.blocksData.find((block) => block.name === name) || null;
  }
  // 获取工具栏内容
  static getBlockToolbox(name: string): ItemValue[] | boolean | undefined {
    return this.getBlockData(name)?.toolbox;
  }

  // 所有画布对象
  static graphs: Graph[] = [];
  // 新增画布
  static registerGraph(graph: Graph) {
    this.graphs.push(graph);
  }
  // 遍历画布
  static mapGraph(fn: (graph: Graph) => void, expectToolbox = true) {
    if (!expectToolbox) {
      this.graphs.forEach(fn);
    } else {
      this.graphs.forEach((graph) => {
        if (!graph.isToolbox) fn(graph);
      });
    }
  }

  // 获取第一个工具箱以外的画布
  static getFirstGraphWithoutToolbox(): Graph | null {
    return this.graphs.find((graph) => !graph.isToolbox) || null;
  }

  static inputting = false
  // 当移除输入时
  static onRemoveFocus = (e?: Event) => {
  };
  // 当虚化时
  static onRemoveBlur = () => {
  };

  static waitEvent: any = null

  // 当前选中的图形块
  static selected: Block | null = null;
  // 设置当前选中的图形块
  static setSelected(block: Block | null) {
    if (this.selected) {
      this.selected.setSelected(false)
    }
    this.clearSelected()
    if (block != null) {
      block.setSelected(true)
    }
    this.selected = block;
  }
  // 清空当前选中的图形块
  static clearSelected() {
    this.graphs.map(graph=>{
      graph.recurBlocks(block=>{
        if(block.isSelected) block.setSelected(false)
      })
    })
    this.selected = null
  }

  // static disable: Block | null = null;
  //
  // static setDisable(block: Block | null) {
  //   if (this.disable) {
  //     this.disable.setDisable(false)
  //   }
  //   if (block != null) {
  //     block.setSelected(true)
  //   }
  //   this.disable = block;
  // }

  // 剪贴板数据
  static clipboard: ClipboardData | null = null;
  // 设置剪贴板内容
  static setClipboard(item: Item, graph: Graph) {
    const time = new Date().getTime();
    this.clipboard = {
      item,
      graph,
      time,
    };
  }
  // 获取剪贴板内容
  static getClipboard(): ClipboardData | null {
    return this.clipboard;
  }
  // 清空剪贴板
  static clearClipboard() {
    this.clipboard = null;
  }

  // 事件监听器
  static eventListeners: ((e: ChangeEvent) => void)[] = [];
  // 新增事件监听器
  static addEventListener(fn: (e: ChangeEvent) => void) {
    this.eventListeners.push(fn);
  }

  // 主动触发指定事件
  static onChange(e: ChangeEvent) {
    if(!e.time){
      e.time = new Date().getTime();
    }
    if(!e.graph?.isToolbox){
      // console.log(e)
      for (const eventListener of this.eventListeners) {
        eventListener(e);
      }
    }
  }

  // 获取当前全局缩放状态
  static getZoom(block?: Block | Graph) {
    if (block && block instanceof Block && block.isInToolbox()) {
      return 1
    } else if (block && block instanceof Graph && block.isToolbox) {
      return 1
    } else {
      return iotZoom() // ONLY-IOT
    }
  }

}

// 事件对象
export interface ChangeEvent {
  type: ChangeEventType; // 类型
  block: Block; // 图形块
  arg: Arg | undefined; // 处于何父参数
  time: number | undefined; // 触发时间
  graph: Graph | undefined; // 处于何画布
}
// 变化事件类型
export enum ChangeEventType {
  ADD = "add", // 创建图形块
  REMOVE = "remove", // 删除图形块
  MOVE = "move", // 移动图形块
  UPDATE = "update", // 更新图形块内容
  INPUT = "input", // 更新图形块内容
  DISABLE = "disable", // 禁用图形块
}

// 剪贴板数据对象
export interface ClipboardData {
  item: Item; // 图形块实例参数
  graph: Graph; // 来自何画布
  time: number; // 复制时间
}

// ONLY-IOT 用于处理缩放情况
export function iotZoom(): number {
  const g = RX.graph;
  if (g) return g.zoom();
  return 1;
}
