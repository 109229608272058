import RX from "../RX";
import {RhineBlock} from "../../../../RhineBlock/core/RhineBlock";
import {getDeviceById} from "../../data/devices/devices";
import {checkInRubbishBin} from "../../Editor";
import RxHistory from "../history/RxHistory";
import RxClipboard from "../clipboard/RxClipboard";
import RxSelection from "../selection/RxSelection";
import state from "../../../store/state";
import DealOverlay from "../tidy/DealOverlay";

export default class RxEvent {
  // 启用事件调试输出
  static useLogEvent = true

  static keyDownListener = null

  // 输出事件
  static logEvent(type, msg) {
    console.log('Event ' + type + ': ' + msg)
  }

  // 注册X6画布原生监听器
  static registerGraphEvent(type, func) {
    RX.graph.on(type, func)
  }

  // 当前连线
  static currentEdge = null;

  static getGraphData() {
    const {cells} = RX.graph.toJSON();
    return {
      name: "test",
      cells: cells.map((item) => {
        if (item.shape === "device-edge") {
          return {
            id: item.id,
            shape: item.shape,
            source: item.source,
            target: item.target,
          };
        }
        return item;
      }),
    }
  }

  // 初始化默认监听器
  static initDefaultListener() {
    const isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    if (RxEvent.keyDownListener) {
      document.removeEventListener("keydown", RxEvent.keyDownListener)
    }
    RxEvent.keyDownListener = (e) => {
      // 输出测试数据
      if (e.key === "F9") {
        const data = RxEvent.getGraphData()
        console.info(JSON.stringify(data, null, 4));
      } else if(e.ctrlKey || (isMac && e.metaKey)) {
        if (e.key.toUpperCase() === "Z") {
          if (e.shiftKey) {
            RxHistory.redo()
          } else {
            RxHistory.undo()
          }
        } else if (e.key.toUpperCase() === "C") {
          RxClipboard.actionCopy()
        } else if (e.key.toUpperCase() === "X") {
          RxClipboard.actionCut()
        } else if (e.key.toUpperCase() === "V") {
          RxClipboard.actionPaste()
        }
      } else if (e.key === "Delete" || e.key === "Backspace") {
        RxSelection.deleteSelection();
      } else if (e.key === " ") {
        if (!state.isDrag) {
          RX.graph.enablePanning();
          RX.graph.setRubberbandModifiers('shift');
        }
      }
    }
    document.addEventListener("keydown", RxEvent.keyDownListener);
    RxEvent.registerGraphEvent("cell:selected", (cell) => {
      RhineBlock.setSelected(null);
    });
    RxEvent.registerGraphEvent("node:selected", ({node}) => {
      let device = getDeviceById(node.data.device);
      if (device) {
        device = device.name;
        // setCurrentDevice(device)
        const cdSpan = document.getElementById("currentDeviceName");
        if (cdSpan) cdSpan.innerText = device;
      }
      RX.freshMarquee()
    });
    RxEvent.registerGraphEvent("node:unselected", ({node}) => {
      RX.freshMarquee()
    });
    RxEvent.registerGraphEvent("edge:mouseenter", ({e, edge, view}) => {
      try {
        const source = RX.graph.getCellById(edge.store.data.source.cell);
        const target = RX.graph.getCellById(edge.store.data.target.cell);
        if (
          source.shape === "logic-node" ||
          target.shape === "logic-node"
        )
          return;
        view.labelSelectors[0].root.children[0].setAttribute(
          "height",
          36
        );
        this.currentEdge = edge;
      } catch (e) {
        console.warn(e)
      }
    });
    RxEvent.registerGraphEvent("edge:mouseleave", ({e, edge, view}) => {
      try {
        view.labelSelectors[0].root.children[0].setAttribute("height", 0);
        this.currentEdge = edge;
      } catch (e) {
        console.warn(e)
      }
    });
    RxEvent.registerGraphEvent("node:moved", ({cell, e, node, view, x, y}) => {
      if(checkInRubbishBin(e.clientX, e.clientY)){
        RX.graph.removeNode(node)
        return
      }
      DealOverlay.dealFromMoveOne(cell)
    })
    RxEvent.registerGraphEvent("node:added", ({node, index, option}) => {
      if (!RX.passDealOverlay) {
        DealOverlay.dealFromMoveOne(node)
      } else {
        RX.passDealOverlay = false
      }
    })
    RxEvent.registerGraphEvent('edge:removed', ({ edge, index, options }) => {
      for (let i of [0, 1]) {
        const target = i ? edge.target : edge.source
        RX.setPortStatus(target.cell, target.port, false)
      }
    })
    RxEvent.registerGraphEvent('blank:mousedown', () => {
      RhineBlock.setSelected(null)
    })
    RxEvent.registerGraphEvent('blank:contextmenu', () => {
      RhineBlock.setSelected(null)
    })
  }

}