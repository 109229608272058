import BaseCompiler, {DefaultOrder} from "../base/base-compiler";
import Block from "../../block/block.class";

// 运算符优先级
export enum JsOrderComplete {
  STATEMENT = DefaultOrder.STATEMENT,     // Statement block.
  UNKNOWN = DefaultOrder.UNKNOWN,         // Default to add () around. Most safety.
  DIRECT = DefaultOrder.DIRECT,           // 0 "" ... . Not safe to add () around.
  NEW = 1.1,            // new
  MEMBER = 1.2,         // . []
  FUNCTION_CALL = 2,    // ()
  INCREMENT = 3,        // ++
  DECREMENT = 3,        // --
  BITWISE_NOT = 4.1,    // ~
  UNARY_PLUS = 4.2,     // +
  UNARY_NEGATION = 4.3, // -
  LOGICAL_NOT = 4.4,    // !
  TYPEOF = 4.5,         // typeof
  VOID = 4.6,           // void
  DELETE = 4.7,         // delete
  AWAIT = 4.8,          // await
  EXPONENTIATION = 5.0, // **
  MULTIPLICATION = 5.1, // *
  DIVISION = 5.2,       // /
  MODULUS = 5.3,        // %
  SUBTRACTION = 6.1,    // -
  ADDITION = 6.2,       // +
  BITWISE_SHIFT = 7,    // << >> >>>
  RELATIONAL = 8,       // < <= > >=
  IN = 8,               // in
  INSTANCEOF = 8,       // instanceof
  EQUALITY = 9,         // == != === !==
  BITWISE_AND = 10,     // &
  BITWISE_XOR = 11,     // ^
  BITWISE_OR = 12,      // |
  LOGICAL_AND = 13,     // &&
  LOGICAL_OR = 14,      // ||
  CONDITIONAL = 15,     // ?:
  ASSIGNMENT = 16,      // = += -= **= *= /= %= <<= >>= ...
  YIELD = 16.5,         // yield
  COMMA = 17,           // ,
  NONE = 99,            // (...)
}

export enum JsOrderEasy {
  UNKNOWN = -1,         // Default to add () around. Most safety.
  ATOMIC,           // 0 "" ... . Not safe to add () around.

  BASE,             // new . [] ()
  UNARY,            // ~ ++ -- ! typeof void delete await
  MULTIPLICATIVE,   // * / %
  ADDITIVE,         // + -
  SHIFT,            // << >> >>>
  RELATIONAL,       // < <= > >=
  EQUALITY,         // == != === !==

  BITWISE_AND,      // &
  BITWISE_XOR,      // ^
  BITWISE_OR,       // |
  LOGICAL_AND,      // &&
  LOGICAL_OR,       // ||

  CONDITIONAL,      // ?:
  ASSIGNMENT,       // = += -= **= *= /= %= <<= >>= ...
}

export default class JsCompiler extends BaseCompiler {

  public static Order = JsOrderComplete;

  public static DEFAULT_VALUE_CODE = "null";
  public static DEFAULT_STATEMENT_CODE = this.LINE_END;


}

