import Style from "./Editor.module.css";
import ToolBtn from "../../common/ToolBtn/ToolBtn";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useState, useEffect, useRef} from "react";
import "@antv/x6-react-shape";
import "./RX/components/cells/DeviceNode/DeviceNode.module.css";
import {Alert, Button, IconButton, OutlinedInput, Popover, Select, Snackbar} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import MenuItem from "@mui/material/MenuItem";
import {Close, FilterAltOutlined, Search} from "@mui/icons-material";
import {devices, filterDevices, getDeviceById, tags} from "./data/devices/devices";
import DeviceDetail from "./RX/components/cells/DeviceDetail/DeviceDetail";
import * as GraphRegister from "./RX/GraphRegister";
import Paper from "@mui/material/Paper";
import Cookies from "js-cookie";
import MenuList from "@mui/material/MenuList";
import React from "react";
import "./MaterialUI.css";
import BlockToolbox from "./views/BlockToolbox/BlockToolbox";
import {getClientCenter} from "./utils/normal";
import state from "../store/state";
import {allExists} from "./data/formwork/allExists";
import {updateData} from "./RX/components/ports/PublicPort/NodeCallbacks";
import {makeFlower} from "./RX/components/ports/MakeFlower/MakeFlower";
import {getDefaultPortData} from "./RX/components/ports/PublicPort/DefaultPortData";
import CodeEditor from "./views/CodeEditor/CodeEditor";
import SplitLayout from "../../common/SplitLayout/SplitLayout";
import HorizontalSplitLayout from "../../common/HorizontalSpiltLayout/HorizontalSplitLayout";
import {createNodeData} from "./RX/components/cells/NodeData";
import RxHistory from "./RX/history/RxHistory";
import RxEvent from "./RX/event/RxEvent";
import RX from "./RX/RX";
import RxClipboard from "./RX/clipboard/RxClipboard";
import Api from "../Api";
import MuiAlert from "./utils/MuiAlert";
import {Option} from "./RX/Option";
import DealOverlay from "./RX/tidy/DealOverlay";
import Direction from "./RX/tidy/Direction";
import axios from "axios";
import {tip} from "../App";
import Once from "../store/once";
import CodeStorage from "./CodeStorage";
import {RhineBlock} from "../../RhineBlock/core/RhineBlock";

const INIT_ON = Api.isDevelopmentEnv() ? 1 : 0;
const API_URL = Api.isDevelopmentEnv() ? "http://localhost:8088" : ""

let graph = null; // 编辑区画布
let deleteEdge = null; // 需删除连线
let lastTs = null; // 历史EnterId
let project = null;

let gData = {cells: []}; // TODO: 建议删除该变量 用x6内置函数代替相关功能
state.isDrag = false;

class EditorListener {
  static mouseDownListener = null

  static keyUpListener = null

  static keyDownListener = null

  static removeAll = () => {
    if (EditorListener.mouseDownListener) {
      document.removeEventListener('mousedown', EditorListener.mouseDownListener)
    }
    if (EditorListener.keyUpListener) {
      document.removeEventListener('keyup', EditorListener.keyUpListener)
    }
    if (EditorListener.keyDownListener) {
      document.removeEventListener('keydown', EditorListener.keyDownListener)
    }
  }
}

function Editor() {
  // 处理浏览器页面参数
  let [params] = useSearchParams()
  const name = "项目载入中..."
  const pid = params.get("pid")
  const ts = params.get("timestamp")
  if (ts !== lastTs) {
    // 进入不同项目，重新初始化画布绘制
    window.blockInited = false
    RX.initProgress = 0
    lastTs = ts
  }

  // 页面组件状态内容
  const [title, setTitle] = useState('正在载入项目...') // 页面标题
  const [titleEditing, setTitleEditing] = useState(false) // 页面标题编辑状态
  const titleInputRef = useRef()
  const [deviceGroupIndex, setDeviceGroupIndex] = useState(0) // 当前设备分组序号
  const [searchText, setSearchText] = useState('') // 当前搜索文本
  const [currentDevice, setCurrentDevice] = useState(
      getDeviceById("airConditioner")
  ); // 当前选中设备（关系右侧列表详细信息）

  const [lineStatus, setLineStatus] = useState(false) // 连线状态 是否选中
  const [edgeAnchorEl, setEdgeAnchorEl] = React.useState(null) // 连线右键菜单锚点
  const [portHoverPosition, setPortHoverPosition] = React.useState({x: -1, y: 100}) // 设备详情菜单位置
  const [portHoverShow, setPortHoverShow] = React.useState(false) // 接口背景是否显示
  const [portHoverData, setPortHoverData] = React.useState(getDefaultPortData()) // 设备信息菜单位置

  const refContainer = useRef() // 画布容器
  const refContainerHolder = useRef() // 画布外侧容器

  const save = () => {
    console.log("save", title)
    if (title === "正在载入项目...") {
      tip("请等待项目加载完成", "warning")
      return
    }
    let data = RxEvent.getGraphData()
    project.name = title
    project.content = JSON.stringify(data)
    console.log("Save:", project, data)
    Api.upload(pid, project).then(r => {
      tip("保存成功", "success");
    }).catch(e => {
      console.error(e)
      tip("保存失败" + e, "error")
    })
  }

  const navigate = useNavigate()

  let handleClosePopover = () => { // 关闭右键悬浮菜单
    setEdgeAnchorEl(null)
  };

  let onDeleteEdge = () => { // 删除连线
    RxHistory.startBatch('Editor DeleteEdge')
    if (deleteEdge) {
      RX.onChangeEdgeStatus(deleteEdge)
      RX.removeCell(deleteEdge)
      deleteEdge = null
    }
    handleClosePopover()
    RxHistory.stopBatch('Editor DeleteEdge')
  };

  let checkEdge = window.checkEdge

  RX.setPortStatus = (nodeId, portId, disable = false) => {
    let node = graph.getCellById(nodeId)
    if (!node) return;
    let port = node.getPort(portId)
    // 防空
    if (port) {
      if(!port.attrs)
        node.setPortProp(port.id, ['attrs'], {circle: {r: 0}, data: {disable: !disable}});
      else if(!port.attrs.data)
        node.setPortProp(port.id, ['attrs', 'data'], {disable: !disable});
      const dom = document.querySelector(`[data-cell-id=${node.id}] foreignObject body div`).querySelector(`[id='${port.id}']`)
      if (null == dom) return
      // 着色
      let originColor = dom.style['backgroundColor']
      node.setPortProp(port.id, ['attrs', 'data', 'disable'], !disable);
      if (!port.attrs.data.originColor) {
        node.setPortProp(port.id, ['attrs', 'data', 'originColor'], originColor)
        port = node.getPort(port.id)
      }
      const isLeft = port.group === "left";
      if (disable || node.getData().isDisable)
        dom.style['background-color'] = isLeft ? "#abccff" : "#d6b8ff";
      else
        dom.style['background-color'] = isLeft ? "#529BF1" : "#B375E0";
    }
  }

  // 设置线状态
  RX.onChangeEdgeStatus = (edge, disable = edge.attr(['data', 'showDisable'])) => {
    // 获取边的实体
    if (edge && edge.attr(['data', 'showDisable']) !== disable) {
      edge.attr(['data', 'showDisable'], disable)
      if (!edge.attr(['data', 'originColor'])) {
        edge.attr(['data', 'originColor'], edge.attr('line/stroke'))
      }
    }

    // 禁用端口
    for (let i of [0, 1]) {
      const target = i ? edge.target : edge.source
      RX.setPortStatus(target.cell, target.port, disable)
    }

    // 判断并着色
    if (edge.attr(['data', 'originColor'])) {
      if (disable) {
        document.querySelector(`[data-cell-id="${edge.id}"]`).classList.add('disable')
        edge.attr('line/stroke', `#9cc6ff`)
      } else {
        document.querySelector(`[data-cell-id="${edge.id}"]`).classList.remove('disable')
        edge.attr('line/stroke', edge.attr(['data', 'originColor']))
      }
    }
    handleClosePopover();
  };

  // 获取连线状态
  let getEdgeStatus = () => {
    // console.log("获取到点击事件")
    let status = false
    let edge = window.checkEdge
    if (edge) {
      // 获取
      if(edge.attr(['data', 'disable'])){
        status = edge.attr(['data', 'disable'])
      }
    }
    return Boolean(status)
  }

  // 改变连线状态
  let changeEdgeStatus = () => {
    RxHistory.startBatch('Editor ChangeEdgeStatus')
    let status = true
    if (checkEdge) {
      // 获取
      if(checkEdge.attr(['data', 'disable'])){
        status = !checkEdge.attr(['data', 'disable'])
      }
    }
    checkEdge.attr(['data', 'disable'], status)
    RX.onChangeEdgeStatus(checkEdge, status)
    // setLineStatus(status)
    RxHistory.stopBatch('Editor ChangeEdgeStatus')
    return status
  }

  // 获取窗口位置
  let getPosition = () => {
    return window.position;
  };

  // 页面加载完成后
  useEffect(message => {
    if (!refContainer.current) return;

    // 设置接口信息悬浮位置
    RX.setPortHover = (x, y, data) => {
      // console.log('setPortHover', x, y, data);
      if (y < 180) {
        y += 176 + 24 + 16;
      }
      setPortHoverPosition({x: x, y: y});
      if(data) setPortHoverData(data);
    }
    RX.setPortShow = (show) => {
      setPortHoverShow(show)
    }

    // 过滤初始化以外的调用
    if (RX.getInitProgress() < 2) {

      // 设置下拉菜单初始值
      if (name === "new1") setDeviceGroupIndex(1)
      if (name === "new2") setDeviceGroupIndex(4)
      if (name === "new3") setDeviceGroupIndex(12)
      if (name === "exs1") setDeviceGroupIndex(1)
      if (name === "exs2") setDeviceGroupIndex(11)
      if (name === "exs3") setDeviceGroupIndex(2)
      if (name === "exs4") setDeviceGroupIndex(9)
      // 切换操作模式 选择/拖拽
      if (state.setChooseSelected && state.setDragSelected) {
        state.setChooseSelected(true);
        state.setDragSelected(false);
      }

      try {
        // 核心初始化流程
        if (RX.getInitProgress() === INIT_ON) {
          // 注册X6相关组件信息
          GraphRegister.register();

          // 成功连接连线验证
          // TODO: 优化连线验证相关代码结构
          const validateEdge = ({edge, type, previous}) => {
            // console.log("validateEdge", edge, type, previous);
            window.isDispatch = false
            const shape = edge.getTargetCell().shape;
            const portId = edge.target.port;
            const isRight =
              edge.store.data.target.port.split("-")[1] === "OUT";
            const isLogic = shape === "logic-node";
            const ms = portId.split("-");
            const result = window.dragRight
              ? ms[1] === "IN"
              : ms[1] === "OUT";
            if (ms[2] === "ADD") {
              // 连线至加号端口以创建连接桩
              let node = RX.getNodeById(edge.target.cell);
              let box = node.getBBox();
              let kn = 0;
              let pn = 0;
              let krn = 0;
              let prn = 0;
              for (const port of node.getPorts()) {
                if (port.group === "left") {
                  pn++;
                  const n = parseInt(port.id.split("-")[2]);
                  if (n > kn) kn = n;
                } else {
                  prn++;
                  const n = parseInt(port.id.split("-")[2]);
                  if (n > krn) krn = n;
                }
              }

              // let max = isLogic ? 6 : 3;
              // let tn = isRight ? prn : pn
              // if (tn > max && !isLogic) {
              //   node.resize(208, 112 + (tn - max) * 32)
              // }
              // DealOverlay.dealFromOne(node, Direction.bottom)

              // if (tn >= max) {
              //   RX.removeCell(edge);
              //   if (shape === "logic-node") {
              //     alert(`最多只能添加${max - 1}个端口`);
              //     return false;
              //   }
              // }

              let p = graph.localToClient(
                box.x,
                box.y + ((box.height - 11) * (pn * 2 - 1)) / (pn * 2)
              );
              if (isRight) {
                p = graph.localToClient(
                  box.x + box.width,
                  box.y + ((box.height - 11) * (prn * 2 - 1)) / (prn * 2)
                );
              }

              const insertIndex = isRight ? prn + pn - 1 : pn - 1;
              if (isLogic) {
                const target = edge.getTargetCell();

                let lnu = [0];
                let rnu = [0];
                target.port.ports.map((tp) => {
                  lnu.push(0)
                  rnu.push(0)
                  if (tp.text !== "+") {
                    let n = tp.id.split("-")[2];
                    tp.id.split("-")[1] === 'IN' ? lnu[n - 1]++ : rnu[n - 1]++;
                  }
                });
                let i = isRight ? rnu.indexOf(0) : lnu.indexOf(0);
                i += 1;

                const id =
                  ms[0] + "-" + (isRight ? "OUT" : "IN") + "-" + i;
                const text = (isRight ? "B" : "A") + i;
                // console.log(id,text)

                node.insertPort(insertIndex, {
                  id: id,
                  group: isRight ? "right" : "left",
                  text: text,
                  attrs: {circle: {r: 10}},
                });
                const newEdge = graph.addEdge({
                  shape: "device-edge",
                  zIndex: 0,
                  source: edge.source,
                  target: {cell: edge.target.cell, port: id},
                });
                let sourceDisable = newEdge.getSourceCell().getData().isDisable
                let targetDisable = newEdge.getTargetCell().getData().isDisable
                if(sourceDisable || targetDisable){
                  RX.onChangeEdgeStatus(newEdge, true)
                }
                updateData(node)
                RX.removeCell(edge);
                RxHistory.stopBatch('Editor ValidateEdge AddToLogic')
              } else {
                const deviceMsg = getDeviceById(node.data.device)
                const supportPortText = isRight ? deviceMsg.outputText : deviceMsg.inputText
                makeFlower(
                  {pageX: p.x, pageY: p.y},
                  "+",
                  shape,
                  supportPortText,
                  (text) => {
                    const portName =
                      ms[0] +
                      "-" +
                      (isRight ? "OUT" : "IN") +
                      "-" +
                      (isRight ? krn + 1 : kn + 1);
                    const newPort = node.insertPort(insertIndex, {
                      id: portName,
                      group: isRight ? "right" : "left",
                      text: text,
                      attrs: {circle: {r: 10}},
                    });
                    const newEdge = graph.addEdge({
                      shape: "device-edge",
                      zIndex: 0,
                      source: edge.source,
                      target: {cell: edge.target.cell, port: portName},
                    });
                    updateData(node)
                    let sourceDisable = newEdge.getSourceCell().getData().isDisable
                    let targetDisable = newEdge.getTargetCell().getData().isDisable
                    if(sourceDisable || targetDisable){
                      RX.onChangeEdgeStatus(newEdge, true)
                    }
                  },
                  () => {
                    RX.removeCell(edge)
                    let ln = 0
                    let rn = 0
                    node.getPorts().map(p => {
                      p.group === 'left' ? ln++ : rn++
                    })
                    let mn = ln > rn ? ln : rn
                    // 需要时变化大小
                    if (mn > 3) {
                      node.resize(208, 112 + (mn - 4) * 32)
                    }
                    RxHistory.stopBatch('Editor ValidateEdge AddToDevice')
                  }
                );
              }
            } else{
              let sourceDisable = edge.getSourceCell().getData().isDisable
              let targetDisable = edge.getTargetCell().getData().isDisable
              if(sourceDisable || targetDisable){
                RX.onChangeEdgeStatus(edge, true)
              }
              RxHistory.stopBatch('Editor ValidateEdge Connect')
            }
            return result;
          }
          // 渲染连线上标签
          const onEdgeLabelRendered = (args) => {
            const {selectors} = args;
            const content = selectors.foContent;
            if (content) {
              const btn = document.createElement("button");
              btn.appendChild(document.createTextNode("+"));
              btn.style.width = "100%";
              btn.style.height = "100%";
              btn.style.lineHeight = "1";
              btn.style.borderRadius = "4px";
              btn.style.textAlign = "center";
              btn.style.color = "#42A4FF";
              btn.style.background = "#ECF5FF";
              btn.style.fontSize = "24px";
              btn.style.border = "2px dashed #42A4FF";
              btn.style.cursor = "pointer";
              btn.addEventListener("click", () => {
                RxHistory.startBatch('Editor CreateLogicNodeInEdge')
                let source = RxEvent.currentEdge.store.data.source;
                let target = RxEvent.currentEdge.store.data.target;
                if (source.port.split("-")[1] === "IN") {
                  const temp = source;
                  source = target;
                  target = temp;
                }

                gData.cells.filter((item) => item.id !== RxEvent.currentEdge.id);

                RxEvent.currentEdge.attr(["data", "disable"], false)
                RX.onChangeEdgeStatus(RxEvent.currentEdge, false);
                graph.removeEdge(RxEvent.currentEdge.id);
                gData.cells = gData.cells.filter(
                  (item) => item.id !== RxEvent.currentEdge.id
                );

                let startX = 0;
                let startY = 0;
                let endX = 0;
                let endY = 0;
                graph.getCells().map((item) => {
                  if (item.id === source.cell) {
                    startX = item.getBBox().x + 104;
                    startY = item.getBBox().y + 66;
                  }
                  if (item.id === target.cell) {
                    endX = item.getBBox().x + 104;
                    endY = item.getBBox().y + 66;
                  }
                });
                let middle = (startX + endX) / 2;

                let dn = RX.createNewDeviceId();
                const node = {
                  id: "N" + dn,
                  shape: "logic-node",
                  data: {},
                  x: middle - Option.LogicNodeWidth / 2,
                  y: (startY + endY) / 2 - Option.LogicNodeHeight / 2,
                  width: Option.LogicNodeWidth,
                  height: Option.LogicNodeHeight,
                  ports: [
                    {
                      id: dn + "-IN-1",
                      group: "left",
                      text: "A1",
                    },
                    {
                      id: dn + "-IN-ADD",
                      group: "left",
                      text: "+",
                      attrs: {circle: {r: 0}},
                    },
                    {
                      id: dn + "-OUT-1",
                      group: "right",
                      text: "B1",
                    },
                    {
                      id: dn + "-OUT-ADD",
                      group: "right",
                      text: "+",
                      attrs: {circle: {r: 0}},
                    },
                  ],
                };
                gData.cells.push(node)
                graph.addNode(node)


                let ln = 1;
                gData.cells.map((item) => {
                  if (item.shape === "device-edge") ln++;
                });
                const edge1 = {
                  shape: "device-edge",
                  id: "L" + ln,
                  zIndex: 0,
                  source: source,
                  target: {
                    cell: "N" + dn,
                    port: dn + "-IN-1",
                  },
                };
                gData.cells.push(edge1);
                const edge1Cell = graph.addEdge(edge1);
                if(graph.getCellById(source.cell).getData().isDisable){
                  RX.onChangeEdgeStatus(edge1Cell, true)
                }
                ln++;
                const edge2 = {
                  shape: "device-edge",
                  id: "L" + ln,
                  zIndex: 0,
                  source: {
                    cell: "N" + dn,
                    port: dn + "-OUT-1",
                  },
                  target: target,
                };
                gData.cells.push(edge2);
                const edge2Cell = graph.addEdge(edge2);
                if(graph.getCellById(target.cell).getData().isDisable){
                  RX.onChangeEdgeStatus(edge2Cell, true)
                }
                RxHistory.stopBatch('Editor CreateLogicNodeInEdge')
              });
              content.appendChild(btn);
            }
            // todo 可以在这里判断线条状态
            // 默认添加线条状态处理被禁用设备连线依然启用状态 -------------开始------------------
            // 获取目标端点
            const edge = args.edge
            const target = graph.getCellById(edge.target.cell)
            const source = graph.getCellById(edge.source.cell)
            if (target && source) {
              const targetPort = target.getPort(edge.target.port)
              // 获取来源端点
              const sourcePort = source.getPort(edge.source.port)
              if (targetPort && sourcePort) {
                let status = false
                let targetStatus = false
                let sourceStatus = false
                if (targetPort) {
                  if (targetPort.attrs && targetPort.attrs.data)
                    targetStatus = targetPort.attrs.data.disable
                  else // 防止空
                    targetStatus = true
                }
                if (sourcePort) {
                  if (sourcePort.attrs && sourcePort.attrs.data)
                    sourceStatus = sourcePort.attrs.data.disable
                  else // 防止空
                    sourceStatus = true
                }
                // 双方被禁用
                if (false === targetStatus && false === sourceStatus) {
                  status = true
                }
                // 获取边的实体
                // 保存状态
                edge.attr(['data', 'disable'], status)
                // 存储原色
                if (!edge.attr(['data', 'originColor'])) {
                  edge.attr(['data', 'originColor'], edge.attr('line/stroke'))
                }
                // 添加状态属性
                edge.attr(['data', 'disable'], status)
                // 判断并着色
                if (edge.attr(['data', 'originColor'])) {
                  if (status) {
                    /* bugfix 选中线条颜色也要0.5透明度 添加disable的class*/
                    document.querySelector(`[data-cell-id="${edge.id}"]`).classList.add('disable')
                    edge.attr('line/stroke', `#9cc6ff`)
                  } else {
                    /* bugfix 选中线条颜色也要0.5透明度 添加disable的class*/
                    document.querySelector(`[data-cell-id="${edge.id}"]`).classList.remove('disable')
                    edge.attr('line/stroke', edge.attr(['data', 'originColor']))
                  }
                }
              }
            }
            // 默认添加线条状态-------------结束------------------
          }
          // 构造X6画布
          graph = RX.initGraph(refContainer.current, validateEdge, onEdgeLabelRendered)

          const containerHolder = refContainerHolder.current; // 获取容器元素
          const resizeObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
              const rect = entry.contentRect
              graph.resize(rect.width, rect.height)
            })
          })
          resizeObserver.observe(containerHolder) // 监听大小变化

          // 当鼠标点下 完成整体连线流程
          EditorListener.removeAll()

          EditorListener.mouseDownListener = (e) => {
            if(window.isDispatch){
              const d = e.target
              if(d.tagName !== "SPAN" || d.innerText !== '+' || d.parentElement.className.indexOf('DeviceNode_port') !== 0){
                window.isDispatch = false
                RxHistory.stopBatch('Editor FinishDispatch')
              }
            }
          }
          document.body.addEventListener("mousedown", EditorListener.mouseDownListener)

          EditorListener.keyUpListener = (e) => {
            if (e.key === " ") {
              if (!state.isDrag) {
                graph.disablePanning();
                graph.setRubberbandModifiers(null);
              }
            } else if (e.key === "s" && e.ctrlKey) {
              e.preventDefault()
            }
          }
          document.addEventListener("keyup", EditorListener.keyUpListener);

          EditorListener.keyDownListener = (e) => {
            if (e.key === "s" && e.ctrlKey) {
              e.preventDefault()
            }
          }
          document.addEventListener("keydown", EditorListener.keyDownListener)

          // 初始化若干默认监听器
          RxEvent.initDefaultListener()
          // 初始化历史记录功能
          RxHistory.init()

          // 连线右键点击事件
          const handleEdgeRightClick = (e) => {
            setEdgeAnchorEl(e.currentTarget);
            let edge = window.checkEdge
            let sourceDisable = edge.getSourceCell().getData().isDisable
            let targetDisable = edge.getTargetCell().getData().isDisable
            if(sourceDisable || targetDisable){
              setLineStatus(undefined)
            }else{
              setLineStatus(getEdgeStatus())
            }
            window.position = {top: e.clientY, left: e.clientX};
          };
          // 连线右键事件
          graph.on("edge:contextmenu", ({e, x, y, edge, view}) => {
            deleteEdge = edge;
            window.checkEdge = edge
            handleEdgeRightClick(e);
          });

          gData = {cells: []};
          graph.fromJSON(gData);

          Api.detail(pid).then(r => {
            project = r["result"]
            gData = JSON.parse(project["content"])
            if (!gData.cells) {
              gData.cells = []
              gData.name = project["name"]
            }
            console.log("Load:", project, gData)
            graph.fromJSON(gData)
            setTitle(project["name"])
          }).catch(e => {
            console.error(e)
            alert("打开项目失败 " + e, "error")
            navigate("/start")
          })
          // console.log('init success')
        }
      } catch (e) {
        console.error(e);
      }
      RX.addInitProgress();
    }
  });

  const downloadFile = (id) => {
    axios({
      url: `${API_URL}/api/download/${id}.zip`,
      method: 'GET',
      responseType: 'blob', // 设置响应类型为blob，以便处理二进制数据
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${id}.zip`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  };

  // 当拖拽设备
  function onDragDevice(e, device) {
    const cr = refContainer.current.getBoundingClientRect();
    const scale = graph.zoom();
    const size = 132 * scale;
    const box = document.createElement("div");
    box.style.position = "fixed";
    box.style.width = size + "px";
    box.style.height = size + "px";
    box.style.left = e.pageX - size / 2 + "px";
    box.style.top = e.pageY - size / 2 + "px";
    box.style.textAlign = "center";
    box.style.borderRadius = size / 7.3 + "px";
    box.style.border = "0 dashed #aaa";
    box.style.zIndex = 8;
    const img = document.createElement("img");
    img.className = Style.deviceShowImg;
    img.src = device.img;
    img.style.marginTop = size / 2 + "px";
    img.style.transform = "translateY(-50%)";
    img.style.pointerEvents = "none";
    if (!['eCatEye', 'fishTank', 'gate', 'gateway', 'sensor'].includes(device.id)) {
      img.className = Style.needSize;
    }
    e.target.style.filter = "blur(4px)";
    document.body.appendChild(box);
    box.appendChild(img);
    // 当鼠标移动
    const onMouseMove = (te) => {
      const x = te.pageX;
      const y = te.pageY;
      box.style.left = x - size / 2 + "px";
      box.style.top = y - size / 2 + "px";
      if (x > cr.x && x < cr.x + cr.width && y > cr.y && y < cr.y + cr.height) {
        box.style.borderWidth = "2px";
      } else {
        box.style.borderWidth = "0";
      }
    };
    // 当鼠标抬起
    const onMouseUp = (te) => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
      document.body.removeChild(box);
      e.target.style.filter = "blur(0)";
      if (te.pageX <= 301) return;

      let dn = RX.createNewDeviceId();
      RxHistory.startBatch('Editor CreateDevice')
      const p = graph.pageToLocal(te.pageX, te.pageY);
      const node = createNodeData(dn, device, p, scale)
      gData.cells.push(node);
      graph.addNode(node);
      RxHistory.stopBatch('Editor CreateDevice')
    };
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
    return false;
  }

  // 参数生成
  const edgePopoverId = edgeAnchorEl ? "popover" : undefined;
  const [drawerMarginLeft, setDrawerMarginLeft] = useState(15)
  const [rows, setRows] = useState(3)
  const [sMode, setSMode] = useState(0) // 0 无 1 搜索 2 筛选
  const changeSMode = (v) => {
    // setDeviceGroupIndex(0)
    setSearchText('')
    setSMode(v)
  }

  // 注册窗口弹出
  const [alertMessage, setAlertMessage] = useState('暂无信息');
  const [alertType, setAlertType] = useState('info');
  const [alertShow, setAlertShow] = useState(false);
  MuiAlert.setMessage = setAlertMessage;
  MuiAlert.setType = setAlertType;
  MuiAlert.setShow = setAlertShow;

  // 发送图形化数据文件
  const sendGraphData = () => {
    const data = RxEvent.getGraphData()
    console.log(data)
    console.log("正在编译...")
    tip("正在编译...")
    axios.post(API_URL + '/api/generate', data).then(res => {
      console.log(res)
      if (res.data.code === 200) {
        console.log("编译成功")
        tip("编译成功", "success")
        let cid = res.data.project
        console.log("正在下载: " + cid)
        downloadFile(cid)
        axios.get(API_URL + '/api/download/' + cid + '.js', data).then(res => {
          CodeStorage.js = res.data.toString()
        })
        axios.get(API_URL + '/api/download/' + cid + '.ts', data).then(res => {
          let code = res.data.toString()
          let i = code.indexOf("\n\n")
          code = '/* ----------\n' + code.substring(0, i)
            + '\n---------- */\nlet [Property, From, LogicProperty, NormalParser, DpParser]: any = {}\n'
            + 'let [DeviceManager, PDO, PDS, Queue, HaveNotSupport, mlog, subscribeLog]: any = {}\n'
            + code.substring(i)
          CodeStorage.ts = code
          window.setNewCode('typescript')
        })
      } else {
        console.error("编译失败")
        console.error(res)
        tip("编译失败  详情请见控制台", "error")
      }
    })
  }

  return (
    <div className={Style.Editor} onKeyUp={e => {
      if (e.key === 's' && e.ctrlKey) {
        save()
      }
    }}>
      <div className={Style.header}>
        <div className={Style.toolbar}>
          <Link to="/start">
            <ToolBtn icon="./icon/home.svg" text="主页"/>
          </Link>
          <ToolBtn icon="./icon/save.svg" text="保存" onClick={(e) => {
            save()
          }}/>
          <div className={Style.divider}/>
          <ToolBtn icon="./icon/choose.svg" text="选择"/>
          <ToolBtn icon="./icon/undo.svg" text="撤回" onClick={(e) => {
            RX.graph?.undo();
          }}/>
          <ToolBtn icon="./icon/redo.svg" text="重做" onClick={(e) => {
            RX.graph?.redo();
          }}/>
          <ToolBtn icon="./icon/copy.svg" text="复制" onClick={(e) => {
            RxClipboard.actionCopy();
          }}/>
          <ToolBtn icon="./icon/paste.svg" text="粘贴" onClick={(e) => {
            RxClipboard.actionPaste();
          }}/>
          {/*<ToolBtn icon="./icon/connect.svg" text="组合"/>*/}
          <ToolBtn icon="./icon/drag.svg" text="拖拽"/>
          {/*<ToolBtn icon="./icon/comment.svg" text="注释"/>*/}
          <ToolBtn
            icon="./icon/logic.svg"
            text="逻辑板"
            onClick={e => {
              const pos = getClientCenter();
              const gPos = graph.pageToLocal(pos.x, pos.y);
              RX.newLogicNode(gPos.x - 288, gPos.y - 410);
            }}
          />
        </div>
        <div className={Style.title}>
          <span
            style={{display: titleEditing ? 'none' : 'inline'}}
            onClick={e => {
              RhineBlock.setSelected(null)
              setTitleEditing(true)
              const input = titleInputRef.current
              if (!input) return
              setTimeout(() => {
                input.focus()
                input.setSelectionRange(input.value.length, input.value.length)
              }, 1)
            }}
          >{title}</span>
          <input
            ref={titleInputRef}
            style={{display: titleEditing ? 'inline' : 'none'}}
            onBlur={e => setTitleEditing(false)}
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </div>
        <div className={Style.sendBtn}>
          <Button
            variant="contained"
            endIcon={<SendIcon/>}
            onClick={e => {
              sendGraphData()
            }}
          >
            Run
          </Button>
        </div>
      </div>
      <HorizontalSplitLayout className={Style.main} onChange={(left, size)=>{
        if(left){
          let rs = parseInt(size / 90)
          setRows(rs)
          setDrawerMarginLeft((size - rs * 90) / 2)
        }else{
          window.resizeMonaco();
        }
      }}>
        <SplitLayout defaultTopSize={500}>
          <div className={Style.deviceList}>
            <div className={Style.listTitle}>
              <span>设备库</span>
              <div className={Style.deviceBarIcons}>
                <IconButton aria-label="搜索" onClick={
                  ()=>changeSMode(sMode === 1 ? 0 : 1)
                } color={sMode !== 1 ? 'neutral' : 'primary'}>
                  <Search />
                </IconButton>
                <IconButton aria-label="筛选" onClick={
                  ()=>changeSMode(sMode === 2 ? 0 : 2)
                } color={sMode !== 2 ? 'neutral' : 'primary'}>
                  <FilterAltOutlined />
                </IconButton>
              </div>
            </div>
            <div className={Style.deviceBody}>
              <div className={Style.sfBar + ' ' + Style.searchBar} style={{
                display: sMode === 1 ? 'block' : 'none',
              }}>
                <Search color={'disabled'} sx={{position: 'absolute', left: '9px', top: '12px'}}/>
                <div
                  style={{
                    display: "inline-block",
                    width: 140,
                    height: 32,
                  }}
                >
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={"text"}
                    size={"small"}
                    value={searchText}
                    sx={{position: 'absolute', left: '44px', right: '40px', height: 32}}
                    onChange={(e)=>setSearchText(e.target.value)}
                  />
                </div>
                <IconButton aria-label="关闭" sx={{position: 'absolute', right: '4px', top: '4px'}} onClick={()=>changeSMode(0)}>
                  <Close />
                </IconButton>
              </div>
              <div className={Style.sfBar + ' ' + Style.filterBar} style={{
                display: sMode === 2 ? 'block' : 'none',
              }}>
                <FilterAltOutlined color={'disabled'} sx={{position: 'absolute', left: '9px', top: '12px'}}/>
                <Select
                  labelId=""
                  id="select1"
                  size="small"
                  value={deviceGroupIndex}
                  sx={{position: 'absolute', left: '44px', right: '40px', height: 32}}
                  onChange={(e)=>setDeviceGroupIndex(e.target.value)}
                >
                  {
                    tags.map((text, i)=>{
                      return <MenuItem value={i} key={i}>{text}</MenuItem>
                    })
                  }
                </Select>
                <IconButton aria-label="关闭" sx={{position: 'absolute', right: '4px', top: '4px'}} onClick={()=>changeSMode(0)}>
                  <Close />
                </IconButton>
              </div>
              <div className={Style.deviceGridHolder + " " + Style.betterScroll} style={{
                top: sMode === 0 ? '0px' : '49px'
              }}>
                <div className={Style.deviceGrid}>
                  {filterDevices(devices, searchText, tags[deviceGroupIndex]).filter(s => s.show).map((device, i) => {
                    return (
                      <div
                        key={i}
                        className={Style.deviceItem}
                        style={{
                          top: parseInt(i / rows) * 134 + 12,
                          left: (i % rows) * 90 + 5 + drawerMarginLeft,
                        }}
                      >
                        <div
                          className={Style.deviceImg}
                          onMouseDown={(e) => {
                            onDragDevice(e, device);
                          }}
                        >
                          <img src={device.img} alt=""
                               className={['eCatEye', 'fishTank', 'gate', 'gateway', 'sensor'].includes(device.id) ? 'defaultSize' : Style.needSize}/>
                        </div>
                        <div className={Style.deviceName}>{device.name}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className={Style.blockList}>
            <div className={Style.listTitle}>
              <span>逻辑列表</span>
            </div>
            <BlockToolbox></BlockToolbox>
          </div>
        </SplitLayout>
        <div className={Style.containerHolder} ref={refContainerHolder}>
          <div id="container" className={Style.container} ref={refContainer} style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          }}/>
          <div className={Style.centerBtn + " " + Style.svgBtn} onClick={(e)=>{
            RX.graph?.centerContent();
          }}>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.66667 3.25H4.33333C3.73502 3.25 3.25 3.73502 3.25 4.33333V8.66667" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M8.66667 22.7507H4.33333C3.73502 22.7507 3.25 22.2656 3.25 21.6673V17.334" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M17.3335 22.7507H21.6668C22.2652 22.7507 22.7502 22.2656 22.7502 21.6673V17.334" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M17.3335 3.25H21.6668C22.2652 3.25 22.7502 3.73502 22.7502 4.33333V8.66667" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M13.0002 16.7923C15.0942 16.7923 16.7918 15.0947 16.7918 13.0007C16.7918 10.9066 15.0942 9.20898 13.0002 9.20898C10.9061 9.20898 9.2085 10.9066 9.2085 13.0007C9.2085 15.0947 10.9061 16.7923 13.0002 16.7923Z" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M13 9.20768V7.04102" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M13 18.9577V16.791" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M18.9582 13H16.7915" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M9.20817 13H7.0415" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12.9998 14.0827C13.5982 14.0827 14.0832 13.5977 14.0832 12.9993C14.0832 12.401 13.5982 11.916 12.9998 11.916C12.4015 11.916 11.9165 12.401 11.9165 12.9993C11.9165 13.5977 12.4015 14.0827 12.9998 14.0827Z" fill="#CCCCCC"/>
            </svg>
          </div>
          <div className={Style.scaleBtnUp + " " + Style.svgBtn} onClick={(e)=>{
            RX.scale(true)
          }}>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.3748 20.5827C16.4604 20.5827 20.5832 16.4599 20.5832 11.3743C20.5832 6.28875 16.4604 2.16602 11.3748 2.16602C6.28924 2.16602 2.1665 6.28875 2.1665 11.3743C2.1665 16.4599 6.28924 20.5827 11.3748 20.5827Z" stroke="#CCCCCC" strokeWidth="2" strokeLinejoin="round"/>
              <path d="M11.375 8.125V14.625" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M8.1333 11.3835L14.6249 11.375" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M17.9951 17.9941L22.5913 22.5903" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
          <div className={Style.scaleBtnDown + " " + Style.svgBtn} onClick={(e)=>{
            RX.scale(false)
          }}>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.3753 20.5827C16.4609 20.5827 20.5837 16.4599 20.5837 11.3743C20.5837 6.28875 16.4609 2.16602 11.3753 2.16602C6.28973 2.16602 2.16699 6.28875 2.16699 11.3743C2.16699 16.4599 6.28973 20.5827 11.3753 20.5827Z" stroke="#CCCCCC" strokeWidth="2" strokeLinejoin="round"/>
              <path d="M8.125 11.375H14.625" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M17.9951 17.9941L22.5913 22.5903" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
          <div className={Style.rubbishBtn + " " + Style.svgBtn} id={"rubbishBin"}>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M12.0312 10.0406L12.8271 5.39844H23.1725L23.9683 10.0406" stroke="#CCCCCC" strokeWidth="2"
                    strokeLinejoin="round"/>
              <path d="M6.06201 10.041H29.9362" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round"/>
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M26.6214 10.041L25.295 30.5993H10.7052L9.37891 10.041H26.6214Z" stroke="#CCCCCC" strokeWidth="2"
                    strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M14.6841 25.293H21.3158" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          </div>
        </div>
        <SplitLayout onChange={()=>{
          if(window.resizeMonaco){
            window.resizeMonaco();
          }
        }}>
          <div className={Style.deviceDetail + " " + Style.betterScroll}>
            <div className={Style.listTitle}>
              <span>设备详情</span>
            </div>
            <DeviceDetail
              type={0}
              property={0}
              source={currentDevice}
              dataType={0}
            />
          </div>
          <div className={Style.codeEditor}>
            <div className={Style.listTitle}>
              <span>逻辑代码</span>
              <h1></h1>
              <div className={Style.language} onClick={e => {
                window.setNewCode('logic')
              }}>Logic</div>
              <div className={Style.language} onClick={e => {
                window.setNewCode('javascript')
              }}>JavaScript</div>
              <div className={Style.language} onClick={e => {
                window.setNewCode('typescript')
              }}>TypeScript</div>
            </div>
            <CodeEditor className={Style.codeEditorContent}/>
          </div>
        </SplitLayout>
      </HorizontalSplitLayout>
      <Popover
        id={edgePopoverId}
        open={Boolean(edgeAnchorEl)}
        anchorEl={edgeAnchorEl}
        onClose={handleClosePopover}
        anchorReference="anchorPosition"
        anchorPosition={getPosition()}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Paper sx={{width: 136}}>
          <MenuList dense>
            <MenuItem onClick={onDeleteEdge}>删除连线</MenuItem>
            {
              lineStatus !== undefined ? <MenuItem onClick={changeEdgeStatus}>{lineStatus?"启用":"禁用"}</MenuItem> : null
            }
          </MenuList>
        </Paper>
      </Popover>
      <div
        className={Style.portHover}
        style={{
          top: portHoverPosition.y,
          left: portHoverPosition.x,
          display: portHoverShow ? "inline-block" : "none",
        }}
      >
        {portHoverData.map((data, i) => {
          return (
            <div key={i}>
              <span className={Style.portHoverKey} style={{top: i * 30 + 20}}>
                {data[0]}
              </span>
              <span
                className={Style.portHoverValue}
                style={{top: i * 30 + 20}}
              >
                {data[1]}
              </span>
            </div>
          );
        })}
      </div>
      <Snackbar
        open={alertShow}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert
          severity={alertType}
          variant="outlined"
          sx={{ width: "320px", background: "#f7fff7" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Editor;

// 是否拖拽至垃圾桶
export function checkInRubbishBin(x, y) {
  const rubbishBin = document.getElementById("rubbishBin");
  const rubbishBinRect = rubbishBin.getBoundingClientRect();
  return (
    x >= rubbishBinRect.left &&
    x <= rubbishBinRect.right &&
    y >= rubbishBinRect.top &&
    y <= rubbishBinRect.bottom
  );
}
