import React from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import Style from "./App.module.css";
import Login from "./Login/Login";
import Start from "./Start/Start";
import Editor from "./Editor/Editor";
import { ThemeProvider } from "@mui/material";
import lightTheme from "./LightTheme";
import {enqueueSnackbar, SnackbarProvider} from "notistack";
import Api from "./Api";

function App() {
  Api.navigate = useNavigate()

  return (
    <ThemeProvider theme={lightTheme}>
      <SnackbarProvider maxSnack={5}>
        <div className={Style.App}>
          <Routes className={Style.holder}>
            <Route path="/" element={<Login />} />
            <Route path="/start" element={<Start />} />
            <Route path="/editor" element={<Editor />} />
          </Routes>
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;

export const tip = (text, type = 'default') => {
  enqueueSnackbar(text, {
    variant: type,
    autoHideDuration: 1200,
    anchorOrigin: { vertical: 'right', horizontal: 'bottom' },
  })
}

