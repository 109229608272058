
export default class EventsSplit {
  
  // IOT ONLY
  static make(code: string) {
    const events = code.split("\n// @EVENT ")
    events.shift()
    const ers = []
    for (let event of events) {
      event = event.split("\n// Not Used Blocks\n")[0]
      ers.push({
        'key': event.split("\n")[0],
        'code': event.split("\n").slice(1).join("\n").trim() + "\n"
      })
    }
    return ers
  }
}
