
enum Direction {
	left = 'left',
	right = 'right',
	top = 'top',
	bottom = 'bottom',
	leftTop = 'left-top',
	leftBottom = 'left-bottom',
	rightTop = 'right-top',
	rightBottom = 'right-bottom',
}

export default Direction
