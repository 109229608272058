import { BlockType, IBlock } from "../core/block/block.class";
import { RhineBlock } from "../core/RhineBlock";
import { FieldType } from "../core/block/arg.class";

const tempBlocks: IBlock[] = [
  {
    name: "temp_range",
    type: BlockType.Output,
    output: "Range",
    color: "#71dccc",
    lines: [
      [
        { text: "区间" },
        {
          field: FieldType.Dropdown,
          option: ["[", "("],
        },
        {
          field: FieldType.Number,
          default: "0",
        },
        { text: "," },
        {
          field: FieldType.Number,
          default: "10",
        },
        {
          field: FieldType.Dropdown,
          option: ["]", ")"],
          default: 1,
        },
        { text: "  " },
      ],
    ],
  },
  {
    name: "temp_time",
    type: BlockType.Output,
    output: 'Number',
    color: "#71dccc",
    lines: [
      [
        { text: "时间" },
        {
          field: FieldType.Number,
          default: "18",
        },
        { text: ":" },
        {
          field: FieldType.Number,
          default: "00",
        },
      ],
    ],
  },
  {
    name: "temp_week_time",
    type: BlockType.Output,
    output: 'Number',
    color: "#71dccc",
    lines: [
      [
        { text: "星期" },
        {
          field: FieldType.Dropdown, option: ["一", "二", "三", "四", "五", "六", "日"]
        },
        { text: "" },
      ],
    ],
  },
  {
    name: "temp_now_time",
    type: BlockType.Output,
    output: 'Number',
    color: "#71dccc",
    lines: [
      [
        { text: "当前时间" },
        {
          field: FieldType.Dropdown,
          option: ["时-分-秒", "时-分", "时"],
          default: 0,
        },
        { text: " " },
      ],
    ],
  },
  // 获取当前的日期：前面的这里滚动条问题，导致年月日时分看不到，遮挡了TODO:
  {
    name: "temp_get_now_time",
    type: BlockType.Output,
    output: 'Number',
    color: "#71dccc",
    lines: [
      [
        { text: "当前日期" },
        {
          field: FieldType.Dropdown,
          option: ["年-月-日", "星期", "年", "月", "日"],
          default: 0,
        },
        { text: " " },
      ],
    ],
  },
  {
    name: "temp_date",
    type: BlockType.Output,
    output: 'Number',
    color: "#71dccc",
    lines: [
      [
        {
          field: FieldType.Number,
          default: "2022",
        },
        { text: "年" },
        {
          field: FieldType.Number,
          default: "1",
        },
        { text: "月" },
        {
          field: FieldType.Number,
          default: "1",
        },
        { text: "日" },
      ],
    ],
  },
  {
    name: "temp_date_in_range",
    type: BlockType.Output,
    output: 'Boolean',
    color: "#84c974",
    lines: [
      [
        { text: "" },
        { value: "num" },
        { text: "在 " },
        { value: "num" },
        { text: "到" },
        { value: "num" },
        { text: " 之间" },
        { text: " " },
      ],
    ],
  },
];

RhineBlock.registerBlocksData(tempBlocks);

export default tempBlocks;
