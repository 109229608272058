import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Popover,
  Snackbar,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import React from "react";

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    borderColor: "#eeeeee",
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiSvgIcon-root": {
    width: 24,
    height: 24,
  },
}));

export const MsgDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    borderColor: "#eeeeee",
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiSvgIcon-root": {
    width: 24,
    height: 24,
  },
}));

export const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, fontSize: 18 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon fill={"#9E9E9E"} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

MsgDialog.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};