
export default class Once {

  static list = {}

  static check(name, len = 200) {
    let last = this.list[name]
    this.list[name] = new Date().getTime()
    if (!last) return false
    return new Date().getTime() - last < len
  }

}
