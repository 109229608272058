import Arg, {ArgType, IArg} from "./arg.class";
import {ChangeEventType, iotZoom, RhineBlock} from "../RhineBlock";
import DragManager from "../drag/drag-manager";
import {Graph} from "../view/graph/graph";
import {parseType} from "../render/base/type-parse";
import {getFieldHolderView} from "../render/base/field-provider";
import {ICompiler} from "../compiler/base/base-compiler";
import RxHistory from "../../../App/Editor/RX/history/RxHistory";

export default class Block {
  view: SVGElement | null = null; // 根dom元素
  width: number = 0; // 宽度
  height: number = 0; // 高度

  next: Arg = Arg.fromJson(-1, {type: ArgType.Statement}); // 下方图形块参数
  private previous: Block | undefined; // 上方图形块

  getPrevious(): Block | undefined {
    return this.previous
  }
  setPrevious(block: Block | undefined) {
    this.previous = block
  }


  parent?: Block; // 父图形块
  private graph?: Graph; // 所处画布

  isShadow: boolean = false; // 是否为阴影块
  isOpacity: OpacityType = OpacityType.Default; // 是否为透明状态
  isSelected: boolean = false; // 是否为选中状态5
  isDisable: boolean = false; // 是否为禁用状态

  // 仅根元素有以下参数的值
  x: number | undefined; // 在画布中的坐标X
  y: number | undefined; // 在画布中的坐标Y
  zIndex: number = 1; // 在画布中的层级

  constructor(
    public name: string,
    public type: BlockType,
    public lines: Arg[][],
    public output: string[],
    public color: string,
    public compiler: ICompiler | undefined,
    public level: number,
  ) {}

  // 通过Item参数构造Block实例
  static fromItem(
    item: Item,
    toolboxMode: boolean = false,
    parent?: Block
  ): Block {
    let data = RhineBlock.getBlockData(item.block);
    if (!data) {
      console.error("Block is not register", item.block);
      data = RhineBlock.getBlockData("unknown")!;
    }
    if (toolboxMode) {
      item.args = typeof data.toolbox !== "boolean" ? data.toolbox : [];
    }
    return Block.fromDataAndItem(data, item, parent)
  }

  // 通过多图形块组合构造实例
  private static fromDataAndItem(
    data: IBlock,
    item?: Item,
    parent?: Block,
  ): Block {
    let argI = 0;
    // 处理数据中的内部参数
    const lines = data.lines.map((line) => {
      return line.map((arg) => {
        if (arg.text !== undefined) arg.type = ArgType.Text;
        const id = arg.type === ArgType.Text ? -1 : argI++;
        return Arg.fromJson(id, arg);
      });
    });
    // 构造图形块实例
    const block = new Block(
      data.name,
      data.type ? data.type : BlockType.Statement,
      lines,
      data.output ? parseType(data.output) : [""],
      data.color ? data.color : "#329eff",
      data.compiler,
      data.level ? data.level : RhineBlock.Compiler.Order.UNKNOWN
    );
    if (item && item.args) {
      // 设置内部信息参数
      block.setArgsFromItems(item.args);
    }
    // 设置其他相关属性
    block.parent = parent;
    block.isDisable = item?.isDisable as boolean
    block.isSelected = item?.isSelected as boolean;
    return block;
  }

  // 是否为工具箱中的图形块
  isInToolbox(): boolean {
    return Boolean(this.getGraph()?.isToolbox);
  }

  // 设置鼠标事件
  setMouseEvent(body: SVGPathElement): void {
    if (this.getGraph()?.isToolbox) {
      if (this.graph) {
        this.setOnDragEvent(body);
      }
    } else {
      this.setOnDragEvent(body);
    }
  }

  // 拖拽事件
  setOnDragEvent(body: SVGPathElement): void {
    body.onmousedown = (e) => {
      RhineBlock.onRemoveFocus(e);
      if (e.button === 0) {
        DragManager.onDragBlockDown(this, e, this.isInToolbox());
      } else if (e.button === 2) {
        // 创建下拉菜单
        const holder = document.createElement("div");
        holder.className = "rb-field-dropdown-holder";
        getFieldHolderView().appendChild(holder);

        holder.style.position = "absolute";
        holder.style.left = `${e.clientX}px`;
        holder.style.top = `${e.clientY}px`;
        holder.style.width = `${140}px`;

        const optTextList = ["删除逻辑块"];
        if(!this.getGraph()?.isDisable) {
          optTextList.push(this.getDisableParent() ? "启用" : "禁用");
        }
        for (const text of optTextList) {
          const optEl = document.createElement("div");
          holder.appendChild(optEl);
          optEl.className = "rb-field-dropdown-option";
          optEl.innerText = text;
          optEl.style.width = `${140 - 24}px`;
          // 下拉菜单选项点击事件
          optEl.addEventListener("click", (e) => {
            if(text === optTextList[0]) {
              this.remove();
            }else{
              RxHistory.startBatch('BlockClass Drag')
              const disableParent = this.getDisableParent() || this;
              disableParent.setDisable(!disableParent.isDisable);
              if(disableParent.isDisable) {
                disableParent.recurMapBlock((block) => {
                });
              }
              this.rerender();
              // console.log(disableParent)
              RxHistory.stopBatch('BlockClass Drag')
            }
            holder.style.opacity = "0";
            setTimeout(() => {
              holder.remove();
            }, 200);
            return false;
          });
        }
        // 当下拉菜单失去焦点
        const onFocusOut = (e?: Event) => {
          if (e && e.target === holder) return;
          if (
            e &&
            e.target instanceof HTMLElement &&
            e.target.className.includes("rb-field-dropdown-option")
          )
            return;
          document.removeEventListener("mousedown", onFocusOut);
          holder.remove();
          RhineBlock.onRemoveFocus = () => {
          }
          setTimeout(() => {
            if (RhineBlock.waitEvent && DragManager.current == null) {
              const we = RhineBlock.waitEvent
              RhineBlock.waitEvent = null
              we.graph.onChangeEvent(we)
              RxHistory.startBatch('LogicNode FreshBoardData Extra')
            }
          }, 200)
        };
        RhineBlock.onRemoveFocus = onFocusOut;
        document.addEventListener("mousedown", onFocusOut);

        setTimeout(() => {
          holder.style.transform = "translate(0, 8px)";
          holder.style.opacity = "1";
        });
        e.stopPropagation();
      }
    };
  }

  // 通过内容设置参数
  setArgFromContent(
    content: Block,
    item: Item | null = null,
    rerender: boolean = false
  ): void {
    const arg = this.getArgByContent(content);
    if (!arg) return;
    this.setArgByItem(arg, item, rerender);
  }
  // 通过内容寻找属于哪个参数
  getArgByContent(content: Block): Arg | void {
    let result = undefined;
    this.mapBlockArgs((arg) => {
      if (arg.content === content) {
        result = arg;
      }
    });
    return result;
  }
  // 遍历所有参数
  mapBlockArgs(fn: (arg: Arg) => boolean | void) {
    this.mapValueArgs((arg) => {
      if (arg.isBlockType()) {
        return fn(arg);
      }
    }, true);
  }
  // 递归遍历所有自己以及内部图形块
  recurMapBlock(fn: (block: Block) => void) {
    fn(this);
    this.mapBlockArgs((arg) => {
      if (arg.content) {
        (arg.content as Block).recurMapBlock(fn);
      }
    });
  }
  // 清空自身参数的值
  clearArgs(): void {
    this.mapValueArgs((arg) => {
      arg.clearView();
    });
  }

  // 克隆一个图形块
  clone(): Block {
    const data = RhineBlock.getBlockData(this.name)!;
    const block = Block.fromDataAndItem(data, this.getItem(), this.parent);
    block.isDisable = this.isDisable;
    block.graph = this.graph;
    return block
  }
  // 是否有帽子
  hadHat(): boolean {
    return this.type === BlockType.HatSingle || this.type === BlockType.Hat;
  }
  // 是否有下方接口
  hadNext(): boolean {
    return (
      [BlockType.Hat, BlockType.Statement, BlockType.Start].indexOf(this.type) >
      -1
    );
  }
  // 是否有上方接口
  hadPrevious(): boolean {
    return this.type === BlockType.Statement || this.type === BlockType.Finish;
  }
  // 是否为输出型图形块
  isOutputType(): boolean {
    return this.type === BlockType.Output;
  }
  // 是否为含有代码块的图形块
  hadStatementInLine(i: number): boolean {
    return this.lines[i].some((arg) => arg.type === ArgType.Statement);
  }

  // 遍历所有参数
  mapArgs(
    fn: (arg: Arg, i: number, j: number) => boolean | void,
    next = true
  ): void {
    let breakFlag: boolean | void = false;
    this.lines.some((line, i) => {
      line.some((arg, j) => {
        breakFlag = fn(arg, i, j);
        return Boolean(breakFlag);
      });
      return Boolean(breakFlag);
    });
    if (this.hadNext() && this.next && next) {
      fn(this.next, this.lines.length, 0);
    }
  }
  // 遍历直接输入值的参数
  mapValueArgs(
    fn: (arg: Arg, id: number, i: number, j: number) => boolean | void,
    next = true
  ): void {
    this.mapArgs((arg, i, j) => {
      if (arg.type !== ArgType.Text) {
        return fn(arg, arg.id, i, j);
      }
    }, next);
  }

  // 通过Item设置参数
  setArgByItem(
    arg: Arg,
    item: ItemValue,
    rerender: boolean = false,
    opacity: boolean = false
  ): void {
    if (!item) {
      arg.clearView();
    } else if (typeof item === "object") {
      arg.clearView();
      const blockData = RhineBlock.getBlockData(item.block);
      if (!blockData) {
        console.error("Block is not register", item.block);
        return;
      }
      if (arg.type === ArgType.Value && blockData.type === BlockType.Output) {
        arg.content = Block.fromDataAndItem(blockData, item, this);
        if (opacity) {
          arg.content.isOpacity = OpacityType.True;
        }
      } else if (
        arg.type === ArgType.Statement &&
        (blockData.type === BlockType.Statement ||
          blockData.type === BlockType.Finish)
      ) {
        arg.content = Block.fromDataAndItem(blockData, item, this);
        if (opacity) {
          arg.content.isOpacity = OpacityType.True;
        }
        if (item.next) {
          if (item.args) arg.content.setArgsFromItems(item.args);
          arg.content.setPrevious(this);
        }
      } else {
        return;
      }
      arg.content.parent = this;
      if (this.next === arg) {
        arg.content.setPrevious(this);
      }
    } else {
      arg.content = item;
    }
    if (rerender) this.rerender()
  }
  // 设置参数的Block
  setArgByBlock(
    arg: Arg,
    block: Block | null,
    rerender: boolean = false
  ): void {
    if (!block) {
      arg.clearView();
    } else {
      if (arg.isBlockType()) {
        arg.clearView();
        arg.content = block;
        arg.content.parent = this;
        if (this.next === arg) {
          arg.content.setPrevious(this);
        }
      }
    }
    if (rerender) this.rerender()
  }
  // 通过内容设置多个参数
  setArgsFromItems(contents: ItemValue[]): void {
    if (!contents) return;
    try {
      // 设置所有内部参数
      this.mapValueArgs((arg, id) => {
        const content = contents[id];
        if (!content) return;
        this.setArgByItem(arg, content);
      }, false);
      // 设置下方参数
      const content = contents[contents.length - 1];
      if (content && typeof content === "object" && content.next) {
        this.setArgByItem(this.next, content);
      }
    } catch (e) {
      console.error("Args is invalid for this block", e);
    }
  }

  // 重渲染
  rerender(): void {
    RhineBlock.Render.rerender(this, RhineBlock.getZoom());
  }

  // 获取指定id的参数
  getArg(id: number): Arg | null {
    for (const line of this.lines) {
      for (const arg of line) {
        if (arg.id === id) return arg;
      }
    }
    return null;
  }

  // 获取图形块的内容数据
  getItem(): Item {
    const contents: ItemValue[] = [];
    this.mapValueArgs((arg, id) => {
      if (arg.type === ArgType.Statement || arg.type === ArgType.Value) {
        if (arg.content) {
          contents.push(this.getArgBlockItem(arg));
        } else {
          contents.push(null);
        }
      } else if (typeof arg.content !== "object") {
        contents.push(arg.content);
      } else {
        contents.push(null);
      }
    }, false);
    if (this.next.content) {
      contents.push(this.getArgBlockItem(this.next));
      (contents[contents.length - 1]! as Item).next = true;
    }
    const item: Item = {
      block: this.name,
      args: contents,
    };
    const pos = this.getPosition()
    if (pos) {
      item.x = pos.x
      item.y = pos.y
    }
    if (this.isShadow) item.shadow = true;
    if (this.isDisable) item.isDisable = true;
    if (this.isSelected) item.isSelected = true;
    return item;
  }

  getArgBlockItem(arg: Arg): Item | null {
    if (arg.isBlockType() && arg.content) {
      return (arg.content as Block).getItem();
    }
    return null;
  }

  // 设置图形块位置
  setPosition(x: number, y: number, zoom: number = RhineBlock.getZoom()): void {
    this.x = x;
    this.y = y;
    this.view?.setAttribute("transform", `translate(${x / zoom}, ${y / zoom})`);
  }

  // 获取透明度状态
  getOpacity(): boolean {
    let block: Block = this;
    while (block.isOpacity === OpacityType.Default && block.parent) {
      block = block.parent;
    }
    return block.isOpacity === OpacityType.True;
  }

  // 获取画布
  getGraph(): Graph | null {
    if(this.graph) return this.graph
    return this.parent ? this.parent.getGraph() : null;
  }
  setGraph(graph: Graph): void {
    this.graph = graph
  }

  // 再父元素中删除自身
  remove(removeEvent = true): void {
    // console.trace('remove block', this)
    if (this.parent) {
      this.parent.removeChild(this);
      removeEvent ? this.onChange(ChangeEventType.REMOVE) : null;
    } else if (this.graph && !this.graph.isToolbox) {
      this.graph.remove(this, removeEvent);
    }
  }

  // 根据图形块内容删除子元素
  removeChild(child: Block): void {
    const arg = this.getArgByContent(child);
    if (!arg) return;
    if (arg.type == ArgType.Statement) {
      const nextBlock = child.next.content as Block;
      if (nextBlock) {
        nextBlock.parent = this;
        nextBlock.setPrevious(arg === this.next ? this : undefined);
        this.setArgByBlock(arg, nextBlock, true);
      } else {
        this.setArgByItem(arg, null, true);
      }
    } else {
      this.setArgByItem(arg, null, true);
    }
  }

  // 设置是否选中
  setSelected(flag: boolean): void {
    this.isSelected = flag

    if (!this.view) return;
    const bodyList = this.view.getElementsByClassName('rb-block-body')
    if (bodyList.length < 1) return;
    const body = bodyList[0] as SVGPathElement

    if (this.isSelected) {
      // console.log('first draw border', body)
      body.setAttribute('stroke', 'rgba(255,155,20,0.85)')
      body.setAttribute('stroke-width', '2.4')
    } else {
      body.setAttribute('stroke', 'none')
      body.setAttribute('stroke-width', '0')
    }
  }

  // 获取在画布中的位置
  getPositionInGraph(): { x: number; y: number } {
    let root: Block = this;
    while (root.parent) {
      root = root.parent;
    }
    const rootRect = root.view?.getBoundingClientRect();
    const rect = this.view?.getBoundingClientRect();
    if (!rootRect || !rect) return {x: 0, y: 0}
    const zoom = RhineBlock.getZoom();
    const pos = root.getPosition() || {x: 0, y: 0}
    return {
      x: (rect.x - rootRect.x) / zoom + pos.x,
      y: (rect.y - rootRect.y) / zoom + pos.y,
    }
  }

  // 触发变化事件
  onChange(e: ChangeEventType, arg?: Arg) {
    this.getGraph()?.onChange(e, this, arg)
  }

  // 获取相对父元素位置
  getPosition(): { x: number; y: number } | undefined {
    if (this.x != undefined && this.y != undefined) {
      return {x: this.x, y: this.y}
    }
  }

  // 是否为根元素
  isRoot(): boolean {
    return this.parent === undefined
  }

  // 设置禁用状态
  setDisable(v: boolean, silent: boolean = false): void {
    this.isDisable = v
    if(!silent) this.getGraph()?.onChange(ChangeEventType.DISABLE, this)
  }

  // 获取画布禁用状态
  getGraphDisable(): boolean {
    return this.getGraph()?.isDisable as boolean
  }
  // 获取父元素禁用状态
  getDisableParent(): Block | null {
    if (this.isDisable) return this
    if (this.parent) return this.parent.getDisableParent();
    return null;
  }

  // 获取最终显示的禁用状态
  getDisableShow(): boolean {
    if(this.isRoot()){
      if(this.getGraphDisable()) return true
      return this.isDisable && this === this.getDisableParent()
    }else{
      return this.isDisable && this === this.getDisableParent() && !this.getGraphDisable()
    }
  }
}

// 图形块类型
export enum BlockType { // Next  Previous  Hat  Output
  Statement, //     //   √       √
  Output, //        //                         √
  Hat, //           //   √              √
  Single, //        //
  Start, //         //   √
  Finish, //        //           √
  HatSingle, //     //                  √
}

// 图形块透明类型
export enum OpacityType {
  Default, // 根据父控件
  True,
  False,
}

// 图形块申明接口
export interface IBlock {
  name: string;
  type?: BlockType;
  lines: IArg[][];
  output?: string | null;
  color?: string;

  toolbox?: ItemValue[] | boolean;
  compiler?: ICompiler;
  level?: number;
}

// 内容类型
export type ItemValue = string | number | boolean | null | Item;

// 图形内容
export interface Item {
  block: string;
  args?: ItemValue[];

  next?: boolean; // 是否为下行属性
  shadow?: boolean; // 是否为阴影块

  isDisable?: boolean; // 是否禁用
  isSelected?: boolean; // 是否选中

  x?: number;
  y?: number;
}

export interface CompilerFunctionOption {
  multiplex?: boolean;
}

