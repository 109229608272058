import Style from './HorizontalSplitLayout.module.css';
import {useEffect, useRef, useState} from "react";

let downSize = -1;
let downX = -1;

export default function HorizontalSplitLayout(props) {
  const minLeftSize = props.minLeftSize || 150;
  const minMiddleSize = props.minMiddleSize || 200;
  const minRightSize = props.minRightSize || 230;
  const maxLeftSize = props.maxLeftSize || 1000;
  const maxRightSize = props.maxRightSize || 1200;

  const [leftSize, setLeftSize] = useState(props.defaulteftSize || 300);
  const [rightSize, setRightSize] = useState(props.defaultRightSize || 380);

  const body = useRef();
  const leftRef = useRef();
  const leftLineRef = useRef();
  const middleRef = useRef();
  const rightLineRef = useRef();
  const rightRef = useRef();

  const onLineMouseDown = (e, left) => {
    downSize = left ? leftSize : rightSize;
    downX = e.clientX;
    let minSize = left ? minLeftSize : minRightSize
    let maxSize = left ? maxLeftSize : maxRightSize

    const bw = body.current.clientWidth;
    const aw = left ? rightRef.current.clientWidth : leftRef.current.clientWidth;
    let space = bw - aw - minMiddleSize;
    if (space < maxSize) maxSize = space;

    const onMouseMove = (e) => {
      let size = downSize + (e.clientX - downX) * (left ? 1 : -1);
      if (size < minSize) {
        size = minSize;
      }
      if (size > maxSize) size = maxSize
      if (size < minSize) size = minSize
      left ? setLeftSize(size) : setRightSize(size);

      if(props.onChange) props.onChange(left, size);
    }
    const onMouseUp = (e) => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    }
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }

  if(props.children.length !== 3) {
    throw new Error('HorizontalSplitLayout must have three children');
  }

  return (
    <div className={Style.HorizontalSplitLayout} ref={body}>
      <div className={Style.left} style={{width: leftSize + 'px'}} ref={leftRef}>{props.children[0]}</div>
      <div className={Style.line +' '+ Style.leftLine} style={{left: leftSize + 'px'}} ref={leftLineRef} onMouseDown={e=>onLineMouseDown(e, true)}></div>
      <div className={Style.middle} style={{left: (leftSize+1) + 'px', right: (rightSize+1) + 'px'}} ref={middleRef}>{props.children[1]}</div>
      <div className={Style.line +' '+ Style.rightLine} style={{right: rightSize + 'px'}} ref={rightLineRef} onMouseDown={e=>onLineMouseDown(e, false)}></div>
      <div className={Style.right} style={{width: rightSize + 'px'}} ref={rightRef}>{props.children[2]}</div>
    </div>
  );

}





