import Style from './SplitLayout.module.css';
import {useEffect, useRef, useState} from "react";

let downSize = -1;
let downY = -1;

export default function SplitLayout(props) {
  const minSize = props.minSize || 100;
  const defaultTopSize = props.defaultTopSize || 500;

  const [topSize, setTopSize] = useState(defaultTopSize);

  const body = useRef();
  const topRef = useRef();
  const bottomRef = useRef();
  const lineRef = useRef();


  const onLineMouseDown = (e) => {
    downSize = topSize;
    downY = e.clientY;

    const onMouseMove = (e) => {
      let size = downSize + e.clientY - downY;
      if (size < minSize) {
        size = minSize;
      }
      const bh = body.current.clientHeight;
      if (size > bh - minSize) {
        size = bh - minSize;
      }
      setTopSize(size);

      if(props.onChange) {
        props.onChange(size, bh - size);
      }
    }
    const onMouseUp = (e) => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    }
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }

  if(props.children.length !== 2) {
    throw new Error('SplitLayout must have two children');
  }

  return (
    <div className={Style.SplitLayout} ref={body}>
      <div className={Style.top} style={{height: topSize + 'px'}} ref={topRef}>{props.children[0]}</div>
      <div className={Style.line} style={{top: topSize + 'px'}} ref={lineRef} onMouseDown={onLineMouseDown}></div>
      <div className={Style.bottom} style={{top: (topSize+1) + 'px'}} ref={bottomRef}>{props.children[1]}</div>
    </div>
  );
}





