const PortType = {
  INPUT: 0,
  OUTPUT: 1,
};

export const deviceDetail = {
  name: "空调",
  supportPorts: ["开关", "温度", "湿度", "风量", "音量"],

  ports: [
    {
      value: 0,
      type: PortType.INPUT,
      property: "状态",
      source: "开关",
      dataType: "二进制",
    },
    {
      value: 1,
      type: PortType.INPUT,
      property: "数值",
      source: "温度传感器",
      dataType: "浮点数",
    },
    {
      value: 3,
      type: PortType.INPUT,
      property: "档位",
      source: "风向传感器",
      dataType: "整数",
    },
    {
      value: 1,
      type: PortType.INPUT,
      property: "数值",
      source: "温度传感器",
      dataType: "浮点数",
    },
  ],
};
