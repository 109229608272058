import {RhineBlock} from "../../../../RhineBlock/core/RhineBlock";
import RX from "../RX";
import {updateData} from "../components/ports/PublicPort/NodeCallbacks";

export default class RxClipboard {

  // 执行复制图形块
  static actionCopyBlock = () => {
    const block = RhineBlock.selected
    const item = block.getItem()
    const pos = block.getPositionInGraph()
    if(pos.x !== 0 || pos.y !== 0) {
      item.x = pos.x
      item.y = pos.y
    }
    RhineBlock.setClipboard(item, block.getGraph())
  }

  // 执行复制节点
  static actionCopy = () => {
    const selected = RX.graph.getSelectedCells()
    if (selected.length > 0) {
      try {
        RX.graph.copy(selected, {
          useLocalStorage: false,
        })
      } catch (e) {
        console.warn(e)
      }
    }else if (RhineBlock.selected) {
      this.actionCopyBlock()
      RX.graph.cleanClipboard()
    }
  }

  // 执行粘贴
  static actionPaste = () => {
    const offset = 36
    const pastes = RX.graph.paste({
      offset: {dx: 0, dy: 80},
      useLocalStorage: false,
    })
    if(pastes.length>0){
      RX.graph.cleanSelection()
      RX.graph.select(pastes)
    }else{
      const clipboard = RhineBlock.getClipboard()
      clipboard.item.x += offset
      clipboard.item.y += offset
      if(clipboard){
        clipboard.graph.render([clipboard.item])
      }
    }
    // 重新生成节点及端口ID
    pastes.map(node => {
      if(!node.isEdge()) {
        const id = RX.createNewDeviceId()
        node.getPorts().map(port => {
          const ids = port.id.split('-')
          ids[0] = id
          const fid = ids.join('-')
          node.addPort({
            id: fid,
            group: port.group,
            text: port.text,
            attrs: {
              circle: {
                r: 10,
              },
            },
          });
          node.removePort(port.id)
        });
        RX.passDealOverlay = true
        RX.graph.updateCellId(node, 'N' + id)
        node.id = 'N' + id
        updateData(node)
      }
    })
  }

  // 执行剪切
  static actionCut = () => {
    const selected = RX.graph.getSelectedCells()
    if (selected.length > 0) {
      RX.graph.cut(selected)
    }else if (RhineBlock.selected) {
      this.actionCopyBlock()
      RX.graph.cleanClipboard()
      RhineBlock.selected.remove();
    }
  }

}


