import Style from "./DeviceDetail.module.css";
import React from "react";
import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { deviceDetail } from "../../../../data/devices/deviceDetail";

export default function DeviceDetail() {
  const data = deviceDetail;
  const [state, setState] = React.useState("0000");

  const handleChange = (event, i) => {
    let str = state + "";
    str = str.substring(0, i) + event.target.value + str.substring(i + 1);
    setState(str);
  };

  return (
    <div className={Style.detailBody}>
      <div className={Style.info}>
        <div className={Style.holder}>
          <span className={Style.keyText}>设备名</span>
          {/*<span className={Style.valueText}>{props.source.name}</span>*/}
          <span id={"currentDeviceName"} className={Style.valueText}>
            空调
          </span>
        </div>
        <div className={Style.holder}>
          <span className={Style.keyText}>可选接口</span>
          <span className={Style.valueText}>{data.supportPorts.join(",")}</span>
        </div>
      </div>
      {data.ports.map((port, deviceIndex) => {
        return (
          <div key={"L" + deviceIndex}>
            <div className={Style.spiltLine}><div/></div>
            <div className={Style.holder}>
              <span className={Style.keyText}>接口</span>
              <Select
                labelId="interface-select-label"
                id="interface-select"
                size="small"
                value={state[deviceIndex]}
                style={{height: 34, fontSize: 14, position: "absolute", left: 120, right: 30, marginTop: 20}}
                onChange={(e) => {
                  handleChange(e, deviceIndex);
                }}
              >
                {data.supportPorts.map((name, i) => {
                  return (
                    <MenuItem key={i} value={i} sx={{ fontSize: 14 }}>
                      {name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div className={Style.holder}>
              <span className={Style.keyText}>接口类型</span>
              <span className={Style.valueText}>
                {port.type === 0 ? "输入接口" : "输出接口"}
              </span>
            </div>
            <div className={Style.holder}>
              <span className={Style.keyText}>接口属性</span>
              <span className={Style.valueText}>
                {port.property === 0
                  ? "状态"
                  : port.property === 1
                  ? "数值"
                  : "档位"}
              </span>
            </div>
            <div className={Style.holder}>
              <span className={Style.keyText}>数据来源</span>
              <span className={Style.valueText}>{port.source}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
