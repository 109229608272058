import { Graph, Markup, Path } from "@antv/x6";
import DeviceNode from "./components/cells/DeviceNode/DeviceNode";
import LogicNode from "./components/cells/DeviceNode/LogicNode";

export function register() {
  const ports = {
    groups: {
      left: {
        position: "left",
        attrs: {
          circle: {
            r: 10,
            magnet: true,
            stroke: "#ffffff00",
            strokeWidth: 0,
            fill: "#ffffff00",
          },
        },
      },
      right: {
        position: "right",
        attrs: {
          circle: {
            r: 10,
            magnet: true,
            stroke: "#ffffff00",
            strokeWidth: 0,
            fill: "#ffffff00",
          },
        },
      },
    },
  };
  const portsFull = {
    groups: {
      left: {
        position: "left",
        attrs: {
          rect: {
            w: 34,
            h: 20,
            magnet: true,
            stroke: "#ffffff00",
            strokeWidth: 0,
            fill: "#ffffff00",
          },
        },
      },
      right: {
        position: "right",
        attrs: {
          rect: {
            w: 34,
            h: 20,
            magnet: true,
            stroke: "#ffffff00",
            strokeWidth: 0,
            fill: "#ffffff00",
          },
        },
      },
    },
  };
  Graph.registerNode(
    "device-node",
    {
      inherit: "react-shape",
      width: 208,
      height: 112,
      component: <DeviceNode />,
      ports: ports,
    },
    true
  );
  Graph.registerNode(
    "logic-node",
    {
      inherit: "react-shape",
      width: 576,
      height: 760,
      component: <LogicNode />,
      ports: ports,
    },
    true
  );

  Graph.registerEdge(
    "device-edge",
    {
      inherit: "edge",
      attrs: {
        line: {
          stroke: "#42A4FF",
          strokeWidth: 3,
          targetMarker: null,
        },
      },
      defaultLabel: {
        markup: Markup.getForeignObjectMarkup(),
        attrs: {
          fo: {
            width: 80,
            height: 0,
            x: -40,
            y: -18,
          },
        },
      },
      label: {
        position: 0.5,
      },
    },
    true
  );

  Graph.registerConnector(
    "algo-connector",
    (s, e, points, option, view) => {
      try {
        const cn = view.sourceMagnet.parentElement.className.baseVal;
        if (cn.includes("left")) {
          let t = e;
          e = s;
          s = t;
        }
        const deltaY = Math.abs(e.y - s.y);
        const r = 24;

        if (e.x - s.x < r * 2 || deltaY < r * 2) {
          const offset = 4;
          const deltaX = Math.abs(e.x - s.x);
          const control = Math.floor((deltaX / 3) * 2);

          const v1 = { y: s.y, x: s.x + offset + control };
          const v2 = { y: e.y, x: e.x - offset - control };

          return Path.normalize(
              `M ${s.x} ${s.y}
            L ${s.x + offset} ${s.y}
            C ${v1.x} ${v1.y} ${v2.x} ${v2.y} ${e.x - offset} ${e.y}
            L ${e.x} ${e.y}
          `
          );
        } else {
          const xm = (s.x + e.x) / 2;
          return Path.normalize(
              `M ${s.x} ${s.y}
            L ${xm - r} ${s.y}
            A ${r} ${r} 0 0 ${s.y < e.y ? 1 : 0} ${xm} ${
                  s.y < e.y ? s.y + r : s.y - r
              }
            L ${xm} ${s.y < e.y ? e.y - r : e.y + r}
            A ${r} ${r} 0 0 ${s.y < e.y ? 0 : 1} ${xm + r} ${e.y}
            L ${e.x} ${e.y}
          `
          );
        }
      } catch (e) {
        console.debug()
      }
    },
    true
  );
}
