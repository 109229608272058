import SvgElCreator, {setText} from "../../utils/svg-el-creator";
import {Graph} from "@antv/x6";
import {iotZoom, RhineBlock} from "../../RhineBlock";
import PathBuilder from "../../utils/path-builder";
import Arg from "../../block/arg.class";
import DragManager from "../../drag/drag-manager";
import Block from "../../block/block.class";
import RX from "../../../../App/Editor/RX/RX";
import RxHistory from "../../../../App/Editor/RX/history/RxHistory";

// 填充内容绘制提供器
export default class FieldProvider {
  FIELD_RADIUS = 4;
  FIELD_BACKGROUND = "#ffffffaa"; // 背景颜色
  FIELD_MIN_HEIGHT = 19;

  FIELD_PADDING_HORIZONTAL = 5;
  FIELD_PADDING_VERTICAL = 0;

  DROPDOWN_VIEW_MIN_WIDTH = 80;

  TRIANGLE_HORIZONTAL_BIAS = -1;
  TRIANGLE_WIDTH = 6;
  TRIANGLE_HEIGHT = 5;

  // 生产文本输入框
  makeTextInput(
    block: Block, // 图形块
    text: string, // 文本
    parent: SVGElement, // 父dom元素
    onChange: (text: string) => void, // 文本变化事件回调
    onChangeFinish: (text: string) => void, // 文本变化事件回调
    onlyNumber: boolean = false, // 是否仅支持数字
    isPassword: boolean = false, // 是否为密码模式
    zoom: number = 1, // 缩放倍数
  ): SVGElement {
    const el = SvgElCreator.newGroup({
      class: "rb-field rb-field-input",
    });
    parent.appendChild(el);
    const [rectEl, textEl] = this.makeTextRect(text, el, 0, zoom);
    rectEl.addEventListener("mousedown", (e) => {
      e.stopPropagation();
    });
    rectEl.addEventListener("mousemove", (e) => {
      e.stopPropagation();
    });
    rectEl.addEventListener("mouseup", (e) => {
      if (DragManager.dragBlock) return
      e.stopPropagation();
      if (block.isInToolbox()) return
      RX.cleanSelection()
      RhineBlock.onRemoveFocus()

      const graph: Graph = RX.graph!;
      if (graph) {
        graph.cleanSelection();
      }

      const input = document.createElement("input");
      input.className = "rb-field-input-input"
      getFieldHolderView().appendChild(input);
      textEl.style.opacity = "0";
      if (onlyNumber) input.type = "number";
      if (isPassword) input.type = "password";
      input.value = textEl.textContent || "";

      // 设置输入框到同等位置
      const tr = textEl.getBoundingClientRect();
      input.style.position = "absolute";
      input.style.left = `${tr.left}px`;
      input.style.top = `${tr.top}px`;
      input.style.width = `${tr.width}px`;
      input.style.height = `${tr.height}px`;
      input.style.fontSize = `${tr.height * 0.7}px`;
      input.select();
      RhineBlock.inputting = true
      // console.log(input.style.top, input.style.left, input.style.width, input.style.height);

      // 监听变化事件
      input.addEventListener("input", (e) => {
        // 处理禁止输入无效数字
        let v = input.value
        if (v.length == 1 && v == '.') v = '0.'
        if (v.length == 2 && v == '-.') v = '-0.'
        while (v.length > 1 && v[0] == '0' && v[1] != '.') v = v.slice(1)
        while (v.length > 2 && v[0] == '-' && v[1] == '0' && v[2] != '.') v = '-' + v.slice(2)
        input.value = v
        // 更新文本内容
        setText(textEl as SVGTextElement, v)
        const tr = textEl.getBoundingClientRect()
        zoom = RhineBlock.getZoom()
        const w = (tr.width + this.FIELD_PADDING_HORIZONTAL * 2) / zoom
        const h = (tr.height + this.FIELD_PADDING_VERTICAL * 2) / zoom
        rectEl.setAttribute("width", `${w}`)
        rectEl.setAttribute("height", `${h}`)
        input.style.width = `${tr.width}px`
        onChange(v)
      });

      const onFocusOut = (e?: Event) => {
        if (e && e.target === input) return;
        input.remove();
        textEl.style.opacity = "1";
        document.removeEventListener("mousedown", onFocusOut);
        RhineBlock.onRemoveFocus = () => {
        }
        onChangeFinish(input.value)
        RhineBlock.inputting = false
        setTimeout(() => {
          if (RhineBlock.waitEvent && DragManager.current == null) {
            const we = RhineBlock.waitEvent
            RhineBlock.waitEvent = null
            we.graph.onChangeEvent(we)
            RxHistory.startBatch('LogicNode FreshBoardData Extra')
          }
        }, 200)
      };
      RhineBlock.onRemoveFocus = onFocusOut
      input.addEventListener("focusout", onFocusOut)
      input.addEventListener("blur", onFocusOut)
      document.addEventListener("mousedown", onFocusOut)
      return false
    });
    return el
  }

  // 生产下拉菜单
  makeDropdown(
    block: Block, // 图形块
    options: string[], // 选项文本列表
    arg: Arg, // 参数
    parent: SVGElement, //父dom元素
    onChange: (text: number) => void, // 选中内容变化事件回调
    zoom: number = 1, // 缩放倍数
  ): SVGElement {
    const el = SvgElCreator.newGroup({
      class: "rb-field rb-field-dropdown",
    });
    parent.appendChild(el);
    const text = options[arg.content as number]
    const [rectEl, textEl] = this.makeTextRect(
      text,
      el,
      this.FIELD_PADDING_HORIZONTAL + this.TRIANGLE_WIDTH,
      zoom,
    )
    const tr = textEl.getBoundingClientRect()
    const path = new PathBuilder()
      .moveTo(
        tr.width / zoom +
        this.FIELD_PADDING_HORIZONTAL * 2 +
        this.TRIANGLE_HORIZONTAL_BIAS,
        (tr.height / zoom - this.TRIANGLE_HEIGHT) / 2,
        true
      )
      .horizontalTo(this.TRIANGLE_WIDTH)
      .lineTo(-this.TRIANGLE_WIDTH / 2, this.TRIANGLE_HEIGHT)
      .close()
      .build()
    const pathRl = SvgElCreator.newPath(path, block.color)
    el.appendChild(pathRl)

    rectEl.addEventListener("mousedown", (e) => {
      e.stopPropagation()
    })
    rectEl.addEventListener("mousemove", (e) => {
      e.stopPropagation()
    })
    rectEl.addEventListener("mouseup", (e) => {
      if (DragManager.dragBlock) return
      e.stopPropagation()
      if (block.isInToolbox()) return
      RhineBlock.onRemoveFocus()

      const holder = document.createElement("div")
      holder.className = "rb-field-dropdown-holder"
      getFieldHolderView().appendChild(holder)
      const rr = rectEl.getBoundingClientRect()
      holder.style.position = "absolute"
      holder.style.left = `${
        (rr.left + rr.right - this.DROPDOWN_VIEW_MIN_WIDTH) / 2
      }px`;
      holder.style.top = `${rr.bottom}px`;
      holder.style.minWidth = `${this.DROPDOWN_VIEW_MIN_WIDTH}px`;

      const allow: string[] = []
      // For LogicNode port show
      if (options.length == 100 && (options[99] == 'A100' || options[99] == 'B100')) {
        let node = null
        RX.graph!.getCells().forEach((cell) => {
          if (cell.isNode() && cell.shape == 'logic-node') {
            if (cell.getData() && cell.getData().graph === block.getGraph()) {
              node = cell
            }
          }
        });
        if (node) {
          const isLeft = options[0] === 'A1'
          // @ts-ignore
          node.getPorts().map(p => {
            if (p.group === (isLeft ? 'left' : 'right') && p.text !== '+') {
              allow.push(p.text)
            }
          })
          if (allow.length == 0) {
            allow.push(isLeft ? 'A1' : 'B1')
          }
        }
      }

      for (const i in options) {
        const opt = options[i];
        if (allow.length) {
          if (allow.indexOf(opt) == -1) continue
        }
        const optEl = document.createElement("div");
        optEl.className = "rb-field-dropdown-option";
        optEl.innerText = opt;
        holder.appendChild(optEl);

        optEl.addEventListener("click", (e) => {
          const v = parseInt(i);
          holder.style.opacity = "0";
          setTimeout(() => {
            holder.remove();
          }, 200);

          if (v !== arg.content) {
            setText(textEl as SVGTextElement, opt);
            onChange(v);
          }
          return false;
        });
      }

      holder.style.opacity = "1";
      holder.style.transform = "translate(0, 8px)";

      const onFocusOut = (e?: Event) => {
        if (e && e.target === holder) return;
        if (
          e &&
          e.target instanceof HTMLElement &&
          e.target.className.indexOf("rb-field-dropdown-option") > -1
        )
          return;
        holder.remove();
        document.removeEventListener("mousedown", onFocusOut);
        RhineBlock.onRemoveFocus = () => {
        }
        setTimeout(() => {
          if (RhineBlock.waitEvent && DragManager.current == null) {
            const we = RhineBlock.waitEvent
            RhineBlock.waitEvent = null
            we.graph.onChangeEvent(we)
            RxHistory.startBatch('LogicNode FreshBoardData Extra')
          }
        }, 200)
      };
      RhineBlock.onRemoveFocus = onFocusOut;
      document.addEventListener("mousedown", onFocusOut);
      return false;
    });
    return el;
  }

  makeTextRect(text: string, el: SVGGElement, mr: number = 0, zoom: number = 1): SVGElement[] {
    const textEl = SvgElCreator.newText(
      text,
      null,
      this.FIELD_PADDING_HORIZONTAL,
      this.FIELD_PADDING_VERTICAL,
    );
    textEl.setAttribute("height", this.FIELD_MIN_HEIGHT + "px");
    el.style.padding = `${this.FIELD_PADDING_VERTICAL}px ${this.FIELD_PADDING_HORIZONTAL}px`;
    el.appendChild(textEl);
    const rect = textEl.getBoundingClientRect();
    const rectEl = SvgElCreator.newRect(
      0,
      0,
      rect.width / zoom + this.FIELD_PADDING_HORIZONTAL * 2 + mr,
      this.FIELD_MIN_HEIGHT,
      this.FIELD_BACKGROUND,
      this.FIELD_RADIUS
    );
    el.insertBefore(rectEl, textEl);
    return [rectEl, textEl];
  }
}

export function getFieldHolderView(): HTMLDivElement {
  const el = document.getElementsByClassName("rb-field-holder");
  if (el.length > 0) return el[0] as HTMLDivElement;
  const holder = document.createElement("div");
  holder.className = "rb-field-holder";
  document.body.appendChild(holder);
  return holder;
}
