import Style from "./Start.module.css";
import React, {useEffect, useRef, useState} from "react";
import EnterExist from "./EnterExist/EnterExist";
import EnterNew from "./EnterNew/EnterNew";
import {Link, useNavigate} from "react-router-dom";
import Api from "../Api";
import {tip} from "../App";
import Once from "../store/once";
import {Autocomplete, Button, Popover, TextField, ThemeProvider} from "@mui/material";
import darkTheme from "../DarkTheme";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalTitle from "../../common/ModalTitle/ModalTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {BootstrapDialog, BootstrapDialogTitle, MsgDialog} from "./dialog";
import {Popconfirm} from "antd";
import Cookies from "js-cookie";

const USE_INNER_LIST = false

function Start() {
  document.oncontextmenu = function (e) {
    return false;
  };

  const navigate = useNavigate()
  const openProject = (id) => {
    navigate(`/editor?pid=${id}&timestamp=${new Date().getTime()}`)
  }

  const onCreate = (type = 0) => {
    Api.create().then(r => {
      let pid = r["result"]
      let name = "新项目"
      setProjects([...projects, {
        id: pid,
        type: type,
        name: name,
        description: "",
        thumbnail: "",
        updateTime: new Date().getTime(),
      }])
      openProject(pid)
    }).catch(e => {
      console.log(e)
      tip("创建项目失败", "error")
    })
  }

  const link = (name) => {
    // RX.initProgress = 0
    return `/editor?eid=${new Date().getTime()}&name=${name}`
  }

  const [projects, setProjects] = useState([])

  useEffect(() => {
    let un = Cookies.get('user.name')
    if (un && un.length > 0) {
      usernameRef.current.innerText = un
    }

    if (Once.check("list")) return;
    if (Api.isDevelopmentEnv() && USE_INNER_LIST) {
      setProjects([{
        "id": 1,
        "name": "海尔空调APP",
        "thumbnail": "http://contentcms-bj.cdn.bcebos.com/cmspic/134788a067ec912251013c761bebf6c8.jpeg?x-bce-process=image/crop,x_0,y_0,w_1654,h_900",
        "status": 0,
        "content": null,
        "createTime": 1688370041000,
        "updateTime": 1688370045000,
        "type": 0,
        "description": "",
      }, {
        "id": 2,
        "name": "智慧教室",
        "thumbnail": null,
        "status": 0,
        "content": null,
        "createTime": 1688371342000,
        "updateTime": 1688371342000,
        "type": 2,
        "description": "",
      }])
    } else {
      Api.list().then(r => {
        setProjects(r["result"])
      }).catch(e => {
        console.error(e)
        tip("获取项目列表失败", "error")
      })
    }
  }, [])

  const [name, setName] = useState("")
  const tl = ["智能家居", "智慧农业", "智慧校园"]
  const [type, setType] = useState(0)
  const [description, setDescription] = useState("")
  const [thumbnail, setThumbnail] = useState("")
  const [id, setId] = useState(-1)
  const imgRef = useRef()
  const usernameRef = useRef()

  const targetProject = () => projects.find(p => p.id === id)

  let getPosition = () => {
    return window.position;
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleContextMenu = (e, i) => {
    let project = projects[i]
    setId(project.id)
    setType(project.type)
    setName(project.name)
    setDescription(project.description)
    setThumbnail(project.thumbnail)
    setAnchorEl(e.currentTarget);
    window.position = { top: e.clientY, left: e.clientX };
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const handleDeleteOpen = (e) => {
    setDeleteModalOpen(true);
    handleClose();
    e.stopPropagation();
  };
  const handleDeleteClose = (e, flag = false) => {
    setDeleteModalOpen(false);
    if (flag === true) {
      Api.delete(id).then(r => {
        tip("删除成功", "success")
        setProjects(projects.filter(p => p.id !== id))
      }).catch(e => {
        tip("删除失败", "error")
      })
    }
    e.stopPropagation();
  };
  const [releaseModalOpen, setReleaseModalOpen] = React.useState(false);
  const handleReleaseOpen = (e) => {
    setReleaseModalOpen(true);
    handleClose();
    e.stopPropagation();
  };
  const handleReleaseClose = (e, flag = false) => {
    setReleaseModalOpen(false);
    if (flag === true) {
      let project = targetProject()
      project.status = project.status === 0 ? 1 : 0
      Api.upload(id, project).then(r => {
        tip(project.status === 1 ? "发布成功" : "撤回成功", "success");
        setProjects(JSON.parse(JSON.stringify(projects)))
      }).catch(e => {
        console.error(e)
        tip("发布失败" + e, "error")
      })
    }
    e.stopPropagation();
  };
  const [detailModalOpen, setDetailModalOpen] = React.useState(false);
  const handleDetailOpen = (e) => {
    setDetailModalOpen(true);
    handleClose();
    e.stopPropagation();
  };
  const handleDetailClose = (e, flag = false, i) => {
    setDetailModalOpen(false);
    if (flag === true) {
      let updateTime = new Date().getTime()
      let project = projects.find(p => p.id === id)
      project.name = name
      project.thumbnail = thumbnail
      project.type = type
      project.description = description
      project.date = updateTime
      Api.upload(id, project).then(r => {
        tip("更新信息成功", "success");
        setProjects(JSON.parse(JSON.stringify(projects)))
      }).catch(e => {
        console.error(e)
        tip("上传失败" + e, "error")
      })
    }
    e.stopPropagation();
  };

  const selectFile = () => {
    const inputObj = document.createElement("input");
    inputObj.setAttribute("id", "_ef");
    inputObj.setAttribute("type", "file");
    inputObj.setAttribute("accept", "image/gif,image/png,image/jpeg");
    inputObj.setAttribute("style", "visibility:hidden");
    document.body.appendChild(inputObj);
    inputObj.addEventListener('change', function(event) {
      let file = event.target.files[0];
      let reader = new FileReader();
      tip("正在上传...")
      // 读取文件内容
      reader.onload = function(e) {
        Api.cover(file).then(r => {
          console.log(r)
          console.log(e)
          setThumbnail(r["result"])
          imgRef.current.src = e.target.result;
        }).catch(e => {
          tip("上传失败 " + e, "error")
        })
      };
      reader.readAsDataURL(file);
    });
    inputObj.click();
  };
  const confirmLogout = (e) => {
    Api.removeLogin()
  }

  return (
    <div className={Style.start}>
      <div className={Style.header}>
        <div className={Style.title}>中科物栖物联网应用平台</div>
        <Popconfirm
            title="退出登录？"
            description="登录后才可使用，且下次登录需要重新输入密码。"
            onConfirm={confirmLogout}
            okText="OK"
            cancelText="Cancel"
            placement="bottomRight"
        >
          <div className={Style.info}>
            <img
                className={Style.profile}
                src={"./common/profile.svg"}
                alt={""}
            />
            <div ref={usernameRef} className={Style.user}>USER</div>
          </div>
        </Popconfirm>
      </div>
      <div className={Style.project}>
        <div className={Style.createProject}>
          <div className={Style.createProjectTitle}>
            <div className={Style.blueBar1} />
            <div className={Style.createProjectText}>创建项目</div>
          </div>
          <div className={Style.clsProject}>
            <EnterNew img={"./app/start/1-1.svg"} onClick={e => onCreate(-1)} text={"新项目"} new />
            {
              tl.map((t, i) => {
                return <EnterNew img={`./app/start/1-${i + 2}.png`} onClick={e => onCreate(i)} text={t} key={i} />
              })
            }
          </div>
        </div>
        <div className={Style.myProject}>
          <div className={Style.myProjectTitle}>
            <div className={Style.blueBar2} />
            <div className={Style.myProjectText}>我的项目</div>
          </div>
          <div className={Style.historyProject}>
            {
              projects.map((project, i) => {
                return (
                  <EnterExist
                    key={i}
                    project={project}
                    create={0}
                    onClick={e => {
                      openProject(project.id)
                    }}
                    handleClick={e => {
                      handleContextMenu(e, i)
                    }}
                  />
                )
              })
            }
          </div>
        </div>
      </div>
      <Popover
        id={open ? "popover" : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={(e) => {
          e.stopPropagation();
        }}
        anchorReference="anchorPosition"
        anchorPosition={getPosition()}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <ThemeProvider theme={darkTheme}>
          <Paper sx={{ width: 136 }} className={Style.OptionPaper}>
            <MenuList dense>
              <MenuItem onClick={handleDetailOpen}>
                <ListItemIcon>
                  <AssignmentIcon fontSize={"small"} />
                </ListItemIcon>
                项目详情
              </MenuItem>
              {/*<div className={Style.divider}/>*/}
              {/*<Divider />*/}
              <MenuItem onClick={handleReleaseOpen}>
                <ListItemIcon>
                  <SendIcon fontSize={"small"} />
                </ListItemIcon>
                {(targetProject() && targetProject().status === 0) ? '发布项目' : '撤回发布'}
              </MenuItem>
              {/*<div className={Style.divider}/>*/}
              {/*<Divider />*/}
              <MenuItem onClick={handleDeleteOpen}>
                <ListItemIcon>
                  <DeleteIcon fontSize={"small"} />
                </ListItemIcon>
                删除项目
              </MenuItem>
            </MenuList>
          </Paper>
        </ThemeProvider>
      </Popover>
      <BootstrapDialog
        onClose={handleDeleteClose}
        aria-labelledby="customized-dialog-title"
        open={deleteModalOpen}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ModalTitle title={"删除"} onClose={handleDeleteClose} />
        <DialogContent dividers>
          <div className={Style.pLine}>
            <img src="./app/start/attention.svg" alt={""} />
            <p>确定要删除”{name}“项目吗？</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="outlined"
            color="gray"
            size="small"
            onClick={handleDeleteClose}
          >
            取消
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 1 }}
            onClick={(e) => handleDeleteClose(e, true)}
          >
            确定
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <BootstrapDialog
        onClose={handleReleaseClose}
        aria-labelledby="customized-dialog-title"
        open={releaseModalOpen}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ModalTitle title={"发布"} onClose={handleReleaseClose} />
        <DialogContent dividers>
          <div className={Style.pLine}>
            <img src="./app/start/allow.svg" alt={""} />
            <p>确定发布 ”{name}“ 项目吗？</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="outlined"
            color="gray"
            size="small"
            onClick={handleReleaseClose}
          >
            取消
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 1 }}
            onClick={(e) => handleReleaseClose(e, true)}
          >
            确定
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <MsgDialog
        onClose={handleDetailClose}
        aria-labelledby="customized-dialog-title"
        open={detailModalOpen}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleDetailClose}
        >
          项目详情
        </BootstrapDialogTitle>
        <DialogContent dividers sx={{ width: 530 }}>
          <div className={Style.dialogLine}>
            <span className={Style.dialogLineName}>项目名称</span>
            <TextField
              id="outlined"
              sx={{ position: "absolute", left: 84, right: 0 }}
              defaultValue={name}
              onChange={e => setName(e.target.value)}
              size={"small"}
            />
          </div>
          <div className={Style.dialogLine}>
            <span className={Style.dialogLineName}>场景类型</span>
            <Autocomplete
              sx={{
                position: "absolute",
                left: 84,
                right: 0,
                display: "inline-block",
              }}
              disablePortal
              id="combo-box-demo"
              options={tl}
              defaultValue={tl[type]}
              onChange={e => {
                let i = tl.indexOf(e.target.textContent)
                if (i !== -1) setType(i)
              }}
              renderInput={(params) => <TextField {...params} size={"small"} />}
            />
          </div>
          <div className={Style.dialogLine} style={{ height: 86 }}>
            <span className={Style.dialogLineName}>项目说明</span>
            <TextField
              id="outlined"
              sx={{ position: "absolute", left: 84, right: 0 }}
              size={"small"}
              multiline
              maxRows={3}
              minRows={3}
              defaultValue={description ? description : ""}
              onChange={e => setDescription(e.target.value)}
            />
          </div>
          <div className={Style.dialogLine} style={{ height: 100 }}>
            <span className={Style.dialogLineName}>项目图标</span>
            <div
              className={Style.dialogImg}
              onClick={selectFile}
            >
              <img className={Style.currentImg} ref={imgRef} src={
                thumbnail && thumbnail.length > 0 ? thumbnail : "./app/start/frame1.png"
              } alt={""} />
              <div />
              <span className={Style.imgBtnHolder}>
                <img
                  src={"./app/start/upload.svg"}
                  alt={""}
                  width ="28px"
                  height="28px"
                  className={Style.upload}
                />
                {/*<img src={'./app/start/delete.svg'} alt={''} className={Style.delete}/>*/}
              </span>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 1 }}
            onClick={(e) => handleDetailClose(e, true)}
          >
            确定
          </Button>
        </DialogActions>
      </MsgDialog>
    </div>
  );
}

export default Start;
