import Style from "./ToolBtn.module.css";
import { useEffect, useState } from "react";
import state from "../../App/store/state";
import RX from "../../App/Editor/RX/RX";

function ToolBtn(props) {
  const [style, setStyle] = useState({});

  //TODO: 添加状态管理后重写这部分代码位置
  const handleClick = (e) => {
    if (props.onClick) {
      props.onClick(props);
    }
    if (props.text === "选择") {
      state.setChooseSelected(true);
      state.setDragSelected(false);
    } else if (props.text === "拖拽") {
      state.setChooseSelected(false);
      state.setDragSelected(true);
    } else {
      setStyle({});
    }
  };
  useEffect(() => {});
  if (props.text === "选择") {
    state.setChooseSelected = (flag) => {
      if (flag) {
        setStyle({
          background: "#42A4FF",
        });
        if(RX.graph){
          RX.graph.disablePanning();
          RX.graph.setRubberbandModifiers(null);
        }
      } else {
        setStyle({});
        if(RX.graph){
          RX.graph.enablePanning();
          RX.graph.setRubberbandModifiers('shift');
        }
      }
    };
  } else if (props.text === "拖拽") {
    state.setDragSelected = (flag) => {
      if (flag) {
        setStyle({
          background: "#42A4FF",
        });
      } else {
        setStyle({});
      }
    };
  }
  return (
    <div className={Style.ToolBtn} onClick={handleClick} style={style}>
      <img className={props.text === '重做' ? Style.iconFlip : Style.icon} src={props.icon} alt={""} />
      <div className={Style.text}>{props.text}</div>
    </div>
  );
}

export default ToolBtn;
