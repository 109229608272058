const PORTS_NUM = 100

export function ALL_PORTS(reverse: boolean = false): string[] {
  const arr = []
  for (let i = 1; i <= PORTS_NUM; i++) {
    arr.push(reverse ? 'B' : 'A' + i)
  }
  for (let i = 1; i <= PORTS_NUM; i++) {
    arr.push(reverse ? 'A' : 'B' + i)
  }
  return arr
}

export function IN_PORTS(): string[] {
  const arr = []
  for (let i = 1; i <= PORTS_NUM; i++) {
    arr.push('A' + i)
  }
  return arr
  // return ['A0', 'RX', 'IN']
}

export function OUT_PORTS(): string[] {
  const arr = []
  for (let i = 1; i <= PORTS_NUM; i++) {
    arr.push('B' + i)
  }
  return arr
  // return ['B0', 'RX', 'OUT']
}

export function PORT_TYPES(): string[] {
  return ['数值', '文本', '时间', '日期', '字典']
}

