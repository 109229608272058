
const portHoverDataOut = [
  ["接口名", "开关"],
  ["所属设备", "空调"],
  ["接口类型", "设备输出接口"],
  ["接口输出属性", "状态"],
  ["输出到接口", "开关"],
];
const portHoverDataIn = [
  ["接口名", "开关"],
  ["所属设备", "空调"],
  ["接口类型", "设备输出接口"],
  ["接受数据类型", "状态"],
  ["数据来源接口", "开关"],
];
export const getDefaultPortData = (isIn) => {
  if (isIn) {
    return portHoverDataIn;
  } else {
    return portHoverDataOut;
  }
}
