import Block, { Item } from "../../block/block.class";
import {iotZoom, RhineBlock} from "../../RhineBlock";

import "../../../blocks/event.block";
import "../../../blocks/equipment.block";
import "../../../blocks/loop.block";
import "../../../blocks/logic.block";
import "../../../blocks/number.block";
import "../../../blocks/text.block";
import "../../../blocks/console.block";
import "../../../blocks/temp.block";
import "../../../blocks/service.block";

import { Graph } from "../graph/graph";

// 渲染工具箱
export default function renderFlyout(dom: HTMLElement, blocks: string[][]) {
  if (!dom) {
    console.error("Flyout dom is null", dom);
    return null;
  }
  dom.innerHTML = "";

  const MARGIN_HORIZONTAL = 20;
  const MARGIN_VERTICAL = 20;
  const SPACING = 32;
  const COLUMN_WIDTH = 200;

  const graph = new Graph(dom);
  graph.isToolbox = true;
  RhineBlock.registerGraph(graph);

  let maxY = 0;
  const zoom = RhineBlock.getZoom()
  for (let i = 0; i < blocks.length; i++) {
    let y = MARGIN_VERTICAL;
    for (let j = 0; j < blocks[i].length; j++) {
      const item: Item = {
        block: blocks[i][j],
        x: MARGIN_HORIZONTAL + i * COLUMN_WIDTH,
        y: y,
      };

      const data = RhineBlock.getBlockData(item.block);
      if (!data) {
        console.error("Block is not register", item.block);
        continue;
      }
      if (typeof data.toolbox === "boolean") {
        if (!data.toolbox) {
          continue;
        } else {
          item.args = [];
        }
      } else {
        item.args = data.toolbox;
      }

      const n = graph.render([item], undefined, false);
      y += graph.blocks[n - 1].height * zoom + SPACING * zoom;
    }
    if (y > maxY) {
      maxY = y;
    }
  }

  graph.svg.style.height = maxY + MARGIN_VERTICAL * 2 + "px";
}
