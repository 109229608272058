import {startBatch, stopBatch} from "../components/ports/PublicPort/NodeCallbacks";
import RX from "../RX";
import {RhineBlock} from "../../../../RhineBlock/core/RhineBlock";
import RxHistory from "../history/RxHistory";

export default class RxSelection {
  // 获取选中的设备节点
  static getSelectedCells() {
    return RX.graph.getSelectedCells()
  }

  // 删除选中的内容
  static deleteSelection() {
    if (RhineBlock.selected) {
      RhineBlock.selected.remove();
    }else if (RX.graph) {
      RxHistory.startBatch('RxSelection DeleteAllSelected')
      for (const cell of this.getSelectedCells()) {
        if(cell.isEdge()) {
          cell.attr(["data", "disable"], false)
          RX.onChangeEdgeStatus(cell, false);
        } else {
          RX.graph.getConnectedEdges(cell).forEach((edge) => {
            edge.attr(["data", "disable"], false)
            RX.onChangeEdgeStatus(edge, false);
          })
        }
        RX.removeCell(cell);
      }
      RxHistory.stopBatch('RxSelection DeleteAllSelected')
    }
  }

}
