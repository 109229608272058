import {BlockType, IBlock} from "../core/block/block.class";
import {RhineBlock} from "../core/RhineBlock";
import {FieldType} from "../core/block/arg.class";
import {JsOrderComplete} from "../core/compiler/js/js-compiler";

const Order = JsOrderComplete;

const numberBlocks: IBlock[] = [
  {
    name: "number_value",
    type: BlockType.Output,
    output: "Number",
    color: "#71dccc",
    lines: [[{text: "数值"}, {field: FieldType.Number, default: "100"}]],
    compiler: "$0",
    level: Order.DIRECT,
  },
  {
    name: "number_basiccal",
    type: BlockType.Output,
    output: "Number",
    color: "#84c974",
    lines: [
      [
        {value: "num"},
        {field: FieldType.Dropdown, option: ["+", "-", "×", "÷"], default: 0, optionCode: ["+", "-", "*", "/"]},
        {value: "num"},
      ],
    ],
    toolbox: [],
    compiler: "$0 $1 $2",
    level: Order.UNARY_PLUS,
  },
  {
    name: "number_compare",
    type: BlockType.Output,
    output: "Boolean",
    color: "#84c974",
    lines: [
      [
        {text: " "},
        {value: "num"},
        {
          field: FieldType.Dropdown,
          option: [">", "<", "=", ">=", "<=", "≠"],
          default: 0,
          optionCode: [">", "<", "==", ">=", "<=", "!="]
        },
        {value: "num"},
        {text: "  "},
      ],
    ],
    toolbox: [],
    compiler: "$0 $1 $2",
    level: Order.RELATIONAL,
  },
  {
    name: "number_seniorcal",
    type: BlockType.Output,
    output: "Number",
    color: "#84c974",
    lines: [
      [
        {
          field: FieldType.Dropdown,
          option: ["绝对值", "平方根", "ln", "log10"],
          default: 0,
          optionCode: ["Math.abs", "Math.sqrt", "Math.log", "Math.log10"],
        },
        {value: "num"},
      ],
    ],
    toolbox: [],
    compiler: "$0($1)",
    level: Order.FUNCTION_CALL
  },
  {
    name: "number_remainder",
    type: BlockType.Output,
    output: "Number",
    color: "#84c974",
    lines: [
      [
        {value: "num"},
        {text: "除以"},
        {value: "num"},
        {text: "的余数"},
      ],
    ],
    toolbox: [],
    compiler: "$0 % $1",
    level: Order.MODULUS
  },
  {
    name: "number_round",
    type: BlockType.Output,
    output: "Number",
    color: "#84c974",
    lines: [
      [
        {text: "四舍五入"},
        {value: "num"},
      ],
    ],
    toolbox: [],
    compiler: "Math.round($0)",
    level: Order.FUNCTION_CALL
  },
  {
    name: "number_trifunc",
    type: BlockType.Output,
    color: "#84c974",
    lines: [
      [
        {
          field: FieldType.Dropdown,
          option: ["sin", "cos", "tan", "cot"],
          default: 0,
          optionCode: ["Math.sin", "Math.cos", "Math.tan", "Math.cot"],
        },
        {value: "num"},
      ],
    ],
    toolbox: [],
    compiler: "$0($1)",
    level: Order.FUNCTION_CALL
  },
  {
    name: "number_constant",
    type: BlockType.Output,
    color: "#71dccc",
    lines: [[{field: FieldType.Dropdown, option: ["π", "e"], default: 0, optionCode: ["Math.PI", "Math.E"]}]],
    compiler: "$0",
    level: Order.DIRECT
  },
  {
    name: "number_randomfloat",
    type: BlockType.Output,
    color: "#71dccc",
    lines: [[{text: "随机小数"}]],
    compiler: "Math.random()",
    level: Order.FUNCTION_CALL
  },
];

RhineBlock.registerBlocksData(numberBlocks);

export default numberBlocks;
