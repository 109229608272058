import RX from "../RX";
import {updateData} from "../components/ports/PublicPort/NodeCallbacks";

export default class RxHistory {

  static USE_LOG = true

  static debug = (...args) => this.USE_LOG && console.debug(...args)
  static log = (...args) => this.USE_LOG && console.log(...args)
  static warn = (...args) => this.USE_LOG && console.warn(...args)
  static info = (...args) => this.USE_LOG && console.info(...args)
  static error = (...args) => this.USE_LOG && console.error(...args)

  // 初始化历史记录模块
  static init(){
    RX.graph.history.on('change', (data) => {
      // console.debug('onGraphHistoryChanged', data)
      if (data.options) return false
    });
    const onHistoryChange = (e, isUndo) => {
      // this.log(isUndo ? 'onUndo' : 'onRedo', e)
      for (const cmd of e.cmds) {
        if (cmd.event === "cell:change:attrs"){
          const cell = RX.graph.getCellById(cmd.data.id)
          if(cell && cell.isEdge()){
            // this.log(cell, cell.attr(['data', 'disable']))
            RX.onChangeEdgeStatus(cell)
          }
        }
      }
      // if (e.cmds[0].event !== 'cell:change:data' || e.cmds[0].data.next.data.blocks === undefined) {
      //   freshPorts(e)
      // }
      freshPorts(e)
    }
    const freshPorts = (e) => {
      const nodes = RX.graph.getNodes();
      for (let i = 0; i < nodes.length; i++) {
        updateData(nodes[i], {ignore: true});
      }
    }
    RX.graph.history.on('undo', e=>onHistoryChange(e, true))
    RX.graph.history.on('redo', e=>onHistoryChange(e, false))
  }

  // 执行重做
  static redo(){
    RX.graph.redo()
  }

  //
  static undo(){
    RX.graph.undo()
  }

  // 是否处于组合操作状态中
  static inBatch = false;

  static targetBatch = null

  // 开始组合操作
  static startBatch(name = 'unknown'){
    if(!this.inBatch){
      this.info(`=== StartBatch: ${name} ===`)
      this.inBatch = true
      RX.graph.startBatch("port-event");
      RxHistory.targetBatch = name
    } else {
      this.info(`======== Pass StartBatch: ${name} ===`)
      if (RxHistory.targetBatch === 'NodeCallback MakeFlower' && name === 'RX StartGesture') {
        RxHistory.targetBatch = name
      }
    }
  }

  // 停止组合操作
  static stopBatch(name = 'unknown'){
    if (name === 'NodeCallback CreatePort' && RxHistory.targetBatch === 'NodeCallback MakeFlower') {
      this.debug(`======== Break1 StopBatch: ${name} ===`)
      return
    }
    if (name === 'NodeCallback MakeFlower' && RxHistory.targetBatch === 'RX StartGesture') {
      this.debug(`======== Break2 StopBatch: ${name} ===`)
      return
    }
    if(this.inBatch){
      this.debug(`=== StopBatch: ${name} ===`)
      this.inBatch = false
      RX.graph.stopBatch("port-event");
    } else {
      this.debug(`======== Pass StopBatch: ${name} ===`)
    }
    RxHistory.targetBatch = null
  }

}
