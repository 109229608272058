import Style from "./Login.module.css";
import TextField from "@mui/material/TextField";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React, {useEffect} from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {useNavigate} from "react-router-dom";
import Api from "../Api";
import {tip} from "../App";
import Once from "../store/once";

function Login() {
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate()
  const onLogin = () => {
    if (values.amount.length === 0) {
      tip("请输入手机号", "warning")
      return
    }
    if (values.password.length === 0) {
      tip("请输入密码", "warning")
      return
    }
    Api.login(values.amount, values.password).then(r => {
      console.log(r)
      navigate('/start')
    }).catch(e => {
      console.log(e)
      let text = "登录失败"
      if (e["message"]) text += " " + e["message"]
      tip(text, "error")
    })
  }

  useEffect(() => {
    // if (Once.check("checkLogin")) return
    // if (Api.checkLogin()) {
    //   navigate('/start')
    // }
  }, [])

  return (
    <div className={Style.Login}>
      <div className={Style.bg}>
        <img
          className={Style.bgImage}
          src={"./app/login/background.svg"}
          alt={""}
        />
      </div>
      <div className={Style.holder}>
        <div className={Style.form}>
          <span className={Style.title}>
            欢迎登录
            <br />
            中科物栖物联网应用平台！
          </span>
          <span
            className={Style.secTitle}
            style={{ marginTop: "60px", marginBottom: "40px" }}
          >
            账号登录
          </span>
          <div>
            <div className={Style.account}>
              <TextField
                className={Style.accountField}
                label="账号"
                fullWidth={true}
                id="account"
                size="small"
                onChange={handleChange("amount")}
              />
            </div>
            <div className={Style.password}>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel htmlFor="passwordField" size="small">
                  密码
                </InputLabel>
                <OutlinedInput
                  fullWidth={true}
                  id="passwordField"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="密码"
                  size="small"
                />
              </FormControl>
            </div>
            <Button
              className={Style.loginBtn}
              color="primary"
              size={"large"}
              variant="contained"
              fullWidth={true}
              style={{ marginTop: "50px", marginBottom: "15px" }}
              onClick={onLogin}
            >
              登录
            </Button>
            <Button
              className={Style.registerBtn}
              color="primary"
              size="small"
              variant="text"
              style={{ display: 'none' }}
            >
              立即注册
            </Button>
            <Button
              className={Style.forgetBtn}
              color="neutral"
              size="small"
              variant="text"
              style={{ position: "absolute", right: 0, display: 'none' }}
            >
              忘记密码?
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
