import React from "react";
import {getDeviceById} from "../../../../data/devices/devices";
import {makeFlower} from "../MakeFlower/MakeFlower";
import RX from "../../../RX";
import RxHistory from "../../../history/RxHistory";
import MuiAlert from "../../../../utils/MuiAlert";
import DealOverlay from "../../../tidy/DealOverlay";
import Direction from "../../../tidy/Direction";
/**
 * 获取背景色
 * @param node x6 node对象
 * @param port x6 port对象
 * @param isLeft 是否是左边组
 * @param status status 状态（新增）
 * return 背景色
 * */
export const getBackground = (node, port, isLeft, status) => {
  const gray = "#cccccc";
  let cin = node.data.connecting; // cell is dragging
  let pms = port.id.split("-"); // port model split
  let wdr = window.dragRight; // window drag right
  if (cin) {
    for (const edge of node.model.graph.getEdges()) {
      const tp = edge.getTarget().port;
      if (tp === port.id) return gray;
      const sp = edge.getSource().port;
      if (sp === port.id) return gray;
    }
    // console.log(node.id, port.id, cin)
    if (port.id.split("-")[0] === cin.split("-")[0]) return gray;
    if (wdr === isLeft) {
      if (pms[0] === cin.split("-")[0]) return gray;
      // 检查设备是否有端口
      if (port.text === '+') {
        const device = node.store.data.data.device
        const dd = getDeviceById(device)
        if (dd) {
          const supportPorts = isLeft ? dd.inputText : dd.outputText
          if (supportPorts.length === 0) return gray
        }
      }
    } else {
      if (port.id !== cin) return gray;
    }
    // if (!wdr && port.text === '+') return gray
  }
  // console.log('returnStatus', status)
  if (status){
    return isLeft ? "#529BF1" : "#B375E0";
  }else {
    return isLeft ? "#abccff" : "#d6b8ff";
  }
};

export const getAllow = (node, port, isLeft) => {
  let cin = node.data.connecting;
  let pms = port.id.split("-");
  let wdr = window.dragRight;
  if (cin) {
    for (const edge of node.model.graph.getEdges()) {
      const tp = edge.getTarget().port;
      if (tp === port.id) return false;
    }
    if (wdr && !isLeft) {
      if (port.id !== cin) return false;
    } else {
      if (pms[0] === cin.split("-")[0]) return false;
    }
    if (!wdr && isLeft) {
      if (port.id !== cin) return false;
    } else {
      if (pms[0] === cin.split("-")[0]) return false;
    }
    if (!wdr && port.text === "+") return false;
  }
  return true;
};

export const createPort = (node, fromPort, text = '') => {
  // 计算基本信息
  let isLeft = fromPort.group === 'left'
  const shape = node.store.data.shape;
  const isLogic = shape === 'logic-node';

  // 计算新端口关键信息
  let index = 0;
  let ln = 0, rn = 0; // 计算端口id
  let lnu = [0], rnu = [0]; // 记录没有用过的端口序号
  node.port.ports.map((tp, i) => {
    if (tp.group === fromPort.group && tp.text === '+') {
      index = i;
    }
    tp.group === 'left' ? ln++ : rn++;
    if (tp.text !== "+") {
      let n = tp.id.split("-")[2];
      tp.group === 'left' ? lnu[n - 1]++ : rnu[n - 1]++;
    }
    lnu.push(0)
    rnu.push(0)
  });
  let tn = 1 + (isLeft ? ln : rn); // 添加端口后 添加的那侧的 端口数量
  let ci = 1 + (isLeft ? lnu.indexOf(0) : rnu.indexOf(0))
  let pms = fromPort.id.split("-");
  const id = pms[0] + "-" + pms[1] + "-" + ci;

  RxHistory.startBatch('NodeCallback CreatePort')
  if (isLogic) {
    // 处理逻辑板扩大尺寸
    const size = node.size()
    if (size.height < tn * 40) {
      node.resize(size.width, tn * 40)
      DealOverlay.dealFromOne(node, Direction.bottom)
    }
    text = isLeft ? "A" + ci : "B" + ci
  } else {
    // 处理节点尺寸变化
    if (tn >= 5) {
      node.resize(208, 112 + (tn - 4) * 32)
      DealOverlay.dealFromOne(node, Direction.bottom)
    }
  }

  // 执行添加端口
  node.insertPort(index, {
    id: id,
    group: fromPort.group,
    text: text,
    attrs: {
      circle: {
        r: 10,
      },
    },
  });
  updateData(node);
  RxHistory.stopBatch('NodeCallback CreatePort')
  return node.getPort(id)
}

export const onPortClick = (node, port, e) => {
  // 计算基本信息
  const isAdd = port.text === '+'
  const isLeft = port.group === 'left'
  const shape = node.store.data.shape;
  const isLogic = shape === 'logic-node';

  // 显示花瓣选择
  if (isLogic) {
    if (isAdd) createPort(node, port)
  } else {
    const deviceMsg = getDeviceById(node.data.device)
    const supportPortList = isLeft ? deviceMsg.inputText : deviceMsg.outputText
    if (supportPortList.length === 0) {
      MuiAlert.warning('当前设备无可用接口')
      return;
    }
    makeFlower(e, port.text, shape, supportPortList, (text) => {
      RxHistory.startBatch('NodeCallback MakeFlower')
      if (port.text === '+') {
        const newPort = createPort(node, port, text)
        checkAndDispatch(newPort, e)
      } else if (text === '删除') {
        node.removePort(port.id)
        updateData(node)
      } else {
        node.setPortProp(port.id, 'text', text)
        updateData(node)
        checkAndDispatch(port, e)
      }
      RxHistory.stopBatch('NodeCallback MakeFlower')
    })
  }
}

export function updateData(node, options = {}) {
  node.setData({
    device: node.data.device,
    update: node.data.update ? node.data.update + 1 : 1,
  }, options);
}

export function dispatchConnect(portId) {
  let view = null;
  for (const v of document.getElementsByTagName("circle")) {
    if (v.getAttribute("port") === portId) {
      view = v;
      break;
    }
  }
  if (!view) return;
  const event = document.createEvent("MouseEvents");
  event.initMouseEvent(
    "mousedown",
    true,
    true,
    window,
    1,
    0,
    0,
    RX.dispatchX,
    RX.dispatchY,
    false,
    false,
    false,
    false,
    0,
    null
  );
  view.dispatchEvent(event);
  window.isDispatch = true;
}

export function checkAndDispatch(port, e) {
  if (window.isDispatch) return;
  RX.setDispatchPosition(e.clientX, e.clientY)
  let flag = true;
  if (port) {
    for (const edge of RX.graph.getEdges()) {
      if(edge.getTarget().port === port.id){
        flag = false;
        break
      }
      if (edge.getSource().port === port.id) {
        flag = false;
        break
      }
    }
  }
  if(flag) dispatchConnect(port.id)
}

