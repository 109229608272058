import SvgElCreator from "../../utils/svg-el-creator";
import Block, {Item} from "../../block/block.class";
import {ChangeEvent, ChangeEventType, iotZoom, RhineBlock} from "../../RhineBlock";
import Arg from "../../block/arg.class";
import RX from "../../../../App/Editor/RX/RX";

export default function renderGraph(dom: HTMLElement, items: Item[]): Graph {
  const graph = new Graph(dom);
  graph.render(items, true);
  RhineBlock.registerGraph(graph);
  return graph;
}

// 画布类
// 图形块只可绘制在画布上，可以跨画布进行拖拽
export class Graph {
  blocks: Block[] = [];
  svg: SVGSVGElement;
  dom: HTMLElement;
  isToolbox: boolean = false;
  isDisable: boolean = false;
  
  toJson() {
    return {
      isToolbox: this.isToolbox,
      isDisable: this.isDisable,
      blocksLength: this.blocks.length,
    }
  }

  constructor(dom: HTMLElement) {
    this.dom = dom;
    this.svg = SvgElCreator.appendSvg(dom);
  }

  // 渲染图形块至当前画布
  // 返回当前画布中根节点的个数
  render(items: Item[] = [], clear = false, addEvent = true): number {
    if (clear) this.clear(addEvent);
    // console.trace('GraphRender', items)
    for (const item of items) {
      const block = Block.fromItem(item)
      block.setGraph(this)
      RhineBlock.Render.render(block, this.svg, this.getZoom());
      block.setPosition(
        item.x ? item.x : 100,
        item.y ? item.y : 100,
      );
      this.blocks.push(block);
      addEvent && this.onChange(ChangeEventType.ADD, block)
    }
    return this.blocks.length;
  }

  compile(): string {
    return RhineBlock.Compiler.compile(this.blocks);
  }

  // 递归遍历所有图形块
  recurBlocks(fn: (block: Block) => void) {
    for (const tb of this.blocks) {
      tb.recurMapBlock(fn);
    }
  }

  // 通过Block移除内容
  remove(block: Block, removeEvent = true) {
    const i = this.blocks.indexOf(block);
    if (i < 0) return;
    if (block.view) {
      this.svg.removeChild(block.view);
    }
    // block.graph = null;
    this.blocks.splice(i, 1);
    removeEvent ? this.onChange(ChangeEventType.REMOVE, block) : null;
  }

  getBlocksData(): Item[] {
    const data: Item[] = []
    this.blocks.map(block => {
      data.push(block.getItem());
    });
    return data;
  }

  clear(addEvent = true) {
    for (const block of this.blocks) {
      if(addEvent) this.onChange(ChangeEventType.REMOVE, block)
    }
    this.svg.innerHTML = "";
    // 遮罩层/蒙版 ------------------ 开始 ----------------
    const defs = document.createElementNS("http://www.w3.org/2000/svg", "defs");
    const mask = SvgElCreator.newSvgElement("mask" ,{
      id:"mask"
    })
    // 遮罩层内部的块，使用#666透明度
    const maskRect = SvgElCreator.newRect(0,0,"100%",'100%','#666')
    // 添加遮罩层块到遮罩层
    mask.appendChild(maskRect)
    //添加蒙版到defs
    defs.appendChild(mask)
    this.svg.appendChild(defs)
    // 遮罩层/蒙版

    this.blocks = [];
  }

  eventListeners: ((e: ChangeEvent) => void)[] = [];

  addEventListener(fn: (e: ChangeEvent) => void) {
    this.eventListeners.push(fn);
  }

  onChangeEvent(e: ChangeEvent) {
    e.graph = this;
    RhineBlock.onChange(e);
    for (const eventListener of this.eventListeners) {
      eventListener(e)
    }
  }

  setDisable(disable: boolean) {
    if(this.isDisable === disable) return;
    this.isDisable = disable;
    this.recurBlocks(block => {
      if(disable != block.isDisable) {
        block.rerender()
      }
    })
  }

  getZoom() {
    return RhineBlock.getZoom(this);
  }

  onChange(type: ChangeEventType, block: Block, arg?: Arg) {
    this.onChangeEvent({
      type,
      block,
      arg,
      time: undefined,
      graph: undefined,
    })
  }

  getContentRange() {
    if (this.blocks.length == 0) {
      return [0, 0, 0, 0]
    }
    const zoom = RX.getZoom()
    let xMin = -1, xMax = -1, yMin = -1, yMax = -1  // contain block's min size
    for(const block of this.blocks) {
      if (block.x === undefined || block.y === undefined || block.view === null) continue
      if (block.x < xMin || xMin === -1) xMin = block.x
      if (block.y < yMin || yMin === -1) yMin = block.y
      const rect = block.view.getBoundingClientRect()
      const bxw = block.x + rect.width
      const byh = block.y + rect.height
      if (bxw > xMax || xMax === -1) xMax = bxw
      if (byh > yMax || xMax === -1) yMax = byh
    }
    return [xMin / zoom, yMin / zoom, xMax / zoom, yMax / zoom]
  }
}

