import {BlockType, IBlock} from "../core/block/block.class";
import {RhineBlock} from "../core/RhineBlock";
import {FieldType} from "../core/block/arg.class";
import {JsOrderComplete} from "../core/compiler/js/js-compiler";

const Order = JsOrderComplete;

const logicBlocks: IBlock[] = [
  {
    name: "logic_state",
    type: BlockType.Output,
    output: "bool",
    color: "#71dccc",
    lines: [
      [
        {text: "状态"},
        {field: FieldType.Dropdown, option: ["开", "关"], optionCode: ["true", "false"]},
        {text: ""},
      ],
    ],
    compiler: "$0",
  },
  {
    name: "logic_if",
    type: BlockType.Statement,
    color: "#ffac5d",
    lines: [
      [{text: "如果"}, {value: "bool"}],
      [{text: "执行"}, {statement: null}],
    ],
    toolbox: [
      // {block: 'math_add', args: [{block: 'math_add', args: [{block: 'number_random_float'}]}]},
      // {block: 'control_if', args: [null, {block: 'console_print'}]},
    ],
    compiler: (args) => {
      const [condition, statement] = args;
      return `if(${condition}){\n${statement}}\n`;
    }
  },
  {
    name: "logic_if_else",
    type: BlockType.Statement,
    color: "#ffac5d",
    lines: [
      [{text: "如果"}, {value: "bool"}],
      [{text: "执行"}, {statement: null}],
      [{text: "否则"}, {statement: null}],
    ],
    toolbox: [
      // {block: 'math_add', args: [{block: 'math_add', args: [{block: 'number_random_float'}]}]},
      // {block: 'control_if', args: [null, {block: 'console_print'}]},
    ],
    compiler: (args) => {
      const [condition, statement, elseStatement] = args;
      return `if(${condition}){\n${statement}}else{\n${elseStatement}}\n`;
    }
  },
  {
    name: "logic_xorgate",
    type: BlockType.Output,
    color: "#ffac5d",
    lines: [
      [
        {field: FieldType.Text, default: "A"},
        {field: FieldType.Text, default: ">"},
        {field: FieldType.Text, default: "B"},
      ],
    ],
  },
  {
    name: "logic_gate",
    type: BlockType.Output,
    output: "bool",
    color: "#84c974",
    lines: [
      [
        {value: "bool"},
        {field: FieldType.Dropdown, option: ["并且", "或者"], default: 0, optionCode: ["&&", "||"]},
        {value: "bool"},
      ],
    ],
    toolbox: [],
    compiler: "$0 $1 $2",
    level: Order.LOGICAL_AND,
  },
  {
    name: "logic_not",
    type: BlockType.Output,
    output: "bool",
    color: "#84c974",
    lines: [[{value: "bool"}, {text: "不成立 "}]],
    compiler: "!$0",
    level: Order.LOGICAL_NOT,
  },
  // {
  //   name: 'logic_null',
  //   type: BlockType.Output,
  //   color: '#ffac5d',
  //   lines: [
  //     [
  //       {text: "空"},
  //     ]
  //   ]
  // }
];

RhineBlock.registerBlocksData(logicBlocks);

export default logicBlocks;
