import React, {useEffect, useRef, useState} from 'react';
import MonacoEditor from 'react-monaco-editor';
import CodeStorage from "../../CodeStorage";

export default function CodeEditor(props) {
  const [code, setCode] = useState(CodeStorage.js);
  const ref = useRef();
  const [width, setWidth] = useState('100%')
  const [height, setHeight] = useState('100%')
  const [language, setLanguage] = useState('logic')
  window.setCode = function (code) {
    CodeStorage.logic = code
    setCode(CodeStorage.logic)
    setLanguage('logic')
  }
  window.setNewCode = function (language = 'logic') {
    setLanguage(language)
    if (language === 'javascript') {
      setCode(CodeStorage.js)
    } else if (language === 'typescript') {
      setCode(CodeStorage.ts)
    } else {
      setCode(CodeStorage.logic)
    }
  }

  const options = {
    selectOnLineNumbers: true
  };

  const editorDidMount = (editor, monaco) => {
    if(!editor) return

    editor.focus();
    window.resizeMonaco = () => {
      setWidth(ref.current.clientWidth + 'px')
      setHeight(ref.current.clientHeight + 'px')
    }
  }
  useEffect(()=>{
    editorDidMount()
  })

  const onChange = (newValue, e) => {
  }

  return (
    <div ref={ref} style={{width: '100%', height: '100%'}}>
      <MonacoEditor
        width={width}
        height={height}
        language={language === 'typescript' ? 'typescript' : 'javascript'}
        theme="vs"
        value={code}
        options={options}
        onChange={onChange}
        editorDidMount={editorDidMount}
        automaticLayout={true}
      />
    </div>
  );
}
