import CloseIcon from "@mui/icons-material/Close";
import Style from "./ModalTitle.module.css";
import { IconButton } from "@mui/material";

function ModalTitle(props) {
  return (
    <div className={Style.ModalTitle}>
      <span className={Style.text}>{props.title}</span>
      <IconButton
        className={Style.closeBtn}
        onClick={props.onClose}
        style={{ position: "absolute", right: 0, margin: 10 }}
      >
        <CloseIcon style={{ width: 18, height: 18, fill: "#5C5F66" }} />
      </IconButton>
    </div>
  );
}

export default ModalTitle;
