const exists1 = {
    "name": "中海智能家居",
    "cells": [
        {
            "position": {
                "x": 90,
                "y": 240
            },
            "size": {
                "width": 208,
                "height": 112
            },
            "view": "react-shape-view",
            "shape": "device-node",
            "component": {
                "key": null,
                "ref": null,
                "props": {},
                "_owner": null,
                "_store": {}
            },
            "ports": {
                "groups": {
                    "left": {
                        "position": "left",
                        "attrs": {
                            "circle": {
                                "r": 10,
                                "magnet": true,
                                "stroke": "#ffffff00",
                                "strokeWidth": 0,
                                "fill": "#ffffff00"
                            }
                        }
                    },
                    "right": {
                        "position": "right",
                        "attrs": {
                            "circle": {
                                "r": 10,
                                "magnet": true,
                                "stroke": "#ffffff00",
                                "strokeWidth": 0,
                                "fill": "#ffffff00"
                            }
                        }
                    }
                },
                "items": [
                    {
                        "id": "2-IN-ADD",
                        "group": "left",
                        "text": "+",
                        "attrs": {
                            "circle": {
                                "r": 0
                            }
                        }
                    },
                    {
                        "id": "2-OUT-1",
                        "group": "right",
                        "text": "开关",
                        "attrs": {
                            "circle": {
                                "r": 10
                            }
                        }
                    },
                    {
                        "id": "2-OUT-ADD",
                        "group": "right",
                        "text": "+",
                        "attrs": {
                            "circle": {
                                "r": 0
                            }
                        }
                    }
                ]
            },
            "id": "N2",
            "data": {
                "device": "HumanMotionSensor",
                "connecting": null,
                "update": 14,
                // "note": "用于检测是否有人经过"
            },
            "zIndex": 1
        },
        {
            "position": {
                "x": 100,
                "y": 610
            },
            "size": {
                "width": 208,
                "height": 112
            },
            "view": "react-shape-view",
            "shape": "device-node",
            "component": {
                "key": null,
                "ref": null,
                "props": {},
                "_owner": null,
                "_store": {}
            },
            "ports": {
                "groups": {
                    "left": {
                        "position": "left",
                        "attrs": {
                            "circle": {
                                "r": 10,
                                "magnet": true,
                                "stroke": "#ffffff00",
                                "strokeWidth": 0,
                                "fill": "#ffffff00"
                            }
                        }
                    },
                    "right": {
                        "position": "right",
                        "attrs": {
                            "circle": {
                                "r": 10,
                                "magnet": true,
                                "stroke": "#ffffff00",
                                "strokeWidth": 0,
                                "fill": "#ffffff00"
                            }
                        }
                    }
                },
                "items": [
                    {
                        "id": "4-IN-ADD",
                        "group": "left",
                        "text": "+",
                        "attrs": {
                            "circle": {
                                "r": 0
                            }
                        }
                    },
                    {
                        "id": "4-OUT-1",
                        "group": "right",
                        "text": "数值",
                        "attrs": {
                            "circle": {
                                "r": 10
                            }
                        }
                    },
                    {
                        "id": "4-OUT-ADD",
                        "group": "right",
                        "text": "+",
                        "attrs": {
                            "circle": {
                                "r": 0
                            }
                        }
                    }
                ]
            },
            "id": "N4",
            "data": {
                "device": "InfraredTemperatureSensor",
                "connecting": null,
                "update": 13
            },
            "zIndex": 2
        },
        {
            "position": {
                "x": 1110,
                "y": 230
            },
            "size": {
                "width": 208,
                "height": 112
            },
            "view": "react-shape-view",
            "shape": "device-node",
            "component": {
                "key": null,
                "ref": null,
                "props": {},
                "_owner": null,
                "_store": {}
            },
            "ports": {
                "groups": {
                    "left": {
                        "position": "left",
                        "attrs": {
                            "circle": {
                                "r": 10,
                                "magnet": true,
                                "stroke": "#ffffff00",
                                "strokeWidth": 0,
                                "fill": "#ffffff00"
                            }
                        }
                    },
                    "right": {
                        "position": "right",
                        "attrs": {
                            "circle": {
                                "r": 10,
                                "magnet": true,
                                "stroke": "#ffffff00",
                                "strokeWidth": 0,
                                "fill": "#ffffff00"
                            }
                        }
                    }
                },
                "items": [
                    {
                        "id": "8-IN-1",
                        "group": "left",
                        "text": "开关",
                        "attrs": {
                            "circle": {
                                "r": 10
                            }
                        }
                    },
                    {
                        "id": "8-IN-ADD",
                        "group": "left",
                        "text": "+",
                        "attrs": {
                            "circle": {
                                "r": 0
                            }
                        }
                    },
                    {
                        "id": "8-OUT-ADD",
                        "group": "right",
                        "text": "+",
                        "attrs": {
                            "circle": {
                                "r": 0
                            }
                        }
                    }
                ]
            },
            "id": "N8",
            "data": {
                "device": "Kettle",
                "connecting": null,
                "update": 11
            },
            "zIndex": 4
        },
        {
            "position": {
                "x": 1120,
                "y": 610
            },
            "size": {
                "width": 208,
                "height": 112
            },
            "view": "react-shape-view",
            "shape": "device-node",
            "component": {
                "key": null,
                "ref": null,
                "props": {},
                "_owner": null,
                "_store": {}
            },
            "ports": {
                "groups": {
                    "left": {
                        "position": "left",
                        "attrs": {
                            "circle": {
                                "r": 10,
                                "magnet": true,
                                "stroke": "#ffffff00",
                                "strokeWidth": 0,
                                "fill": "#ffffff00"
                            }
                        }
                    },
                    "right": {
                        "position": "right",
                        "attrs": {
                            "circle": {
                                "r": 10,
                                "magnet": true,
                                "stroke": "#ffffff00",
                                "strokeWidth": 0,
                                "fill": "#ffffff00"
                            }
                        }
                    }
                },
                "items": [
                    {
                        "id": "10-IN-1",
                        "group": "left",
                        "text": "开关",
                        "attrs": {
                            "circle": {
                                "r": 10
                            }
                        }
                    },
                    {
                        "id": "10-IN-ADD",
                        "group": "left",
                        "text": "+",
                        "attrs": {
                            "circle": {
                                "r": 0
                            }
                        }
                    },
                    {
                        "id": "10-OUT-ADD",
                        "group": "right",
                        "text": "+",
                        "attrs": {
                            "circle": {
                                "r": 0
                            }
                        }
                    }
                ]
            },
            "id": "N10",
            "data": {
                "device": "AirConditioningFan",
                "connecting": null,
                "update": 11
            },
            "zIndex": 5
        },
        {
            "position": {
                "x": 420,
                "y": 60
            },
            "size": {
                "width": 576,
                "height": 760
            },
            "view": "react-shape-view",
            "shape": "logic-node",
            "component": {
                "key": null,
                "ref": null,
                "props": {},
                "_owner": null,
                "_store": {}
            },
            "ports": {
                "groups": {
                    "left": {
                        "position": "left",
                        "attrs": {
                            "circle": {
                                "r": 10,
                                "magnet": true,
                                "stroke": "#ffffff00",
                                "strokeWidth": 0,
                                "fill": "#ffffff00"
                            }
                        }
                    },
                    "right": {
                        "position": "right",
                        "attrs": {
                            "circle": {
                                "r": 10,
                                "magnet": true,
                                "stroke": "#ffffff00",
                                "strokeWidth": 0,
                                "fill": "#ffffff00"
                            }
                        }
                    }
                },
                "items": [
                    {
                        "id": "12-IN-1",
                        "group": "left",
                        "text": "A1",
                        "attrs": {
                            "circle": {
                                "r": 10
                            }
                        }
                    },
                    {
                        "id": "12-IN-2",
                        "group": "left",
                        "text": "A2",
                        "attrs": {
                            "circle": {
                                "r": 10
                            }
                        }
                    },
                    {
                        "id": "12-IN-ADD",
                        "group": "left",
                        "text": "+",
                        "attrs": {
                            "circle": {
                                "r": 0
                            }
                        }
                    },
                    {
                        "id": "12-OUT-1",
                        "group": "right",
                        "text": "B1",
                        "attrs": {
                            "circle": {
                                "r": 10
                            }
                        }
                    },
                    {
                        "id": "12-OUT-2",
                        "group": "right",
                        "text": "B2",
                        "attrs": {
                            "circle": {
                                "r": 10
                            }
                        }
                    },
                    {
                        "id": "12-OUT-ADD",
                        "group": "right",
                        "text": "+",
                        "attrs": {
                            "circle": {
                                "r": 0
                            }
                        }
                    }
                ]
            },
            "id": "N12",
            "data": {
                "blocks": [
                    {
                        x: 100,
                        y: 80,
                        block: "event_equip_state",
                        args: [
                            0,
                            1,
                            {
                                next: true,
                                block: "equipment_control",
                                args: [
                                    1,
                                    0,
                                ]
                            }
                        ],
                    },
                    {
                        x: 100,
                        y: 220,
                        block: "event_compare",
                        args: [
                            1,
                            0,
                            "300",
                            {
                                next: true,
                                block: "equipment_control",
                                args: [
                                    1,
                                    0,
                                ]
                            }
                        ],
                    },
                    {
                        x: 100,
                        y: 360,
                        block: "event_equip_state",
                        args: [
                            0,
                            0,
                            {
                                next: true,
                                block: "equipment_control",
                                args: [
                                    0,
                                    1,
                                ]
                            }
                        ],
                    },
                ],
                "update": 12,
                "connecting": null
            },
            "zIndex": 6
        }
    ]
}

export default exists1