import md5 from 'js-md5'
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";

export default class Api {
  static SUCCESS_MW = 'c53a8c85f53f12d372fddd4e7a298d5f'
  static URL = 'https://newrccloudgateway.jeejio.com'

  static token = ""
  static user = null
  static navigate = () => {}

  static checkUserLocal(username, password) {
    let str = `U:${username},P:${password},S:TEMP_SALT`
    let flag = md5(str) === this.SUCCESS_MW || this.isDevelopmentEnv()
    // console.log(str, md5(str))
    if (flag) {
      this.token = "TEMP_TOKEN"
    }
    return flag
  }

  static checkLogin() {
      if (Api.token.length === 0) {
        Api.token = Cookies.get('token')
      }
      return !!(Api.token && Api.token.length > 0)
  }

  static removeLogin() {
    Cookies.remove('token')
    Api.token = ''
    setTimeout(e => {
      Api.navigate('/')
    })
  }

  static async login(phone, password) {
    return new Promise(function(resolve, reject){
      if (Api.isDevelopmentEnv()) {
        // resolve({code: 200})
        // return
      }

      let myHeaders = new Headers();
      myHeaders.append("Platform", "1");
      myHeaders.append("Content-Type", "application/json");
      let raw = JSON.stringify({
        "phone": phone,
        "password": password,
        "loginType": 0
      })
      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      }

      fetch(`${Api.URL}/user/login`, requestOptions)
        .then(response => response.json())
        .then(result => {
          let code = result["code"]
          if (code === 200) {
            Api.token = result["result"]["token"]
            Cookies.set('token', Api.token)
            Cookies.set('user.name', result["result"]["name"])
            resolve(result)
          } else {
            reject(result)
          }
        })
        .catch(error => {
          console.error(error)
          reject({code: 404})
        });
    })
  }

  static async list() {
    return new Promise(function(resolve, reject){
      if (Api.isDevelopmentEnv()) {
        // resolve({code: 200})
        // return
      }
      if (!Api.checkLogin()) {
        Api.removeLogin()
        reject({code: 11108, message: "登录过期，请重新登录"})
      }

      let requestOptions = {
        method: 'GET',
        headers: Api.getHeaders(),
        redirect: 'follow'
      };
      fetch(`${Api.URL}/app/graphical/app/list`, requestOptions)
        .then(response => response.json())
        .then(result => {
          let code = result["code"]
          if (code === 200) {
            resolve(result)
          } else {
            if (code === 11108 || code === 11101) {
              Api.removeLogin()
            }
            reject(result)
          }
        })
        .catch(error => {
          console.error(error)
          reject({code: 404})
        });
    })
  }

  static async detail(id) {
    return new Promise(function(resolve, reject){
      if (Api.isDevelopmentEnv()) {
        // resolve({code: 200})
        // return
      }
      if (!Api.checkLogin()) {
        Api.removeLogin()
        reject({code: 11108, message: "登录过期，请重新登录"})
      }

      let requestOptions = {
        method: 'GET',
        headers: Api.getHeaders(),
        redirect: 'follow'
      };
      fetch(`${Api.URL}/app/graphical/app/` + id, requestOptions)
        .then(response => response.json())
        .then(result => {
          let code = result["code"]
          if (code === 200) {
            resolve(result)
          } else {
            if (code === 11108 || code === 11101) {
              Api.removeLogin()
            }
            reject(result)
          }
        })
        .catch(error => {
          console.error(error)
          reject({code: 404})
        });
    })
  }

  static async create(type = -1) {
    return new Promise(function(resolve, reject){
      if (Api.isDevelopmentEnv()) {
        // resolve({code: 200})
        // return
      } else if (!Api.checkLogin()) {
        Api.removeLogin()
        reject({code: 11108, message: "登录过期，请重新登录"})
      }

      let raw = JSON.stringify({
        name: "新项目",
        thumbnail: "",
        content: "{}",
        type: type,
        description: ""
      })
      let requestOptions = {
        method: 'PUT',
        headers: Api.getHeaders(),
        body: raw,
        redirect: 'follow'
      };
      fetch(`${Api.URL}/app/graphical/app`, requestOptions)
        .then(response => response.json())
        .then(result => {
          let code = result["code"]
          if (code === 200) {
            resolve(result)
          } else {
            if (code === 11108 || code === 11101) {
              Api.removeLogin()
            }
            reject(result)
          }
        })
        .catch(error => {
          console.error(error)
          reject({code: 404})
        });
    })
  }

  static async delete(id) {
    return new Promise(function(resolve, reject) {
      if (!Api.checkLogin()) {
        Api.removeLogin()
        reject({code: 11108, message: "登录过期，请重新登录"})
      }

      let requestOptions = {
        method: 'DELETE',
        headers: Api.getHeaders(),
        redirect: 'follow'
      };
      fetch(`${Api.URL}/app/graphical/app/`+id, requestOptions)
        .then(response => response.json())
        .then(result => {
          let code = result["code"]
          if (code === 200) {
            resolve(result)
          } else {
            if (code === 11108 || code === 11101) {
              Api.removeLogin()
            }
            reject(result)
          }
        })
        .catch(error => {
          console.error(error)
          reject({code: 404})
        });
    })
  }

  static async upload(id, project) {
    return new Promise(function(resolve, reject) {
      if (Api.isDevelopmentEnv()) {
        // resolve({code: 200})
        // return
      }
      if (!Api.checkLogin()) {
        Api.removeLogin()
        reject({code: 11108, message: "登录过期，请重新登录"})
      }

      let raw = JSON.stringify(project)
      let requestOptions = {
        method: 'POST',
        headers: Api.getHeaders(),
        body: raw,
        redirect: 'follow'
      };
      fetch(`${Api.URL}/app/graphical/app/` + id, requestOptions)
        .then(response => response.json())
        .then(result => {
          let code = result["code"]
          if (code === 200) {
            resolve(result)
          } else {
            if (code === 11108 || code === 11101) {
              Api.removeLogin()
            }
            reject(result)
          }
        })
        .catch(error => {
          console.error(error)
          reject({code: 404})
        });
    })
  }

  static async cover(fileInput) {
    return new Promise(function(resolve, reject) {
      if (Api.isDevelopmentEnv()) {
        // resolve({code: 200})
        // return
      } else if (!Api.checkLogin()) {
        Api.removeLogin()
        reject({code: 11108, message: "登录过期，请重新登录"})
      }

      let formdata = new FormData();
      formdata.append("file", fileInput, "[PROXY]");
      let requestOptions = {
        method: 'POST',
        headers: Api.getHeaders(true),
        body: formdata,
        redirect: 'follow'
      };
      fetch(`${Api.URL}/app/graphical/app/thumbnail/file`, requestOptions)
        .then(response => response.json())
        .then(result => {
          let code = result["code"]
          if (code === 200) {
            resolve(result)
          } else {
            if (code === 11108 || code === 11101) {
              Api.removeLogin()
            }
            reject(result)
          }
        })
        .catch(error => {
          console.error(error)
          reject({code: 404})
        });
    })
  }

  static getHeaders(withOutJson = false) {
    let headers = new Headers();
    headers.append("platform", "5");
    headers.append("token", Api.token);
    if (!withOutJson) {
      headers.append("Content-Type", "application/json");
    }
    return headers
  }


  static isDevelopmentEnv() {
    // eslint-disable-next-line no-undef
    return process.env.NODE_ENV === 'development'
  }

  static isProductionEnv() {
    // eslint-disable-next-line no-undef
    return process.env.NODE_ENV === 'production'
  }

  static hadLogin() {
    return this.token.length > 0 || this.isDevelopmentEnv()
  }
}


