import React from "react";
import Style from "./DeviceNode.module.css";
import "./DeviceNodeX6.css";
import {onPortClick} from "../../ports/PublicPort/NodeCallbacks";
import {Popover} from "@mui/material";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import renderGraph from "../../../../../../RhineBlock/core/view/graph/graph";
import PublicPort from "../../ports/PublicPort/PublicPort";
import {toRGBObject} from "../../../../../../RhineBlock/core/utils/color-adjust";
import RxHistory from "../../../history/RxHistory";
import RX from "../../../RX";
import DealOverlay from "../../../tidy/DealOverlay";
import Direction from "../../../tidy/Direction";
import EventsSplit from "../../../../../../RhineBlock/core/compiler/base/events-split";
import CodeStorage from "../../../../CodeStorage";
import {ChangeEventType, RhineBlock} from "../../../../../../RhineBlock/core/RhineBlock";
import DragManager from "../../../../../../RhineBlock/core/drag/drag-manager";

export class LogicNode extends React.Component {
  graph = null;
  node = null;

  graphFitPadding = 4; // 自动缩放布局时给的合适的内边距
  passOnceRender = false; // 当图形快变化时屏蔽画布重绘

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      anchorElPad: null,
      anchorElPort: null,
      isLogicPadDisabled: false,
      portStatus: false,
      portStatusList: {}, //读取这个状态列表，上面仅展示用
      anchorPort: null,
      portDisableMode: -1, // -1不显示 0禁用 1启用
    };
    this.updateFlag = false;
  }

  setAnchorEl(v) {
    this.setState({
      anchorEl: v,
    });
    this.updateFlag = true;
  }

  setAnchorElPad(v) {
    this.setState({
      anchorElPad: v,
    });
    this.updateFlag = true;
  }

  setAnchorElPort(el, port) {
    this.setState({
      anchorElPort: el,
      anchorPort: port,
    });
    this.updateFlag = true;
  }

  setLogicPadDisable(status) {
    this.setState({
      isLogicPadDisabled: status
    })

    if (!this.graph) return
    this.graph.setDisable(status)
    // this.updateFlag = true;
  }

  setPortDisableMode(v) {
    if (v !== 0 && v !== 1) v = -1
    this.setState({
      portDisableMode: v,
    });
    this.updateFlag = true;
  }

  setPortDisableList(id, data) {
    let status = this.state.portStatusList
    let port = status[id]
    if (port) {
      status[id] = data
    } else {
      status[id] = false
    }
    this.setState({
      portStatus: status
    })
    // this.updateFlag = true;
  }

  setPortDisable(status) {
    this.setState({
      portStatus: status
    })
    // this.updateFlag = true;
  }

  shouldComponentUpdate() {
    if (this.updateFlag) {
      this.updateFlag = false;
      return true;
    }
    const {node} = this.props;
    if (node) {
      if (node.hasChanged("data")) {
        return true;
      }
    }
    return false;
  }

  componentDidMount() {
    const graphRef = this.refs.graph

    const {node} = this.props
    const data = node?.getData()

    const blocks = [];
    if (data && data.blocks) {
      blocks.push(...data.blocks)
    }

    if (!this.graph) {
      this.graph = renderGraph(graphRef, blocks)
      CodeStorage.logic = this.graph.compile()
      window.setNewCode && window.setNewCode('logic')

      if (this.node && this.graph) {
        this.node.setData({
          blocks: blocks,
          graph: this.graph.toJson(),
          connecting: data.connecting,
          isDisable: data.isDisable,
          update: data.update ? data.update + 1 : 1,
        }, {ignore: true})
        this.graph.addEventListener((e) => {
          let blocks = e.blocks || this.graph.getBlocksData()
          window.passNum++

          CodeStorage.logic = this.graph.compile()
          window.setNewCode && window.setNewCode('logic')
          const events = EventsSplit.make(CodeStorage.logic)
          RxHistory.startBatch('LogicNode FreshBoardData')
          if (e.type !== ChangeEventType.INPUT) {
            if (RhineBlock.inputting) {
              e.blocks = this.graph.getBlocksData()
              e.graph = this.graph
              RhineBlock.waitEvent = e
            } else {
              if (RhineBlock.waitEvent && DragManager.current == null) {
                let we = RhineBlock.waitEvent
                RhineBlock.waitEvent = null
                this.graph.onChangeEvent(we)
                RxHistory.startBatch('LogicNode FreshBoardData Extra')
              }
              this.setX6Data(blocks, events)
            }
          }

          if (e.type !== 'remove' && e.type !== 'disable') {
            this.freshBoardSize()
          }
          RxHistory.stopBatch('LogicNode FreshBoardData')
        });
      }

      if (this.node.getData().isDisable) {
        this.graph.setDisable(true)
      }
    }
  }

  setX6Data(blocks, events, silent = false, overwrite = true) {
    const data = this.node.getData();
    if (overwrite) {
      console.log('LogicNode SetData', blocks, events)
      this.passOnceRender = true
      this.node.setData({
        blocks: blocks,
        events: events,
        graph: this.graph.toJson(),
        connecting: data.connecting,
        isDisable: data.isDisable,
        update: data.update ? data.update + 1 : 1,
      }, {silent: silent, overwrite: true});
    } else {
      this.node.setData({
        blocks: blocks,
        events: events,
        update: data.update ? data.update + 1 : 1,
      }, {silent: silent});
    }
    // setTimeout(() => {
    //   console.log('setX6Data', this.node.getData())
    // }, 40)
  }

  // 刷新逻辑板大小
  freshBoardSize() {
    const {node} = this.props;
    let directions = []
    let hadInBatch = RxHistory.inBatch
    if (!hadInBatch) RxHistory.startBatch('LogicNode FreshBorderSize')
    let range = this.graph.getContentRange()
    let xMin = range[0], yMin = range[1]
    let size = this.node.size()
    if (xMin < 0 || yMin < 0) {
      if (yMin < 0) directions.push(Direction.top)
      if (xMin < 0) directions.push(Direction.left)
      xMin = xMin < 0 ? -xMin + this.graphFitPadding : 0
      yMin = yMin < 0 ? -yMin + this.graphFitPadding : 0
      node.resize(size.width + xMin, size.height + yMin, {direction: 'top-left'})
      this.moveAllBlocks(xMin, yMin)
    }
    range = this.graph.getContentRange()
    let xMax = range[2] + 96, yMax = range[3] - 20
    size = this.node.size()
    if (xMax > size.width || yMax > size.height) {
      if (yMax > size.height) directions.push(Direction.bottom)
      if (xMax > size.width) directions.push(Direction.right)
      xMax = xMax > size.width ? xMax + this.graphFitPadding : size.width
      yMax = yMax > size.height ? yMax + this.graphFitPadding : size.height
      node.resize(xMax, yMax, {direction: 'bottom-right'})
    }
    for (const direction of directions) {
      DealOverlay.dealFromOne(node, direction)
    }
    if (!hadInBatch) RxHistory.stopBatch('LogicNode FreshBorderSize')
  }

  // 变化逻辑板大小
  // direction: 'top' | 'right' | 'bottom' | 'left'; 扩大方向
  // size: number; 改变大小后该方向的尺寸
  changeLogicNodeSize(direction, size) {
    const ns = this.node.size()
    this.node.resize(ns)
  }

  // 平移内部所有图形块
  // dx: number, dy: number; 移动量
  moveAllBlocks(dx, dy) {
    for (const block of this.graph.blocks) {
      block.setPosition(block.x + dx, block.y + dy)
    }
  }

  render() {
    const {node} = this.props;
    const data = node.getData()
    this.node = node
    const ports = node?.port.ports;

    let getPosition = () => {
      return window.position;
    };
    // 展开右键菜单
    const handleContextMenu = (e) => {
      this.setAnchorEl(e.currentTarget)
      this.setAnchorElPad(e.currentTarget);
      window.position = { top: e.clientY, left: e.clientX };
    };
    // 关闭右键菜单
    const handleClose = () => {
      // 清空锚点
      this.setAnchorEl(null);
    };
    const open = Boolean(this.state.anchorEl);
    const id = open ? "popover" : undefined;


    const onDeleteNode = () => {
      RxHistory.startBatch('LogicNode DeleteNode')
      RX.graph?.getConnectedEdges(node).forEach((edge) => {
        edge.attr(["data", "disable"], false)
        RX.onChangeEdgeStatus(edge, false);
      })
      RX.removeCell(node);
      RxHistory.stopBatch('LogicNode DeleteNode')
    };

    const openPort = Boolean(this.state.anchorElPort);
    const idPort = open ? "popover" : undefined;
    const handleContextPort = (port, e) => {
      if (port.text === "+") {
        onPortClick(node, port, e);
      } else {
        this.setAnchorElPort(e.currentTarget, port);
        window.position = {top: e.clientY, left: e.clientX};
        const edge = RX.getEdgeByPort(node, port.id)
        let sourceDisable = edge?.getSourceCell().getData().isDisable
        let targetDisable = edge?.getTargetCell().getData().isDisable
        if (!edge || sourceDisable || targetDisable) {
          this.setPortDisableMode()
        } else {
          let edgeDisable = edge.attr(['data', 'disable']) || false
          this.setPortDisableMode(edgeDisable ? 1 : 0)
        }
      }
    };
    const handleClickPort = (port, e, max) => {
      handleContextPort(port, e);
    };
    const handleClosePort = () => {
      this.setAnchorElPort(null, null);
      this.setPortDisableMode()
    };
    const onDeletePort = () => {
      handleClosePort()
      if (this.state.anchorElPort) {
        node.removePort(this.state.anchorElPort);
      }
    };

    const setPortDisable = (disable) => {
      handleClosePort()
      const port = this.state.anchorPort
      const edge = RX.getEdgeByPort(node, port.id)
      if (!edge) return
      RxHistory.startBatch('LogicNode PortDisable')
      edge.attr(['data', 'disable'], disable)
      RX.onChangeEdgeStatus(edge, disable)
      RxHistory.stopBatch('LogicNode PortDisable')
    }

    // 再端点写入attr，所有数据存储于此
    const setPortStatus = () => {
      this.setAnchorElPort(null, null);
      let el = this.state.anchorEl
      let port = node.getPortProp(window.checkProt)
      if (port) {
        let data = port.attrs['data'];
        const dom = el.parentElement.querySelector(`[id='${port.id}']`)
        let originColor = dom.style['backgroundColor']

        if (!data) { // 防止attr为空
          node.setPortProp(port, ['attrs', 'data', 'disable'], this.state.portStatus);
          try {
            port = node.getPortProp(window.checkProt)
            port.attrs['data'].disable
          } catch (e) { // todo 紧急使用，并非持久化方案
            console.error(e)
          }
          this.setPortDisableList(port.id, port.attrs['data'] ? port.attrs['data'].disable : this.state.portStatus)
          data = port.attrs['data'];
        }
        node.setPortProp(port, ['attrs', 'data'], {disable: !data.disable});
        if (!port.attrs['data'].originColor) {// 保存原色
          node.setPortProp(port, ['attrs', 'data', 'originColor'], originColor)
        }
        if (!port.attrs['data'].disable) {
          const rgb = toRGBObject(dom.style['backgroundColor'])
          dom.style['backgroundColor'] = `rgba(${rgb.red},${rgb.green},${rgb.blue},0.5)`
        } else {
          dom.style['backgroundColor'] = port.attrs['data'].originColor
        }
      }
    }

    //检测状态，显示文字
    const onChangePortStatus = () => {
      let port = node.getPortProp(window.checkProt)
      if (port) {
        let data = port.attrs['data'];
        if (data) {
          this.setPortDisable(port.attrs['data'] ? port.attrs['data'].disable : false)
        } else {
          this.setPortDisable(false)
        }
      }
    }

    if (window.passNum > 0) {
      window.passNum--;
    } else {
      if (this.graph && node) {
        const items = data.blocks
        this.graph.render(items, true, false)
      }
    }

    // 设置逻辑板禁用状态
    const setLogicPadDisable = (disable = !this.state.isLogicPadDisabled, ignore = false) => {
      RxHistory.startBatch('LogicNode LogicPadDisable')
      this.setLogicPadDisable(disable)
      if (!ignore) {
        node.setData({
          isDisable: disable,
          update: data.update ? data.update + 1 : 1,
        })
      }
      node.attr(['data', 'disable', this.state.disable])

      // setTimeout(()=>{
      // this.state.isLogicPadDisabled ? this.graph.svg.style = `width: 100%; height: 100%;`:this.graph.svg.style =
      //     `width: 100%; height: 100%;mask: url(#mask)`

      // 同步端点
      for (let port of node.ports.items) {
        let data = port.attrs['data'];
        const dom = el.parentElement.parentElement.querySelector(`[id='${port.id}']`)
        let originColor = dom.style['backgroundColor']

        if (!data) {// 防止attr为空
          node.setPortProp(port, ['attrs', 'data', 'disable'], this.state.isLogicPadDisabled);
          try {
            // port = node.getPortProp(window.checkPort)
            port.attrs['data'].disable
          } catch (e) { // todo 紧急使用，并非持久化方案
            port = node.getPort(port.id)
          }
          // this.setPortDisableList(port.id, port.attrs['data'] ? port.attrs['data'].disable : this.state.portStatus)
          data = port.attrs['data'];
        }
        node.setPortProp(port, ['attrs', 'data'], {disable: this.state.isLogicPadDisabled});
        if (!port.attrs['data'].originColor) {
          port = node.getPort(port.id)
        }
        const isLeft = port.group === "left";
        if (this.state.isLogicPadDisabled) {
          dom.style['background-color'] = isLeft ? "#529BF1" : "#B375E0";
        } else {
          dom.style['background-color'] = isLeft ? "#abccff" : "#d6b8ff";
        }
      }

      if (RX.graph) {
        for (const edge of RX.graph.getConnectedEdges(node)) {
          let state = !this.state.isLogicPadDisabled
          const edgeState = edge.attr(['data', 'disable'])
          const anotherNode = edge.getSourceCell() === node ? edge.getTargetCell() : edge.getSourceCell()
          const anotherState = anotherNode.getData().isDisable
          RX.onChangeEdgeStatus(edge, state || anotherState || edgeState)
        }
      }

      // 同步edge状态
      handleClose()
      RxHistory.stopBatch('LogicNode LogicPadDisable')
    }


    const checkDisable = () => {
      const dataIsDisable = data.isDisable || false
      if (dataIsDisable !== this.state.isLogicPadDisabled) {
        this.setLogicPadDisable(dataIsDisable)
      }
      if (this.graph) this.graph.setDisable(dataIsDisable)
      return dataIsDisable
    }
    const dataIsDisable = checkDisable()

    let el = this.state.anchorElPad
    if (el) {
      try {
        if (!this.state.isLogicPadDisabled) { // todo 临时方案 直接动dom
          el.classList.remove('logicPad', 'disable')
        } else {
          el.classList.add('logicPad', 'disable')
        }
      } catch (e) {
        el = undefined
      }
    }

    const resize = (i, e) => {
      e.stopPropagation()

      const zoom = RX.getZoom()
      const range = this.graph.getContentRange()
      let xMin = range[0], yMin = range[1], xMax = range[2], yMax = range[3]

      let wMin = xMax - xMin, hMin = yMax - yMin  // min width and height
      if (wMin < 320) wMin = 320
      if (hMin < 280) hMin = 280
      const pns = RX.getPortNum(this.node)  // port num s
      const mpn = pns[0] > pns[1] ? pns[0] : pns[1]  // more side's port num
      if (hMin < mpn * 40) hMin = mpn * 40  // limit min height by port num

      let isUd = i === 0 || i === 2  // is up and down or nor
      let size = node.size()
      let ss = isUd ? size.height : size.width  // start size
      let as = isUd ? size.width : size.height  // another size
      let sp = isUd ? e.clientY : e.clientX  // start position

      let dx = 0, dy = 0
      const freshSize = (e, overlay) => {
        const bs = this.graphFitPadding  // border size
        RX.isResizing = true
        let ds = (isUd ? e.clientY : e.clientX ) - sp  // delta size
        ds /= zoom
        if (ds > 0 || ds < 0) {
          if (i === 0 && ss - ds > ss - yMin + bs && ss - ds > hMin) {
            node.resize(as, ss - ds, {direction: 'top-left'})
            this.moveAllBlocks(0, (- ds - dy) * zoom)
            dy += - ds - dy
            if (overlay) DealOverlay.dealFromOne(node, Direction.top)
          } else if (i === 1 && ss + ds > xMax + bs + 96 && ss + ds > wMin) {
            node.resize(ds + ss, as, {direction: 'top-right'})
            if (overlay) DealOverlay.dealFromOne(node, Direction.right)
          } else if (i === 2 && ss + ds > yMax + bs - 20 && ss + ds > hMin) {
            node.resize(as, ss + ds, {direction: 'bottom-right'})
            if (overlay) DealOverlay.dealFromOne(node, Direction.bottom)
          } else if (i === 3 && ss - ds > ss - xMin + bs && ss - ds > wMin) {
            node.resize(ss - ds, as, {direction: 'bottom-left'})
            this.moveAllBlocks((- ds - dx) * zoom, 0)
            dx += - ds - dx
            if (overlay) DealOverlay.dealFromOne(node, Direction.left)
          }
        }
        RX.isResizing = false
      }
      const onMouseMove = (e) => {
        if (RX.isResizing) return
        freshSize(e)
      }
      const onMouseUp = (e) => {
        freshSize(e, true)
        document.removeEventListener('mousemove', onMouseMove)
        document.removeEventListener('mouseup', onMouseUp)
        RxHistory.stopBatch('LogicNode Resize')
      }
      RxHistory.startBatch('LogicNode Resize')
      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onMouseUp)
    }

    const stopPropagation = e => e.stopPropagation()
    const pdm = this.state.portDisableMode
    return (
      <div className={Style.node}>
        <div className={Style.logicHolder}>
          <div className={Style.connectors} style={{left: 0}} onMouseDown={stopPropagation}>
            {ports.map((port, i) => {
              if (port.group === "left") {
                // eslint-disable-next-line react/jsx-key
                return <PublicPort port={port} key={i} node={node} i={i} handleClickPort={handleClickPort} isLogic={true}/>
              }
            })}
          </div>
          <div className={Style.logicCenter}>
            <div className={Style.logicBg}
                 style={{opacity: dataIsDisable ? 0.5 : 1.0}}
                 onContextMenu={handleContextMenu}>
              <div className={Style.logicBgTitle}>逻辑编辑区</div>
              <div className={Style.logicBgGraph}>
                <div className={Style.logicBgImg}>
                  <p/>
                  <p/>
                  <p/>
                  <p/>
                </div>
                <div className={Style.logicScale}>
                  <p onMouseDown={e => resize(0, e)}/>
                  <p onMouseDown={e => resize(1, e)}/>
                  <p onMouseDown={e => resize(2, e)}/>
                  <p onMouseDown={e => resize(3, e)}/>
                </div>
              </div>
            </div>
            <div className={Style.logic}>
              <div ref={"graph"} className={Style.graph}/>
            </div>
            <div className={"node-border"}>
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
          <div className={Style.connectors} style={{right: 0}} onMouseDown={stopPropagation}>
            {ports.map((port, i) => {
              if (port.group === "right") {
                // eslint-disable-next-line react/jsx-key
                return <PublicPort port={port} key={i} node={node} i={i} handleClickPort={handleClickPort} isLogic={true}/>
              }
            })}
          </div>
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={getPosition()}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Paper sx={{width: 136}} className={Style.OptionPaper}>
            <MenuList dense>
              <MenuItem onClick={onDeleteNode}>删除逻辑板</MenuItem>
              <MenuItem onClick={e => setLogicPadDisable()}>
                {checkDisable() ? "启用" : "禁用"}
              </MenuItem>
            </MenuList>
          </Paper>
        </Popover>
        <Popover
          id={idPort}
          open={openPort}
          anchorEl={this.state.anchorElPort}
          onClose={handleClosePort}
          anchorReference="anchorPosition"
          anchorPosition={getPosition()}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Paper sx={{width: 136}} className={Style.OptionPaper}>
            <MenuList dense>
              <MenuItem onClick={onDeletePort}>删除接口</MenuItem>
              {
                pdm !== -1 ?
                  <MenuItem onClick={() => setPortDisable(pdm === 0)}>{pdm === 1 ? '启用' : '禁用'}</MenuItem> : null
              }
            </MenuList>
          </Paper>
        </Popover>
      </div>
    );
  }
}

export default LogicNode;
