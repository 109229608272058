import { BlockType, IBlock } from "../core/block/block.class";
import { RhineBlock } from "../core/RhineBlock";
import { FieldType } from "../core/block/arg.class";

const consoleBlock: IBlock[] = [
  {
    name: "unknown",
    type: BlockType.Statement,
    color: "#666666",
    lines: [[{ text: "未注册图形块" }]],
    compiler: "/* 未注册图形块 */",
  },
  {
    name: "console_output",
    type: BlockType.Statement,
    color: "#84c974",
    lines: [[{ text: "输出至控制台" }, { value: "str" }]],
    compiler: "console.log($0)\n",
  },
  {
    name: "console_input",
    type: BlockType.Output,
    output: "Text",
    color: "#84c974",
    lines: [[{ text: "获取控制台输入" }]],
    compiler: "prompt()",
  },
  {
    name: "console_sleep",
    type: BlockType.Statement,
    color: "#ffac5d",
    lines: [
      [
        { text: "等待" },
        { field: FieldType.Number, default: "1" },
        { field: FieldType.Dropdown, option: ["秒", "分钟", "小时", "毫秒"], optionCode: [" * 1000", " * 60000", " * 3600000", ""], default: 0 },
      ],
    ],
    compiler: "sleep($0$1)\n",
  },
];

RhineBlock.registerBlocksData(consoleBlock);

export default consoleBlock;
