
export const devicesTags = `
智能家居 智慧办公 智能交通 智慧农业 智慧医疗 智慧工厂 智慧健康 开发板套件 智能厨卫 智慧养宠 智能卫浴 智慧教育
`

export const devicesTestData = `
灯 Light
开关 亮度（相对） 亮度（绝对） 色温 光照模式 睡眠模式 夜间模式 护眼模式 RGB值 呼吸灯模式 断电记忆开关 渐变开关
开关 亮度（相对） 亮度（绝对） 色温 光照模式 睡眠模式 夜间模式 护眼模式 RGB值 呼吸灯模式 断电记忆开关 渐变开关
智能家居 智慧办公 智能交通 智慧农业 智慧医疗 智慧工厂 智慧健康

家用加湿器 HomeHumidifier
开关 喷雾开关 喷雾量 喷雾模式 喷雾量（百分比） 湿度 灯光开关 灯光模式 背光亮度 提示音 除菌开关
开关 喷雾开关 喷雾量 当前水位 喷雾模式 当前湿度 灯光模式 湿度 灯光开关 背光亮度 提示音 除菌开关
智能家居 智慧办公 智慧健康 

人体存在传感器 HumanBodySensor
报警条件 指示灯开关 蜂鸣器开关 触发延时（有人） 触发延时（无人） 报警开关 最小触发距离 最大触发距离
存在状态 报警条件 指示灯开关 蜂鸣器开关 报警开关 当前距离 光照度 触发延时（有人） 触发延时（无人） 最小触发距离 最大触发距离
智能家居 智慧办公 智能交通 智慧农业 智慧医疗 智慧工厂 智慧健康

门窗传感器 DoorAndWindowSensor
灵敏度 超时未关报警 超时时间 解除防拆报警
门窗状态 灵敏度 超时未关报警 超时时间 防拆报警
智能家居 智慧办公 智能交通 智慧农业 智慧医疗 智慧工厂 智慧健康
`
export const devicesData = `
灯 Light
开关 亮度（相对） 亮度（绝对） 色温 光照模式 睡眠模式 夜间模式 护眼模式 RGB值 呼吸灯模式 断电记忆开关
开关 亮度（相对） 亮度（绝对） 色温 光照模式 睡眠模式 夜间模式 护眼模式 RGB值 额定功率 呼吸灯模式 断电记忆开关
智能家居 智慧办公 智能交通 智慧农业 智慧医疗 智慧工厂 智慧健康

家用加湿器 HomeHumidifier
开关 喷雾开关 喷雾量 喷雾模式 湿度 灯光开关 灯光模式 背光亮度 提示音 除菌开关
开关 喷雾开关 喷雾量 当前水位 喷雾模式 当前湿度 湿度 灯光开关 灯光模式 背光亮度 提示音 除菌开关
智能家居 智慧办公 智慧健康 

人体存在传感器 HumanBodySensor
指示灯开关 蜂鸣器开关 触发延时（有人） 触发延时（无人） 报警开关 最小触发距离 最大触发距离
存在状态 报警条件 指示灯开关 蜂鸣器开关 报警开关 当前距离 光照度 触发延时（有人） 触发延时（无人） 最小触发距离 最大触发距离
智能家居 智慧办公 智能交通 智慧农业 智慧医疗 智慧工厂 智慧健康

门窗传感器 DoorAndWindowSensor
灵敏度 超时未关报警 超时时间 解除防拆报警
门窗状态 灵敏度 超时未关报警 超时时间 防拆报警
智能家居 智慧办公 智能交通 智慧农业 智慧医疗 智慧工厂 智慧健康

单按键开关 SingleKeySwitch
-
按压状态 开关次数 单击触发 双击触发 长按触发
智能家居

落地扇 FloorFan
开关 定时 摆头角度 风速档位 自定义
开关 定时 摆头角度 风速档位 自定义
智能家居

空调 AirConditioningFan
开关 定时 送风角度 风速档位 自定义
开关 定时 送风角度 风速档位 自定义
智能家居

热水壶 Kettle
开关 档位 目标温度 定时 加热时间 自定义
开关 档位 目标温度 当前水温 开始加热时间 结束加热时间 加热时长 当前设备状态 模式
智能家居

智能窗 SmartWindow
开关 开关幅度 定时 开关模式 推力大小
开关 开关幅度 定时 当前开关模式 推力大小 设备状态 设备电量 开始时间点 闭合时间点 开窗时长 是否有雨
智能家居

电暖器 ElectricHeater
开关 档位 目标温度 定时 自定义
开关 档位 目标温度值 开始时间 停止时间 使用时长 当前设备状态 当前温度 自定义
智能家居

显示屏 DevDisplay
开关 文本内容 图片内容 视频内容 亮度值 时间信息 对比度值 刷新率值 分辨率值 自定义
开关 文本内容 图片内容 视频内容 亮度值 时间信息 屏幕开始时间 屏幕结束时间 屏幕使用时长 刷新率值 对比度值 设备电量 分辨率值 自定义
智能家居 智慧办公

吊扇灯 CeilingFanLight
开关 定时 风速档位 灯光亮度 风扇转向 自定义
开关 定时 风速档位 灯光亮度 风扇转向 自定义
智能家居

空气温湿度监测器 AirTemperatureAndHumidityMonitor
开关
开关 温度值 湿度值 设备电量 co2数值 pm2.5数值
智能家居 智慧办公 智能交通 智慧农业

二氧化碳传感器 CarbonDioxideSensor
开关 
二氧化碳浓度值 开关状态 设备电量
智能家居

水浸传感器 WaterImmersionSensor
开关
有无水浸状态 开关状态（开/关） 设备电量
智能家居

点阵屏 DotMatrixScreen
开关 文本内容 图片内容 视频内容 亮度值 时间信息 对比度值 刷新率值 分辨率值
开关 文字内容 图片内容 亮度值  屏幕开始时间 屏幕结束时间 屏幕使用时长 刷新率值 对比度值 设备电量 分辨率值 自定义
智能家居 智慧办公 

电子白板 ElectronicWhiteboard
开关 文本内容 图片内容 视频内容 亮度值 时间信息 对比度值 刷新率值 分辨率值
开关 文字内容 图片内容 亮度值  屏幕开始时间 屏幕结束时间 屏幕使用时长 刷新率值 对比度值 设备电量 分辨率值 自定义
智能家居 智慧办公

红外测温传感器 InfraredTemperatureSensor
开关 侦测距离范围 
开关 温度值 设备电量 物距比 是否有物体 侦测距离范围值
智能家居 智慧办公 智能交通 智慧农业

红外测距传感器 InfraredRangingSensor
开关 侦测距离范围
开关 距离值 设备电量 物距比 是否有物体 侦测距离范围值 当前速度
智能家居 智慧办公 智能交通 智慧农业

虹膜识别器 IrisRecognizer
开关 侦测距离范围
开关 虹膜特征值 设备电量 是否匹配正确 虹膜匹配人信息
智能家居 智慧办公 智能交通 智慧农业

智能摄像头（固定版） SmartCamera(FixedVersion)
开关 拍照开关 录像开关 录音开关 定时 云台转动角度 画面向右旋转 画面向左旋转 画面水平翻转 画面垂直翻转 自定义
开关 图像 视频实况 录像内容 录音内容 定时 存储容量 云台转动角度 设备电量 自定义
智能家居 智慧办公 智能交通 智慧农业

智能毛巾架 SmartTowelRack
开关 档位 时间 自定义
开关 当前设备模式 当前设备档位 当前温度数值
智能家居 

胎压检测仪 TirePressureDetector
开关
轮胎名称 胎温 胎压 漏气速度 开关状态 设备电量 语音信息
智能交通 

可燃气体探测器（家用） CombustibleGasDetector(Household)
开关 气体阈值
开关 报警状态 气体浓度值 阈值报警 故障状态 设备电量 自定义
智能家居 

可燃气体探测器（工业） CombustibleGasDetectors(Industrial)
开关 
开关 报警状态 气体浓度值 气体阈值报警 故障状态 气体类型 自定义
智慧农业 智慧工厂

土壤PH传感器 SoilPhSensor
开关
开关 土壤酸碱性 土壤Ph值 电量
智慧农业

灯（工业） Lamps(Industrial)
开关 亮度档位 色温 定时 光照强度 RGB值 自定义
开关 亮度档位 色温 定时 光照强度 RGB值 自定义
智慧农业 智慧工厂

灯（医疗） Lamp(Medical)
开关 亮度档位 色温 定时 光照强度 升降位置 旋转角度 加热温度 自定义
开关 亮度档位 色温 定时 光照强度 升降位置 旋转角度 加热温度 自定义 
智慧工业 智慧医疗

工业加湿器 IndustrialHumidifier
开关 雾量档位 目标温度值 目标湿度值 定时 自定义
开关 雾量档位 当前温度值 当前湿度值 目标温度值 目标湿度值 功能开始时间 功能结束时间 功能使用时长 滤网寿命 水量值 自定义
智慧工厂

智能喷灌器 SmartSprinkle
开关 定时 出水档位 出水直径 出水流量 出水线条型 出水喷雾型 喷洒角度 喷洒范围 自定义
开关 功能开始时间 功能结束时间 功能使用时长 当前功率 已喷灌水量 出水档位 出水直径 出水流量 喷洒角度 喷洒范围 自定义
智慧工厂 智慧农业

农业大棚卷帘 AgriculturalGreenhouseRollerShutter
卷帘电机开关 卷帘进度 卷帘方向 定时 自定义
卷帘电机开关 目标卷帘进度 当前的卷帘进度 目标卷帘方向 当前的卷帘方向 定时 自定义
智慧工厂 智慧农业

智能窗帘 SmartCurtain
开关 卷帘打开方向 定时 开合比例
开关 卷帘打开方向 定时 开合比例
智能家居

血压计 Sphygmomanometer
开关
血压信息 开关状态 电量
智能家居 智慧健康

空气净化器 AirPurifier
开关 风量档位 净化模式 出风模式 定时时间 空气浓度阈值
开关 当前风量档位 当前净化模式 当前空气质量 当前出风模式 定时信息 滤芯耗材状态
智能家居 

指纹识别器 FingerprintReader
开关 
开关 设备电量 指纹识别是否正确 指纹匹配人
智能家居 智慧办公

毫米波测距测速传感器 MillimeterWaveRangingAndSpeedSensor
开关 设定检测距离
开关 物体距离值 物体速度值 是否有物体 自定义
智能交通

超声波油耗探测器 UltrasonicFuelConsumptionDetector
开关
开关 温度值 液位值 信号强度 自定义
智能交通

路况传感器 RoadSensor
开关
开关 温度值 湿度值 积水厚度 覆冰厚度 积雪厚度 湿滑程度 路面材料
智能交通

地磁传感器 GeomagneticSensor
开关 探测时间间隔 响应时间 温度 探测距离 复位 方向校准
开关 有无车状态 温度值 是否有水覆盖 地磁方向 地磁强度 磁场强度（X轴） 磁场强度（Y轴） 磁场强度（Z轴） 自定义
智能交通

血糖仪 BloodGlucoseMeter
-
开关 是否餐前测量 血糖值 开始测量时间 设备电量
智慧健康 智慧医疗

新风机（家用挂壁式）FreshAirFan(HouseholdWallMounted)
开关 风量档位 定时 自定义
开关 空气类型数据 当前空气质量状态 过滤网更换提醒 当前风量档位 定时信息 自定义
智能家居 智慧医疗

新风机（管道机） FreshAirFan(PipeMachine)
开关 风量档位 定时 自定义
开关 风量档位 定时 功能使用时长 自定义
智慧工厂 智慧办公 智慧医疗

除湿机（家庭版） Dehumidifier(HomeEdition)
开关 档位 目标湿度 湿度阈值设定 定时 衣物除湿模式 夜间模式
开关 当前档位 功率信息 目标湿度 当前湿度 水箱数据 定时 功能使用时长 当前模式
智能家居 智慧办公 智慧医疗

除湿机（工业版） Dehumidifier(IndustrialVersion)
开关 档位 目标湿度 湿度阈值设定 定时 自动模式
开关 当前档位 功率信息 目标湿度 当前湿度 水箱数据 定时 功能使用时长 当前模式
智慧工厂

扫地机器人 SweepingRobot
开关 定时 语音控制 档位 自定义
开关 灰尘数量 定时 清洁使用时长 电量 垃圾存量 清洁水余量 清洁剂余量 自定义
智能家居 智慧办公 智慧医疗

智能门锁 SmartDoorLock
开关 打开门锁 省电模式 正常模式 设定解锁方式
开关 密码是否正确 指纹是否正确 开锁状态 门锁破坏警报 电量 开锁时间记录 开锁次数记录 指纹开锁次数 NFC开锁次数 蓝牙开锁次数 临时开锁次数 人脸识别开锁次数 应急钥匙开锁次数 门口是否有人 门外视频画面
智能家居

风力风速传感器 WindSpeedSensor
开关 测风速
开关 设备电量 当前风速 
智慧工厂 智慧农业

光照强度传感器 LightIntensitySensor
开关
开关 光照强度值 电量 光照阈值报警
智能家居 智慧工厂 智慧农业 智慧办公 智慧交通 智慧医疗
 
人体存在传感器 HumanPresenceSensor
开关 设定距离范围
开关 是否有人 电量 设定的距离值 设定的距离内状态 人数
智能家居 智慧工厂 智慧农业 智慧办公 智慧交通 智慧医疗

人体移动传感器 HumanMotionSensor
开关
开关 是否有人 电量 报警提醒
智能家居 智慧工厂 智慧农业 智慧办公 智慧医疗

高精度人体移动传感器 HighPrecisionHumanMotionSensor
开关 设定灵敏度 设定检测范围
开关 是否有人 设备电量 报警提醒 设定灵敏度值 设定检测范围值 最近的目标距离 光照强度 人存在的时间 人数
智能家居 智慧工厂 智慧农业 智慧办公 智慧医疗

紧急按钮 EmergencyButton
-
按压状态 设备电量
智能家居 智慧工厂 智慧农业 智慧办公 智慧医疗

烟雾传感器 SmokeSensor
开关 烟雾报警阈值 报警状态
开关 烟雾值 烟雾报警阈值 报警状态 设备电量 
智能家居 智慧工厂 智慧农业 智慧办公 智慧医疗

双按键开关 DoubleKeySwitch
-
键位名 按压状态 开关次数 单击触发 双击触发 长按触发
智能家居 智慧工厂 智慧农业 智慧办公 智慧医疗

三按键开关 ThreeButtonSwitch
-
键位名 按压状态 开关次数 单击触发 双击触发 长按触发
智能家居 智慧工厂 智慧农业 智慧办公 智慧医疗

四按键开关 FourButtonSwitch
-
键位名 按压状态 开关次数 单击触发 双击触发 长按触发
智能家居 智慧工厂 智慧农业 智慧办公 智慧医疗

多按键开关 MultiButtonSwitch
-
键位名 按压状态 开关次数 单击触发 双击触发 长按触发
智能家居 智慧工厂 智慧农业 智慧办公 智慧医疗

单开单控翘板开关 SingleOpenSingleControlRockerSwitch
-
常开状态 常闭状态 常开次数 常闭次数 开和关切换次数
智能家居 智慧工厂 智慧农业 智慧办公 智慧医疗

双开单控翘板开关 DoubleOpenSingleControlRockerSwitch
-
开关键位名 常开状态 常闭状态 常开次数 常闭次数 开和关切换次数
智能家居 智慧工厂 智慧农业 智慧办公 智慧医疗

三开单控翘板开关 ThreeWaySingleControlRockerSwitch
-
开关键位名 常开状态 常闭状态 常开次数 常闭次数 开和关切换次数
智能家居 智慧工厂 智慧农业 智慧办公 智慧医疗

四开单控翘板开关 FourOpenSingleControlRockerSwitch
-
开关键位名 常开状态 常闭状态 常开次数 常闭次数 开和关切换次数
智能家居 智慧工厂 智慧农业 智慧办公 智慧医疗

无线开关 WirelessSwitch
-
开关连接状态 开关状态 打开次数 关闭次数 设备电量
智能家居 智慧工厂 智慧农业 智慧办公 智慧医疗

声控开关 VoiceSwitch
-
是否有声音 开关状态 打开次数 关闭次数
智能家居 智慧工厂 智慧农业 智慧办公 智慧医疗

无极旋钮开关 PoleKnobSwitch
旋钮的扭矩 打开次数 关闭次数
智能家居 智慧工厂 智慧农业 智慧办公 智慧医疗

睡眠检测仪 SleepDetector
开关
开关 在床状态 睡眠状态 设备电量 开始睡眠时间 结束睡眠时间 睡眠时长
智能家居 智慧医疗 智慧健康

蓝牙音箱 BluetoothSpeaker
开关 音量 播放控制 播放切换 播放模式 蓝牙开关 麦克风开关 音频源选择 自定义
开关 音量 当前的控制状态 当前的播放模式 蓝牙开关状态 麦克风状态 电量 自定义
智能家居 智慧办公 智慧医疗

RGB颜色传感器 RgbColourSensor
开关 复位 灵敏度值 
开关 颜色值（RGB） 设备电量 设定的灵敏度值 复位次数
智能家居 智慧工厂 智慧办公 

家用体重秤 HomeWeightScale
-
开关 重量值 设备电量 体脂率
智能家居 智慧办公 智慧医疗

工业重量传感器 IndustrialWeightSensor
开关 复位 量程选择
开关状态 重量值 
智慧工厂 智慧农业 

车载测速仪 OnboardTachometer
开关 限速值 显示屏亮度 静音 抓拍 上传
开关 实时车速 雷达信号状态 设定的限速值 超速状态提醒 静音状态 显示屏亮度值 设备电量 地理位置 抓拍图像 抓拍次数 抓拍的时间点
智慧交通

雨水传感器 RainSensor
开关 设定雨量阈值
开关 是否有雨 是否达到阈值 设置的雨量阈值 雨水量 降雨等级
智能家居 智慧工厂 智慧农业 智慧办公 智慧医疗 智慧交通

智能空调 SmartAirConditioner
开关 设置温度 指示灯开关 选择模式 风速控制 定时功能 风向控制 扫风状态 自定义
开关 设置的温度 当前温度 当前湿度 指示灯开关状态 选择的模式 当前模式 当前的风速 选择的风速 定时 当前的风向 选择的风向 当前扫风状态 选择的扫风状态 当前功率 空调匹数 空调制冷量 自定义
智能家居 智慧工厂 智慧农业 智慧办公 智慧医疗

空气质量与环境检测仪 AirQualityAndEnvironmentalDetector
开关 
开关 温度值 湿度值 PM2.5的等级 PM2.5的数值  PM10的等级 PM10的数值  甲醛的等级 甲醛的数值  CO2的等级 CO2的数值 空气质量指数 设备电量 
智能家居 智慧工厂 智慧农业 智慧办公 智慧医疗

智能可视门铃 SmartViewableDoorbell
开关 拍照功能开关 实时视频开关 实时音频开关 录像功能开关 录音功能开关 自定义
开关 图片 实时视频 视频录像 实时音频 音频片段 人脸数据 是否有人 是否有物体移动
拍摄图片时间 拍摄录像时间 录制音频时间 自定义
智能家居 智慧工厂 智慧农业 智慧办公 智慧医疗

智能插座 SmartPlug
开关 定时 语音控制
开关 当前功率 电量统计 定时 开关开启时间 开关关闭时间 使用时长
智能家居 智慧工厂 智慧农业 智慧办公 智慧医疗

智能分控插排 IntelligentSplitControlInterpolation
开关 分控口名 分控口开关 分控口输出功率调节 分控口定时 语音控制
开关 分控口名 分控口开关 分控口输出功率 分控口电量统计 总电量统计 分控口开启时间 分控口关闭时间 分控口使用时长
智能家居 智慧工厂 智慧农业 智慧办公 智慧医疗

抽油烟机 RangeHood
开关 设定风力档位 照明开关 延时关机时长 自定义
开关 当前档位风力 当前烹饪区温度 当前风道的风压 照明开关状态 滤网清洁度 风机清洁度 延时关机时长 当前模式
智能厨卫

手机 CellPhone
发送短信 手机振动 拨打电话 发送文字消息（冒泡）发送音视频消息（冒泡） 设定闹钟
计步器数 环境音量 陀螺仪摇晃 GPS位置信息 冒泡消息 手机电量 设备模式 闹钟设置信息
智能家居 智慧办公 智能交通 智慧农业 智慧医疗 智慧工厂 智慧健康

智能宠物饮水机 SmartPetWaterDispenser
开关 开始出水 停止出水 自定义
开关 出水状态 剩余水量 滤芯寿命 当前模式
智慧养宠

智能宠物净味器 SmartPetOdorPurifier
开关 定时 净味开关 自定义
开关 当前净味模式 净味开关状态 检测是否有宠物 定时 电量 滤芯寿命
智慧养宠

智能宠物窝 SmartPetBed
开关 设定灯光强度 设定温度 定时 
开关 灯光强度值 窝内温度值 窝内湿度值 宠物是否在窝 定时信息
智慧养宠

智能宠物喂食器 SmartPetFeeder
开关 加餐开关 设定喂食量 定时 
开关 电量 余粮 干燥剂余量 喂食量 喂食时间点 输出定时信息 设定喂食量信息
智慧养宠

智能猫砂机 IntelligentCatLitterMachine
开关 定时 一键清仓 自动铲屎 除臭 
开关 干净猫砂重量 集便仓重量 如厕次数 如厕时长 感应是否有猫 自动铲屎开关状态 铲屎次数 除臭开关状态 除臭次数 定时信息
智慧养宠

智能鱼缸喂食器 SmartFishTankFeeder
开关 定时 设定投食量 开始投料
开关 电量 定时信息 投食量设定值 投食时间戳 是否投食 饲料余量
智慧养宠

智能鱼缸 SmartFishTank
开关 灯光颜色 灯光强度 灯光模式（*） 定时 开始投料 设定投饲量 一键换水 设定水泵出水量 设定水温 除菌开关
开关 定时 当前灯光颜色 当前灯光强度 当前灯光模式 喂食时间 投料开关 喂食次数 换水时间 换水开关 水泵出水量 水泵调节次数 当前水温 设定的目标水温
智慧养宠 智能家居

鱼缸灯 FishTankLights
开关 定时 设置灯光RGB值 设置灯光亮度值 设置灯光模式
开关 定时信息 当前灯光RGB值 当前灯光亮度值 当前灯光模式
智慧养宠 智能家居

智能电饭锅 SmartRiceCooker
开关 定时开关 定时开始时间 设定预约工作时长 设定锅内温度 自定义模式 保温开关 保温时长 
开关 定时信息 功能开始时间 功能结束时间 已工作时长 工作剩余时长 当前锅内温度 当前模式状态 当前工作状态
智能厨卫

智能热水器（燃气） SmartWaterHeater(gas)
开关 水温设置 零冷水开关 零冷水模式
开关 开关开启时间 开关关闭时间 当前温度 当前设备模式 当前水压 零冷水开关状态 单位时间用水量 单位时间用电量 
智能厨卫

智能热水器（电）SmartWaterHeater(electric) 
开关 定时开关 定时开始时间 温度 自定义模式 设置出水断电开关
开关 温度 当前剩余水量 当前模式 当前功率 是否有水流 当前水质（不良/中等/优良）/ 单位时间用水量 单位时间用电量 运行状态 已工作时长 剩余工作时长 出水断电状态 故障报警
智能厨卫 智能卫浴

烤箱 Oven
开关 定时 设定上管温度 设定下管温度 烘焙灯开关 自定义模式 
开关 上管温度 下管温度 箱内温度 灯光开关状态 当前设备模式 已工作时长 剩余工作时长 
智能厨卫 

电热毯 ElectricBlanket
开关 左侧开关 右侧开关 设定左边温度 设定右边温度 定时开关 定时开始时间 自定义模式
开关 左侧开关状态 右侧开关状态 左边温度 右边温度 当前设备模式 已工作时长 剩余工作时长 定时信息
智能家居

香薰机 AromatherapyMachine
开关 喷雾开关 静音开关 定时开关 定时开始时间 设定模式 设定亮度值 设定档位
开关 喷雾状态 剩余时间 当前模式状态 亮度值 档位状态 
智能家居

浴室镜 BathroomMirror
开关 除雾开关 设定工作模式 设定亮度值 设定灯光颜色
开关 除雾状态 当前模式状态 亮度值
智能卫浴

智能打卡机 SmartCardMachine
开关 人脸打卡 指纹打卡 密码打卡 IC卡打卡
开关 当前打卡模式 打卡状态 防拆报警状态 门磁开关状态 故障状态 是否有人
智慧办公

智能投影仪 IntelligentProjector
开关 静音开关 设定焦距 设定音量 设定亮度 设定颜色模式 设定接入信号源 设定显示文本 设定显示图片 设定显示视频 自定义模式
开关 静音开关 调焦数值 亮度显示 颜色模式显示 音量 自定义模式
智慧办公 智能家居

智能排风机 IntelligentExhaustFan
开关 设定风档位 定时时间
开关 当前档位 定时时间
智慧办公 智能家居

智能水杯 SmartCup
开关 设定音量
开关 当前档位 定时时间
智慧办公 智能家居

智能书桌 SmartDesk
开关 设置升降开关 设置升降高度 设置桌面倾斜角度
开关 升降开关状态 升降高度值 桌面倾斜角度
智慧办公 智能家居

智能饮水机 SmartWaterDispenser
开关 热水开关 冷水开关 设定制热目标温度 设定制冷目标温度 设定出水量 模式
开关 热水当前温度 冷水当前温度 热水目标温度 冷水目标温度 水桶剩余水量 故障状态 单位时间用水量 水质（TDS） 滤芯寿命
智慧办公 智能家居 智慧医疗 

智能按摩椅 SmartMassageChair
开关 自动模式开关 手动模式开关 椅背倾斜角度 腿部角度 按摩球位置 按摩速度 加热开关 设置按摩时间 灯开关 按摩椅复位开关
开关 自动模式开关 手动模式开关 椅背倾斜角度 腿部角度 按摩球位置 按摩速度 加热开关状态 按摩时长 倒计时剩余时间 灯开关状态 按摩椅复位开关
智慧办公 智能家居

智能蒸蛋器 SmartEggSteamer
开关 定时
开关 定时
智能家居 智能厨卫

晾衣架 DryingRack
开关 控制上升开关 控制下降开关 控制停止开关 照明开关 杀菌模式开关 设定杀菌时长 烘干模式开关 设定烘干时长 风干模式开关 设定风干时长 
开关 上升开关状态 下降开关状态 停止开关状态 当前位置 杀菌工作时间 杀菌剩余时间 烘干工作时间 烘干剩余时间 风干工作时间 风干剩余时间 照明状态
智能家居

浴霸 BathroomMaster
开关 设定工作时长 预约开关 预约时长 设定暖房模式 开启暖光 开启白光 开启自然光 风机档位 摆风角度 场景模式 照明开关 暖房开关 换气开关 摆风开关 杀菌开关 干燥开关 香薰开关 异味感应开关 设定亮度值
开关 工作状态 工作时长 剩余时长 室内温度 室内湿度 暖房模式 照明模式 风机档位 摆风角度 场景模式 照明开关状态 暖房开关状态 换气开关状态 摆风开关状态 杀菌开关状态 干燥开关状态 香薰开关状态 异味感应开关状态 当前亮度值 
智能卫浴

净水设备 WaterPurificationEquipment
开关 净水开关 设定储水量 温水模式 冷水模式 沸水模式 制水优先模式 节水优先模式 耗材重置/复位 儿童锁开关
开关 净水开关 出水流速 实际出水量 出水温度 净水前水质 自来水TDS值 净化后水质 净水后TDS值 当前工作状态 当前冲洗模式 当前温度模式 滤芯耗材剩余使用时间 儿童锁状态
智能家居 智能厨卫

炒菜机 CookingMachine
开关 启动开关 继续开关 暂停开关 取消开关 不搅拌开关 低转速开关 高转速开关 工作模式 小火开关 中火开关 大火开关 工作时长设置 预约开关 预约时长
开关 工作状态 当前压力 当前温度 工作时长 剩余时长 当前火力 当前搅拌模式 当前工作模式
智能厨卫

养生壶 HealthPot
开关 启动开关 继续开关 暂停开关 取消开关 保温开关 保温时间 设定保温温度 预约开关 预约开始时间 设定目标温度 设定加热时长 设定火力 设定模式
开关 工作状态 当前温度 目标温度 制作时长 剩余时长 当前模式 当前火力
智能家居 智慧健康

燃气灶 GasStoves
开关 档位 定时 
开关 档位 定时 自定义
智能厨卫

电磁炉 InductionCooker
开关 定时 设定输出功率 选择烹饪模式 自定义
开关 定时 输出功率 当前烹饪模式 自定义
智能厨卫

智能冰箱 SmartRefrigerator
开关 设定冷藏柜温度 设定冷冻柜温度 设定冷藏湿度 设定冷冻湿度 设定制冷档位 冰箱灯开关 风速档位 定时
开关 冰箱门开关状态 冷藏柜温度 冷冻柜温度 冷藏湿度 冷冻湿度 制冷档位 冰箱灯开关 风速档位 定时
智能厨卫

洗碗机 Dishwasher
开关 定时 设定清洗模式 清洗开关
开关 定时 当前清洗模式 清洗开关 剩余时间 是否清洗完成 滤网是否需要清洁 水箱水量 洗涤剂余量 洗碗水水质(清洁质量) 自定义
智能厨卫

电子秤（厨房） ElectronicScale(kitchen)
开关 设定计量单位 去皮 自定义
开关 计量单位 重量 自定义 
智能厨卫

微波炉 MicroWaveOven
开关 设定加热时长 设定档位 解冻模式 煮饭模式 快加热模式
开关 加热时长 剩余加热时长 当前档位 当前模式 
智能厨卫

豆浆机 SoymilkMachine
开关 定时 设定模式 启动研磨开关 研磨档位 自定义 
开关 预约时间 定时 研磨开关 研磨档位 剩余工作时长 当前模式
智能厨卫

家用电火锅 HouseholdElectricHotPot
开关 加热档位 恒温模式 定时
开关 加热档位 当前是否恒温模式 定时 当前温度 
智能厨卫

洗衣机 WashingMachine
开关 速洗模式 速洗模式 混合模式 单脱水模式 漂洗模式 脱水模式 羊毛模式 高温消毒模式 启动洗衣开关 定时 设定水量 设定水温 设定洗衣时长
开关 当前模式 洗衣状态 洗衣剩余时间 单次用水量 单次用电量 当前水位量 当前水温 当前洗衣时长
智能家居

智能马桶 SmartToilet
开关 设定水压 设定臀部清洗模式 设定女性清洗模式 设定律动清洗模式 设定烘干档位 自动翻盖开关 设定座圈温度 设定水温
开关 当前清洗模式 当前烘干档位 当前自动翻盖开关状态 当前座圈温度 当前水温 当前水压 单次用水量 使用次数
智能卫浴

舵机 SteeringGear 
旋转方向 旋转角度
旋转方向 旋转角度
智慧教育 开发板套件

马达小车 Motor
开关 旋转方向 旋转速度
开关 旋转方向 旋转角度
智慧教育 开发板套件

雨水传感器 DevRainSensor 
-
雨水状态检测 降水量档位
智慧教育 开发板套件

低压水泵 LowPressurePump 
开关 出水量档位
开关 出水量档位 
智慧教育 开发板套件

磁场传感器 MagneticFieldSensor 
-
磁场状态
智慧教育 开发板套件

多彩灯珠 RgbLight 
开关 亮度值 亮闪频率 颜色RGB值 
开关 亮度值 亮闪频率 颜色RGB 亮闪的总次数
智慧教育 开发板套件

麦克风与喇叭 MicrophoneAndSpeaker 
开关 音量 对讲开关 录音开关 播放开关 
开关 音量 对讲状态 录音状态 播放状态
智慧教育 开发板套件

游戏操纵杆 GameJoystick 
按下状态 按压模式
按下状态 按压模式 X轴数值 Y轴数值
智慧教育 开发板套件

陀螺仪 ImuGyroscope 
-
偏航角度 俯仰角度 翻滚角度 X轴加速度 Y轴加速度 Z轴加速度 X轴角速度 Y轴角速度 Z轴角速度 
智慧教育 开发板套件

手势识别 GestureSensor 
-
手势的移动方向 手势的旋转方向 手势的摇摆状态 
智慧教育 开发板套件

屏幕 Display
文字字号 图片的信息 文字颜色 字间距 行间距 动图 文字开始位置 
显示文本 文字颜色 显示动图 显示视频
智慧教育 开发板套件

环境传感器 EnvironmentSensor 
-
环境温度 环境湿度 光照强度 一氧化碳存在状态 烟雾存在状态 空气被污染的状态
智慧教育 开发板套件

RFID读写器 RfidReader
-
id串 卡的物理区块的信息内容
智慧教育 开发板套件

人体感应开关 HumanThermalSensor 
-
热源物体的存在状态
智慧教育 开发板套件

条码扫描 CodeScanner 
扫描条形码与二维码开关
读取条码内容
智慧教育 开发板套件

多功能按键 ButtonPanel 
按键模式
获取按键信息 按键值 按键模式
智慧教育 开发板套件

摄像头 Camera
拍照 上传图片 摄像状态（未上线） 视频的分辨率
拍照 上传图片 摄像状态（未上线） 视频的分辨率
智慧教育 开发板套件

旋转编码器 Knob 
按钮的状态 编码器旋转方向 编码器的档位
按钮的状态 编码器旋转方向 编码器的档位 
智慧教育 开发板套件

心率血氧检测仪 HeartRateMonitor 
开关 
开关 心率值 血氧浓度
智慧教育 开发板套件
`