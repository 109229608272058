import ReactDOM from "react-dom/client";
import {Alert} from "@mui/material";
import {useState} from "react";

export default class MuiAlert {
  static DURATION = 2000;

  static setMessage = (message: string) => {}
  static setType = (type: string) => {}
  static setShow = (show: boolean) => {}

  static message(message: string, type: string, duration: number = MuiAlert.DURATION) {
    this.setMessage(message);
    this.setType(type);
    this.setShow(true);
    setTimeout(() => {
      this.setShow(false);
    }, duration);
  }

  static success(message: string, duration?: number) {
    this.message(message, "success", duration);
  }

  static error(message: string, duration?: number) {
    this.message(message, "error", duration);
  }

  static info(message: string, duration?: number) {
    this.message(message, "info", duration);
  }

  static warning(message: string, duration?: number) {
    this.message(message, "warning", duration);
  }
}
