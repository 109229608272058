import Block, {CompilerFunctionOption} from "../../block/block.class";
import Arg, {ArgType, FieldType} from "../../block/arg.class";
import {RhineBlock} from "../../RhineBlock";
import {addCodeIndent} from "./compiler-utils";
import VariableProvider from "./variable-provider";

export enum DefaultOrder {
  STATEMENT = -2,       // Statement block.
  UNKNOWN = -1,         // Default to add () around. Most safety.
  DIRECT,          // 0 "" ... . Not safe to add () around.
  BASE,             // new . [] ()
}

// 基本编译器
export default class BaseCompiler {
  public static VariableProvider: typeof VariableProvider = VariableProvider; // 变量管理器

  public static Order: any = DefaultOrder; // 操作符优先级表

  public static LINE_END = "\n"; // 行末符
  public static INTERVAL = "\n"; // 分割符
  public static INDENT = 2; // 缩进数
  public static DEFAULT_VALUE_CODE = "/* Default Value */"; // 默认值块代码
  public static DEFAULT_STATEMENT_CODE = "// Default Statement\n"; // 默认语句块代码

  // 执行编译
  public static compile(blocks: Block[]): string {
    // console.log('compile', blocks)
    // 清空变量管理器
    this.VariableProvider.clear();

    // 默认代码
    let code = "\n// LogicBoard Code\n\n\n";
    // 未连接的图形块分组按坐标排序
    blocks.sort((a, b) => {
      if (a.y && b.y) {
        return a.y - b.y;
      } else {
        return 1;
      }
    })
    // 分区编译
    blocks.forEach(block => {
      if(block.isDisable) return;
      if(!block.name.startsWith('event_')) {
        code += '// Not Used Blocks\n'
      }
      code += this.compileBlock(block)
      if (block.isOutputType()) code += '\n'
      code += this.INTERVAL
    });

    return code
  }

  // 编译图形块
  public static compileBlock(block: Block): string {
    // 为所有参数优先生成代码
    const args: string[] = [];
    block.mapValueArgs((arg, id, i, j) => {
      if (arg === block.next) return;
      let code = this.compileArg(arg, block.level);
      // 语句类块默认增加一层缩进
      if (arg.type === ArgType.Statement) {
        code = addCodeIndent(code);
      }
      args.push(code);
    });

    let code = this.useCompiler(args, block);
    if (!block.isOutputType() && block.hadNext() && block.next.content) {
      code += this.compileArg(block.next);
    }
    return code
  }

  // 编译图形块中的指定参数
  public static compileArg(arg: Arg, level: number = this.Order.UNKNOWN): string {
    if (arg.type === ArgType.Field) {
      return this.compileField(arg);
    } else if (arg.type === ArgType.Value || arg.type === ArgType.Statement) {
      if (arg.content && !(arg.content  as Block).isDisable) {
        const block = arg.content as Block;
        const code = this.compileBlock(block);
        if(arg.type === ArgType.Statement) {
          return code;
        }
        if (
          level > block.level &&
          level !== this.Order.DIRECT &&
          level !== this.Order.UNKNOWN &&
          block.level !== this.Order.DIRECT
        ) {
          return "(" + code + ")";
        }
        return code;
      } else if (arg.type === ArgType.Value) {
        return this.DEFAULT_VALUE_CODE;
      } else {
        return this.DEFAULT_STATEMENT_CODE;
      }
    } else {
      return "/* " + arg.text + " */";
    }
  }

  // 编译填充内容
  public static compileField(arg: Arg): string {
    if(arg.type === ArgType.Field) {
      if([FieldType.Text, FieldType.Number, FieldType.Password].includes(arg.fieldType)) {
        return arg.content as string;
      }else if(arg.fieldType === FieldType.Checkbox) {
        return arg.content ? "true" : "false";
      }else if(arg.fieldType === FieldType.Dropdown) {
        const i = arg.content as number;
        if (arg.direct) {
          return arg.option[i].toString()
        } else if(arg.optionCode && arg.optionCode.length>i) {
          return arg.optionCode[i]
        } else {
          return i.toString()
        }
      }
    }
    return "/* Field " + arg.fieldType + " */";
  }

  // 使用图像块中编译器
  public static useCompiler(args: string[], block: Block): string {
    const compiler = block.compiler;
    // 编译当前块代码
    if (compiler) {
      if (typeof compiler === "function") {
        // 函数编译器
        return compiler(args, {});
      } else {
        // 通过表达式编译
        return this.useExpression(args, compiler);
      }
    }
    // 无编译器返回默认代码
    return this.getDefaultCode(block);
  }

  // 使用表达式生成
  public static useExpression(args: string[], expression: string): string {
    let code = expression;
    args.forEach((arg, i) => {
      code = code.replace("$" + i, arg);
    });
    // TODO: 需要考虑极端数字组合情况，转义符无效
    return code;
  }

  // 获取图形块默认代码
  public static getDefaultCode(block: Block, prefix: string = ""): string {
    if (block.isOutputType()) {
      return "/* Block " + block.name + " */";
    } else {
      return "// Block " + block.name + this.LINE_END;
    }
  }
}

// 编译器方法接口
export type ICompilerFunc = (args: string[], option: CompilerFunctionOption) => string;
// 编译器接口
export type ICompiler = ICompilerFunc | string | undefined;

// 编译结果接口
export interface CompilerResult {
  code: string;
  level?: number | typeof RhineBlock.Compiler.Order;
  option?: any;
}




