import Style from "./EnterNew.module.css";
import React from "react";

function EnterNew(props) {
  return (
    <div className={Style.EnterNew} onClick={props.onClick}>
      <div className={Style.imgHolder}>
        <div className={Style.img} hidden={props.new}>
          <img className={Style.imgInner} src={props.img} alt={""} />
          <p className={Style.shadow} />
        </div>
        <div className={Style.imgNew} hidden={!props.new}>
          <svg
            width="73.000000"
            height="73.000000"
            viewBox="0 0 73 73"
            fill="none"
          >
            <rect
              y="33.500000"
              rx="3.000000"
              width="73.000000"
              height="6.000000"
            />
            <rect
              x="39.500000"
              rx="3.000000"
              width="73.000000"
              height="6.000000"
              transform="rotate(90 39.500000 0.000000)"
            />
            <defs />
          </svg>
        </div>
        <div className={Style.state} hidden={props.new}>
          <span className={Style.stateText}>模板</span>
        </div>
      </div>
      <div className={Style.text}>{props.text}</div>
    </div>
  );
}

export default EnterNew;
