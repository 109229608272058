import { BlockType, IBlock } from "../core/block/block.class";
import { RhineBlock } from "../core/RhineBlock";
import { FieldType } from "../core/block/arg.class";
import { ALL_PORTS, IN_PORTS, OUT_PORTS, PORT_TYPES } from "./static-value";

// Pollute the global namespace:
// PDS: Pending Block State
// PDO: Pending Block Operation

const equipmentBlocks: IBlock[] = [
  {
    name: "equipment_state",
    type: BlockType.Output,
    output: "bool",
    color: "#9570ef",
    lines: [
      [
        { text: "接口" },//修改设备为接口
        {
          field: FieldType.Dropdown,
          option: IN_PORTS(),
          direct: true,
        },
        { text: "为" },
        { field: FieldType.Dropdown, option: ["开", "关"], default: 0 },
        { text: " " },
      ],
    ],
    compiler: "PDS('BOOLEAN', '$0', $1)"
  },
  // 新增接口A1为有或无
  {
    name: "equipment_interface_exist",
    type: BlockType.Output,
    output: "bool",
    color: "#9570ef",
    lines: [
      [
        { text: "接口" },//修改设备为接口
        {
          field: FieldType.Dropdown,
          option: IN_PORTS(),
          direct: true,
        },
        { text: "为" },
        { field: FieldType.Dropdown, option: ["有", "无"], default: 0 },
        // 下面的text撑起右侧的样式空间
        { text: " " },
      ],
    ],
    compiler: "PDS('BOOLEAN_HAD', '$0', $1)"
  },
  {
    name: "equipment_control",
    type: BlockType.Statement,
    color: "#9570ef",
    lines: [
      [
        { text: "设置接口" },
        {
          field: FieldType.Dropdown,
          option: OUT_PORTS(),
          direct: true,
        },
        { text: "为" },
        { field: FieldType.Dropdown, option: ["开启", "关闭"], default: 0 },
        // {value: "bool",}
      ],
    ],
    compiler: "PDO('CONTROL', '$0', $1)\n",
  },

  {
    name: "equipment_control_rotation",
    type: BlockType.Statement,
    color: "#9570ef",
    lines: [
      [
        { text: "设置接口" },
        {
          field: FieldType.Dropdown,
          option: OUT_PORTS(),
          direct: true,
        },
        { text: "旋转方向为" },
        { field: FieldType.Dropdown, option: ["顺时针", "逆时针"], default: 0 },
      ],
    ],
    compiler: "PDO('CONTROL_ROTATION', '$0', $1)\n",
  },
  // 设置B1旋转角度
  {
    name: "equipment_rotation_value",
    type: BlockType.Statement,
    color: "#9570ef",
    lines: [
      [
        { text: "设置" },
        {
          field: FieldType.Dropdown,
          option: OUT_PORTS(),
          direct: true,
        },
        { text: "(" },
        { field: FieldType.Dropdown, option: ["顺时针", "逆时针"], default: 0 },
        { text: ")" },
        { field: FieldType.Number, default: "0" },
        { text: "度" },
      ],
    ],
    compiler: "PDO('ROTATION_VALUE', '$0', $1, $2)\n",
  },
  {
    name: "equipment_rotation_turns_number",
    type: BlockType.Statement,
    color: "#9570ef",
    lines: [
      [
        { text: "设置" },
        {
          field: FieldType.Dropdown,
          option: OUT_PORTS(),
          direct: true,
        },
        { text: "(" },
        { field: FieldType.Dropdown, option: ["顺时针", "逆时针"], default: 0 },
        { text: ")" },
        { field: FieldType.Number, default: "0" },
        { text: "圈" },
      ],
    ],
    compiler: "PDO('ROTATION_TURNS_NUMBER', '$0', $1, $2)\n",
  },
  {
    name: "equipment_get_value",
    type: BlockType.Output,
    color: "#9570ef",
    lines: [
      [
        {
          field: FieldType.Dropdown,
          option: IN_PORTS(),
          direct: true,
        },
        { text: "的" },
        {
          field: FieldType.Dropdown,
          option: PORT_TYPES(),
        },
        { text: " " },
      ],
    ],
    compiler: "PDS('VALUE', '$0', $1)",
  },
  {
    name: "equipment_compare_value",
    type: BlockType.Output,
    output: "Boolean",
    color: "#9570ef",
    lines: [
      [
        { text: "" },
        {
          field: FieldType.Dropdown,
          option: IN_PORTS(),
          direct: true,
        },
        { text: "的数值" },
        { field: FieldType.Dropdown, option: [">", "<", "=", "≠"], default: 0 },
        { field: FieldType.Number, default: "10" },
        { text: "  " },
      ],
    ],
    compiler: "PDS('COMPARE', '$0', $1, $2)",
  },
  {
    name: "equipment_compare_text",
    type: BlockType.Output,
    output: "Boolean",
    color: "#9570ef",
    lines: [
      [
        { text: "" },
        {
          field: FieldType.Dropdown,
          option: IN_PORTS(),
          direct: true,
        },
        { text: "的文本" },
        { field: FieldType.Dropdown, option: ["等于", "包含", "属于"] },
        { field: FieldType.Text, default: "Hello" },
        { text: "  " },
      ],
    ],
    compiler: "PDS('COMPARE_TEXT', '$0', $1, $2)",
  },
  {
    name: "equipment_set_value",
    type: BlockType.Statement,
    color: "#9570ef",
    lines: [
      [
        { text: "设置" },
        {
          field: FieldType.Dropdown,
          option: OUT_PORTS(),
          direct: true,
        },
        { text: "的" },
        {
          field: FieldType.Dropdown,
          option: PORT_TYPES(),
        },
        { text: "为" },
        { value: "num" },
      ],
    ],
    toolbox: [0, { next: true, block: "number_value" }],
    compiler: "PDO('SET_VALUE', '$0', $1, $2)\n",
  },
];

RhineBlock.registerBlocksData(equipmentBlocks);

export default equipmentBlocks;
