/**
 * 注册各种新增scratch逻辑块
 */

import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material";
import lightTheme from "../../../LightTheme";
import Style from "./BlockToolbox.module.css";
import { RhineBlock } from "../../../../RhineBlock/core/RhineBlock";
import MellowRender from "../../../../RhineBlock/core/render/mellow/mellow-render";
import renderFlyout from "../../../../RhineBlock/core/view/flyout/flyout";
import {useEffect, useRef} from "react";
import JsCompiler from "../../../../RhineBlock/core/compiler/js/js-compiler";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function BlockToolbox() {
  const blocks = [
    [ // 事件
      "event_start",
      "event_change",
      "event_equip_state",
      "event_equip_exist_status",
      "event_compare",
      "event_compare_text",
    ], [ // 控制
      "logic_if",
      "logic_if_else",

      "loop_times",
      "loop_conditions",
      "loop_break",
      "loop_single",
      "console_sleep",
      "console_output",
      "console_input",
    ], [ // 运算
      "number_basiccal",
      "number_compare",
      "text_connect",
      "text_contain",
      "logic_gate",
      "logic_not",
      "temp_date_in_range",
      "number_remainder",
      "number_round",
      "number_seniorcal",
      "number_trifunc",
      "text_length",
      "text_isempty",
      "text_case",
    ], [ // 元件
      "number_value",
      "text_input",
      "text_json",
      "temp_range",
      "logic_state",
      "number_constant",
      "number_randomfloat",
      "temp_now_time",
      "temp_get_now_time",
      "temp_week_time",
      "temp_time",
      "temp_date",
    ], [ // 设备
      "equipment_state",
      "equipment_interface_exist",
      "equipment_control_rotation",
      "equipment_rotation_value",
      "equipment_rotation_turns_number",
      "equipment_control",
      "equipment_get_value",
      "equipment_set_value",
      "equipment_compare_value",
      "equipment_compare_text",
    ],[ // 服务
      "service_media_set_url",
      "service_weather_get",
      "service_weather_get_now",
      "service_weather_get_air",
      "service_weather_get_sun",
    ]
  ];
  const ref = React.useRef();

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (ref.current) {
      renderFlyout(ref.current, [blocks[newValue]]);
    }
  };

  const blockTabs = useRef();
  useEffect(() => {
    if (!window.blockInited) {
      RhineBlock.registerRender(MellowRender);
      RhineBlock.registerCompiler(JsCompiler);
      if (ref.current) {
        renderFlyout(ref.current, [blocks[value]]);
      }
      window.blockInited = true;
    }
    const tabsDiv = blockTabs.current;
    const scrollBtns = tabsDiv.getElementsByClassName('MuiTabs-scrollButtons');
    for (const scrollBtn of scrollBtns) {
      scrollBtn.style.width = '30px';
      scrollBtn.style.opacity = '0.5';
    }
  });

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      style: {
        padding: 0,
        minWidth: 60,
        minHeight: 35,
        lineHeight: "40px",
        color: index === value ? "#42AAFF" : "#999999",
      },
    };
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <Box className={Style.holder} sx={{ width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            borderBottom: "1px solid #dddddd",
            height: "34px",
            overflowY: "visible",
          }}
        >
          <Tabs
            ref={blockTabs}
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label="事件" {...a11yProps(0)} />
            <Tab label="控制" {...a11yProps(1)} />
            <Tab label="运算" {...a11yProps(2)} />
            <Tab label="元件" {...a11yProps(3)} />
            <Tab label="设备" {...a11yProps(4)} />
            <Tab label="服务" {...a11yProps(5)} />
          </Tabs>
        </Box>
        <div
          className={Style.toolbox}
          onScroll={(e) => {
            RhineBlock.onRemoveFocus(e);
          }}
        >
          <div className={Style.toolboxInner}>
            <div ref={ref} />
            <span>——— 工具栏底部 ———</span>
          </div>
        </div>
      </Box>
    </ThemeProvider>
  );
}
