import { BlockType, IBlock } from "../core/block/block.class";
import { RhineBlock } from "../core/RhineBlock";
import { FieldType } from "../core/block/arg.class";
import { IN_PORTS } from "./static-value";

const eventBlocks: IBlock[] = [
  {
    name: "event_start",
    type: BlockType.Hat,
    color: "#5399ff",
    lines: [[{ text: "当程序开始运行" }]],
    compiler: "// @EVENT START\n",
  },
  {
    name: "event_change",
    type: BlockType.Hat,
    color: "#5399ff",
    lines: [
      [
        { text: "当" },
        {
          field: FieldType.Dropdown,
          option: IN_PORTS(),
          direct: true,
        },
        { text: "内容变化时" },
      ],
    ],
    compiler: "// @EVENT CHANGE $0\n",
  },
  {
    name: "event_equip_state",
    type: BlockType.Hat,
    color: "#5399ff",
    lines: [
      [
        { text: "当开关" },
        {
          field: FieldType.Dropdown,
          option: IN_PORTS(),
          direct: true,
        },
        { text: "为" },
        { field: FieldType.Dropdown, option: ["开启", "关闭"] },
        { text: "时" },
      ],
    ],
    compiler: "// @EVENT EQUIP_STATE $0 $1\n",
  },
  // 新增：当A1状态为有或无
  {
    name: "event_equip_exist_status",
    type: BlockType.Hat,
    color: "#5399ff",
    lines: [
      [
        { text: "当" },
        {
          field: FieldType.Dropdown,
          option: IN_PORTS(),
          direct: true,
        },
        { text: "状态为" },
        { field: FieldType.Dropdown, option: ["有", "无"] },
        { text: "时" },
      ],
    ],
    compiler: "// @EVENT EQUIP_EXIST_STATUS $0 $1\n",
  },
  {
    name: "event_change_text",
    type: BlockType.Hat,
    color: "#5399ff",
    lines: [
      [
        { text: "当文本" },
        {
          field: FieldType.Dropdown,
          option: IN_PORTS(),
          direct: true,
        },
        { text: "变化时" },
      ],
    ],
    compiler: "// @EVENT CHANGE_TEXT $0\n",
  },
  {
    name: "event_compare_text",
    type: BlockType.Hat,
    color: "#5399ff",
    lines: [
      [
        { text: "当文本" },
        {
          field: FieldType.Dropdown,
          option: IN_PORTS(),
          direct: true,
        },
        { text: "为" },
        { field: FieldType.Text, default: "Hello" },
        { text: "时" },
      ],
    ],
    compiler: "// @EVENT COMPARE_TEXT $0 $1\n",
  },
  //设定A1的文本为hello
  {
    name: "event_control_text",
    type: BlockType.Hat,
    color: "#5399ff",
    lines: [
      [
        { text: "设定" },
        {
          field: FieldType.Dropdown,
          option: IN_PORTS(),
          direct: true,
        },
        { text: "的文本为" },
        { field: FieldType.Text, default: "Hello" },
      ],
    ],
    compiler: "// @EVENT CONTROL_TEXT $0 $1\n",
  },
  {
    name: "event_compare",
    type: BlockType.Hat,
    color: "#5399ff",
    lines: [
      [
        { text: "当数值" },
        {
          field: FieldType.Dropdown,
          option: IN_PORTS(),
          direct: true,
        },
        { field: FieldType.Dropdown, option: [">", "<", "=", ">=", "<=", "≠"]},
        { field: FieldType.Number, default: "10" },
        { text: "时" },
      ],
    ],
    compiler: "// @EVENT COMPARE $0 $1 $2\n",
  },
  {
    name: "events_do",
    type: BlockType.Statement,
    lines: [
      [{ text: "执行A" }, { statement: null }],
      [{ text: "执行B" }, { statement: null }],
    ],
  },
  {
    name: "console_print",
    type: BlockType.Statement,
    lines: [[{ text: "输出" }, { field: FieldType.Text, default: "hello" }]],
  },
  {
    name: "math_add",
    type: BlockType.Output,
    lines: [
      [
        { field: FieldType.Number, default: "10" },
        { text: "+" },
        { field: FieldType.Number, default: "20" },
      ],
    ],
  },
];

RhineBlock.registerBlocksData(eventBlocks);

export default eventBlocks;
