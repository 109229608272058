import {Cell, Graph} from "@antv/x6";
import {createLogicNodeData} from "./components/cells/NodeData";
import RxHistory from "./history/RxHistory";
import LogicNode from "./components/cells/DeviceNode/LogicNode";
import {RhineBlock} from "../../../RhineBlock/core/RhineBlock";
import {getDeviceById} from "../data/devices/devices";

export default class RX {
  // 画布对象
  static graph: Graph|null = null
  // 是否已初始化画布
  static hadGraph() {
    return Boolean(this.graph)
  }

  // 页面加载进度
  static initProgress = 0
  // 增加加载进度
  static addInitProgress() {
    this.initProgress ++
  }
  // 获取当前加载进度
  static getInitProgress() {
    return this.initProgress
  }

  // 缩放画布
  static scale(up: boolean = true) {
    if (up) {
      this.graph?.zoom(+0.2);
    } else {
      this.graph?.zoom(-0.2);
    }
  }

  // 通过节点及其端口id获取端口上的连线实例
  static getEdgeByPort(node: any, portId: string) {
    const edges: any = RX.graph!.getConnectedEdges(node)
    for (const e of edges) {
      if (e.source.port === portId || e.target.port === portId) {
        return e
      }
    }
  }

  // 初始化画布
  static initGraph = (ref: any, validateEdge: any, onEdgeLabelRendered: any) => {
    // 设置对当前连接端口
    const setConnecting = (targetPort: any) => {
      const nodes = this.graph!.getNodes();
      for (const node of nodes) {
        node.setData({
          device: node.data.device,
          connecting: targetPort,
          update: node.data.update ? node.data.update + 1 : 1,
        }, {
          // silent: node === nodes[nodes.length - 1],
          // ignore: true,
        });
      }
    };
    // 清空连接状态
    const clearConnecting = () => {
      const nodes = this.graph!.getNodes();
      for (const node of nodes) {
        node.setData({
          device: node.data.device,
          connecting: null,
          update: node.data.update ? node.data.update + 1 : 1,
        }, {
          // silent: node === nodes[nodes.length - 1],
          // ignore: true,
        });
      }
    };
    // 创建X6画布实例
    this.graph = new Graph({
      container: ref,
      autoResize: true,
      panning: {
        enabled: false,
        eventTypes: ["leftMouseDown", "mouseWheel"],
      },
      mousewheel: {
        enabled: false,
        factor: 1.1,
        maxScale: 1.5,
        minScale: 0.5,
      },
      history: {
        enabled: true,
        beforeAddCommand(event, args: any) {
          if (args.options) {
            if(args.options.ignore){
              // console.debug('Ignore add History:', event, args)
              return false
            }
          }
          // console.log('Add History:', event, args)
          return true
        },
      },
      clipboard: {
        enabled: true,
      },
      highlighting: {
        magnetAdsorbed: {
          name: "stroke",
          args: {
            attrs: {
              fill: "#fff",
              stroke: "#31d0c6",
              strokeWidth: 4,
            },
          },
        },
      },
      selecting: {
        enabled: true,
        rubberEdge: true,
        rubberNode: true,
        rubberband: true,
        multiple: true,
      },
      connecting: {
        snap: true,
        allowBlank: false,
        allowLoop: false,
        allowNode: false,
        allowEdge: false,
        highlight: true,
        allowMulti: "withPort",
        connector: "algo-connector",
        connectionPoint: "anchor",
        anchor: "center",
        validateMagnet({magnet, view}) {
          const pid: string = magnet.getAttribute("port")!;
          for (const edge of view.graph.getEdges()) {
            const tp = (edge.getSource() as any).port;
            if (tp === pid) return false;
          }
          const ms = pid.split("-");
          // let result = magnet.getAttribute('port-group') !== 'left' && ms[2] !== 'ADD'
          const result = ms[2] !== "ADD";
          (window as any).dragRight = magnet.getAttribute("port-group") !== "left";
          if (result) {
            RxHistory.startBatch('RX StartGesture')
            setConnecting(magnet.getAttribute("port"));
            const onStopConnecting = () => {
              clearConnecting();
              document.removeEventListener("mouseup", onStopConnecting);
              return true;
            };
            document.addEventListener("mouseup", onStopConnecting);
          }
          return result;
        },
        validateConnection({
                             targetPort,
                             targetMagnet,
                             targetView,
                             targetCell,
                           }) {
          for (const edge of targetView!.graph.getEdges()) {
            const tp = (edge.getTarget() as any).port;
            if (tp === targetPort) return false;
            const sp = (edge.getSource() as any).port;
            if (sp === targetPort) return false;
          }
          const device = (targetCell as any).store.data.data.device;
          const isLeft = !(window as any).dragRight;
          if (device) {
            const dd = getDeviceById(device)
            if (dd) {
              const supportPorts = isLeft ? dd.outputText : dd.inputText
              if (supportPorts.length === 0) return false
            }
          }
          const ms = targetPort!.split("-");
          if (isLeft) {
            return ms[1] === "OUT";
          } else {
            return ms[1] === "IN";
          }
        },
        validateEdge: validateEdge,
        createEdge() {
          return RX.graph?.createEdge({
            shape: "device-edge",
            attrs: {
              line: {},
              data: { disable: false, showDisable: false } // 附加线状态
            },
            zIndex: -1,
          });
        },
      },
      onEdgeLabelRendered: onEdgeLabelRendered,
    });
    return this.graph
  }

  // 当前是否正在缩放逻辑板
  static isResizing = false

  // 准备显示端口细节
  static readyShowPort = false

  // 设置当前悬浮的端口
  static setPortHover = (x:number, y:number, data?: string[][]) => {}
  static setPortShow = (show:boolean) => {}

  // 连线状态改变事件
  static onChangeEdgeStatus = () => {}

  // 设置连线禁用状态
  static setPortStatus = () => {}

  // 创建新设备ID
  static createNewDeviceId() {
    let dn = 0;
    // 检查当前用到的序号
    this.graph?.getNodes().map((node) => {
      if(node.id[0]==='N'){
        const p = parseInt(node.id.substring(1), 10);
        if (p > dn) dn = p;
      }
    });
    dn += 1;
    return dn;
  }

  static dispatchX = 0
  static dispatchY = 0
  static setDispatchPosition = (x: number, y: number) => {
    this.dispatchX = x
    this.dispatchY = y
  }

  static freshMarqueeEventListeners: Map<string, () => void> = new Map()

  static addFreshMarqueeEventListeners = (id: string, listener: () => void) => {
    this.freshMarqueeEventListeners.set(id, listener)
  }

  static freshMarquee = () => {
    this.freshMarqueeEventListeners.forEach((listener)=>{
      listener()
    })
  }

  // 获取节点ID
  static getNodeById(id: string) {
    for (const tn of this.graph!.getNodes()) {
      if (tn.id === id) {
        return tn;
      }
    }
  }

  // 删除元素
  static removeCell(cell: Cell) {
    try{
      const selected = this.graph!.getSelectedCells()
      if(!selected.includes(cell)){
        cell.remove();
      }else{
        RxHistory.startBatch('RX RemoveAllSelectedCell')
        selected.forEach((cc)=>{
          cc.remove();
        })
        RxHistory.stopBatch('RX RemoveAllSelectedCell')
      }
    }catch (e) {
      // console.error(e);
    }
  }

  // 新建逻辑板
  static newLogicNode(x: number, y: number, blocks: any = [], w: number = 336, h: number = 280) {
    const dn = RX.createNewDeviceId();
    const node = createLogicNodeData(dn, x, y, blocks, w, h)
    // gData.cells.push(node);
    this.graph?.addNode(node);
  }

  // 获取节点两侧端口数量
  // return [左侧端口数量, 右侧端口数量]
  static getPortNum(node: any) {
    let ln = 0
    let rn = 0
    node.port.ports.map((tp: any, i: any) => {
      tp.group === "left" ? ln++ : rn++;
    });
    return [ln, rn]
  }

  // 获取画布缩放
  static getZoom() {
    if (this.graph) return this.graph.zoom()
    return 1
  }

  // 清空所有选中内容
  static cleanSelection = () => {
    this.graph?.cleanSelection()
    RhineBlock.clearSelected()
  }

  static getNodeByPort = (port: any) => {
    const nodes = this.graph?.getNodes()
    if (!nodes) return null
    let node = null
    for (const n of nodes) {
      const ports = n.getPorts()
      for (const p of ports) {
        if (p.id === port.id) {
          node = n
          break
        }
      }
    }
    return node
  }

  static passDealOverlay = false
}

