import {devicesData, devicesTags, devicesTestData} from "./devicesData";

export class Device {
  id = 'TEMP'
  name = 'UNKNOWN-DEVICE'
  img = ''
  show = true
  classes = []

  inputText = []
  outputText = []

  constructor(str) {
    const msg = str.split('\n').filter(s => s&&s.trim())
    const nameAndId = msg[0].trim().split(' ')
    if(nameAndId.length>1){
      this.id = nameAndId[1].trim()
      this.img = "./app/editor/device/" + this.id + ".svg"
    }else{
      this.show = false
    }
    this.name = nameAndId[0].trim()
    if(msg.length>1){
      const line = msg[1].trim()
      if(line!=='-'){
        this.inputText = line.split(' ')
      }
    }
    if(msg.length>2){
      const line = msg[2].trim()
      if(line!=='-'){
        this.outputText = line.split(' ')
      }
    }
    if(msg.length > 3) {
      this.classes = msg[3].trim().split(' ')
    }
  }
}

export const tags = initTags();
export const devices = initDevices();

export function filterDevices(devices, text = '', classStr = '全部') {
  let results = []
  if (classStr !== '全部') {
    for (const device of devices) {
      if(device.classes.indexOf(classStr) > -1) {
        results.push(device)
      }
    }
  } else {
    if(text === '') return devices
    for (const device of devices) {
      if(device.name.indexOf(text) > -1) {
        results.push(device)
      }
    }
  }
  return results
}

function initDevices() {
  const deviceList = []
  devicesTestData.split("\n\n").map((deviceStr, i) => {
    deviceList.push(new Device(deviceStr))
  });
  return deviceList;
}

function initTags() {
  const tags = devicesTags.trim().split(' ')
  tags.unshift('全部')
  return tags
}

export function getDeviceById(id) {
  for (const device of devices) {
    if (device.id === id) {
      return device;
    }
  }
  return null;
}
