import {BlockType, IBlock} from "../core/block/block.class";
import {RhineBlock} from "../core/RhineBlock";
import {FieldType} from "../core/block/arg.class";
import {JsOrderComplete} from "../core/compiler/js/js-compiler";
import {IN_PORTS, OUT_PORTS} from "./static-value";

const Order = JsOrderComplete;

const serviceBlock: IBlock[] = [
  {
    name: "service_media_set_url",
    type: BlockType.Statement,
    color: "#8bd900",
    lines: [
      [
        {text: "设置接口"},
        {
          field: FieldType.Dropdown,
          option: OUT_PORTS(),
          default: 0,
        },
        {text: "的"},
        { field: FieldType.Dropdown, option: ["视频", "图片", "音频"]},
        {text: "的地址为"},
        { field: FieldType.Text, default: "http://xx.com/xx.mp4"},
      ]
    ],
  },
  {
    name: "service_weather_get",
    type: BlockType.Output,
    output: "num",
    color: "#8bd900",
    lines: [
      [
        {text: "获取"},
        {field: FieldType.Dropdown,option: ["北京"],},
        {text: "省"},
        { field: FieldType.Dropdown, option: ["北京",]},
        {text: "市 的"},
        { field: FieldType.Dropdown, option: ["当前温度", "当前湿度", "最高湿度", "最低温度"]},
      ]
    ],
  },
  {
    name: "service_weather_get_now",
    type: BlockType.Output,
    output: "num",
    color: "#8bd900",
    lines: [
      [
        {text: "获取"},
        {field: FieldType.Dropdown,option: ["北京"],},
        {text: "省"},
        { field: FieldType.Dropdown, option: ["北京",]},
        {text: "市 当前"},
        { field: FieldType.Dropdown, option: ["天气", "风速", "风向", "风力", "地面气压", "降雨量"]},
      ]
    ],
  },
  {
    name: "service_weather_get_air",
    type: BlockType.Output,
    output: "num",
    color: "#8bd900",
    lines: [
      [
        {text: "获取"},
        {field: FieldType.Dropdown,option: ["北京"],},
        {text: "省"},
        { field: FieldType.Dropdown, option: ["北京",]},
        {text: "市 当前"},
        { field: FieldType.Dropdown, option: ["空气质量", "空气指数", "PM2.5颗粒物", "PM10颗粒物", "首要污染物", "SO2小时均值", "NO2小时均值", "CO小时均值", "O3小时均值"]},
      ]
    ],
  },
  {
    name: "service_weather_get_sun",
    type: BlockType.Output,
    output: 'num',
    color: "#8bd900",
    lines: [
      [
        {text: "获取"},
        {field: FieldType.Dropdown,option: ["北京"],},
        {text: "省"},
        { field: FieldType.Dropdown, option: ["北京",]},
        {text: "市 的"},
        { field: FieldType.Dropdown, option: ["日出时间", "日落时间"]},
      ]
    ],
  },
];

RhineBlock.registerBlocksData(serviceBlock);

export default serviceBlock;
