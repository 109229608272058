import Style from "./MakeFlower.module.css";

const FORCE_MORE = true; // 强制使用新版花朵
const BOTTOM_DELETE = false; // 花瓣下方含删除按钮

/*
创建一个花瓣选择交互
e: MouseEvent   原生点击事件。来源于被点击用于创建花朵的元素，位置处为花瓣圆心。
source: string  原始状态。创建花瓣所属端口上原本的文本。
shape: string   节点类型。logic-node为逻辑板，device-node为设备节点。
portText: string[]  端口选项列表。花瓣中的可选文本。
callback: function  交互结果回调。当用户选中选项，或关闭花瓣时进行回调。
end: function       结束回调。当结束一次交互时回调，用作通用收尾操作。
*/
export function makeFlower(e, source, shape, portText, callback, end) {
  const addMode = source === "+" // 是否为新增端口模式
  let isMore = (!(portText.length<=5 || (portText.length<=7 && addMode))) || FORCE_MORE // 是否为显示大量选项模式
  let size = isMore ? 13 : 7 // 花瓣尺寸 大量选项模式为13，正常模式为7。

  let types = []; // 花瓣内容
  for (let i = 0; i < size; i++) {
    types.push("") // 先填充空文本
  }
  if (!addMode) {
    types[0] = source
    if(BOTTOM_DELETE) types[size - 1] = "删除" // 非添加模式下，下方花瓣为删除接口按键
    for (let i = 1; i < portText.length + 1; i++) {
      i = parseInt(i)
      if (i > size-(BOTTOM_DELETE?2:1)) break
      types[i] = portText[i - 1]
    }
  } else {
    for (let i = 0; i < portText.length; i++) {
      i = parseInt(i)
      if (i > size-1) break
      types[i] = portText[i]
      if (i === 0) types[0] += "\n(推荐)" // 添加模式时中心花瓣显示推荐选项
    }
  }

  // 计算花瓣坐标
  const SHOW_BIAS = 10; // 花瓣显示偏移量
  const x = e.pageX - SHOW_BIAS;
  const y = e.pageY - SHOW_BIAS;
  const r = isMore ? 120 : 78;
  // 计算花瓣角度
  let angles = [null, 0, 300, 60, 240, 120, 180];
  if(isMore){
    angles = [null, 0, 330, 30, 300, 60, 270, 90, 240, 120, 210, 150, 180];
  }

  // 覆盖全屏，用于拦截失去焦点事件，花瓣交互最优先。
  const full = document.createElement("div");
  full.className = Style.full;
  // 花瓣外侧容器
  const flower = document.createElement("div");
  const pos = isMore ? 172 : 115
  flower.className = Style.flower;
  flower.style.left = x - pos + "px";
  flower.style.top = y - pos + "px";
  // const img = document.createElement('img');
  // img.src = './app/editor/flower.svg'
  // 花瓣关闭事件
  const closeFlower = () => {
    flower.className = Style.flower;
    // 等待动画播放完毕真正删除元素
    setTimeout(() => {
      full.remove();
    }, 200);
  }
  // 当背景填充被点击，触发关闭花瓣
  full.onclick = (e) => {
    closeFlower()
    if (end) end();
  };
  full.oncontextmenu = (e) => {
    closeFlower()
    if (end) end();
  };
  document.body.appendChild(full);

  // 获取花瓣classname，用于xml创建。
  const getPetalClass = (i) => {
    return types[i].length > 0 ? Style.petal : (Style.petal + ' ' + Style.petalEmpty)
  }

  // 将svg资源文件中文本直接进行组合
  flower.innerHTML = size === 13 ? `
    <svg width="345" height="344" viewBox="0 0 345 344" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle class="${getPetalClass(0)}" cx="172.869" cy="172" r="51.5"/>
      <path   class="${getPetalClass(1)}" d="M177.277 3.34878C174.648 1.43895 171.089 1.43895 168.46 3.34878L139.143 24.6492C136.656 26.4561 135.523 29.5979 136.285 32.5761L152.925 97.6019C153.774 100.921 156.765 103.243 160.191 103.243H185.547C188.973 103.243 191.963 100.921 192.812 97.6019L209.452 32.5761C210.214 29.5979 209.082 26.4561 206.595 24.6492L177.277 3.34878Z"/>
      <path   class="${getPetalClass(2)}" d="M92.4262 23.7229C89.1948 23.3833 86.1122 25.163 84.7906 28.1313L70.0512 61.2367C68.8008 64.0451 69.3908 67.3322 71.54 69.5304L118.463 117.525C120.858 119.974 124.609 120.49 127.576 118.777L149.535 106.099C152.502 104.386 153.931 100.88 153.007 97.5809L134.904 32.947C134.075 29.9867 131.523 27.8322 128.466 27.5109L92.4262 23.7229Z"/>
      <path   class="${getPetalClass(3)}" d="M260.949 28.1308C259.627 25.1625 256.544 23.3828 253.313 23.7224L217.273 27.5104C214.216 27.8317 211.664 29.9863 210.835 32.9465L192.732 97.5804C191.808 100.879 193.237 104.385 196.204 106.098L218.163 118.776C221.13 120.489 224.881 119.974 227.276 117.524L274.199 69.5299C276.348 67.3317 276.938 64.0446 275.688 61.2362L260.949 28.1308Z"/>
      <path   class="${getPetalClass(4)}" d="M29.1289 83.7937C26.1606 85.1152 24.3808 88.1978 24.7205 91.4292L28.5084 127.469C28.8298 130.526 30.9843 133.078 33.9446 133.907L98.5784 152.01C101.878 152.934 105.383 151.505 107.096 148.538L119.774 126.579C121.487 123.612 120.972 119.861 118.522 117.466L70.5279 70.543C68.3298 68.3939 65.0427 67.8038 62.2343 69.0542L29.1289 83.7937Z"/>
      <path   class="${getPetalClass(5)}" d="M321.02 91.4277C321.36 88.1963 319.58 85.1137 316.612 83.7921L283.506 69.0526C280.698 67.8022 277.411 68.3923 275.213 70.5414L227.219 117.465C224.769 119.86 224.253 123.61 225.967 126.577L238.644 148.536C240.357 151.503 243.863 152.932 247.162 152.008L311.796 133.906C314.756 133.077 316.911 130.525 317.232 127.468L321.02 91.4277Z"/>
      <path   class="${getPetalClass(6)}" d="M3.94427 176.573C1.90712 173.769 1.90712 169.973 3.94427 167.169L25.2447 137.851C27.1721 135.198 30.5233 133.99 33.7 134.803L98.7259 151.443C102.266 152.349 104.743 155.539 104.743 159.193L104.743 184.549C104.743 188.203 102.266 191.393 98.7259 192.299L33.7 208.939C30.5233 209.752 27.1721 208.544 25.2447 205.891L3.94427 176.573Z"/>
      <path   class="${getPetalClass(7)}" d="M341.393 176.279C343.303 173.651 343.303 170.091 341.393 167.463L320.093 138.145C318.286 135.658 315.144 134.526 312.166 135.288L247.14 151.927C243.821 152.777 241.5 155.767 241.5 159.193L241.5 184.549C241.5 187.975 243.821 190.966 247.14 191.815L312.166 208.454C315.144 209.217 318.286 208.084 320.093 205.597L341.393 176.279Z"/>
      <path   class="${getPetalClass(8)}" d="M28.9275 260.407C25.7613 258.997 23.8629 255.709 24.2252 252.262L28.0131 216.222C28.3559 212.961 30.6541 210.239 33.8117 209.355L98.4455 191.252C101.965 190.267 105.704 191.791 107.531 194.956L120.209 216.915C122.036 220.079 121.487 224.08 118.874 226.635L70.8794 273.558C68.5347 275.851 65.0285 276.48 62.0329 275.146L28.9275 260.407Z"/>
      <path   class="${getPetalClass(9)}" d="M321.018 252.316C321.358 255.547 319.578 258.63 316.61 259.952L283.504 274.691C280.696 275.941 277.409 275.351 275.211 273.202L227.217 226.279C224.767 223.884 224.252 220.133 225.965 217.166L238.642 195.207C240.355 192.24 243.861 190.811 247.16 191.735L311.794 209.838C314.754 210.667 316.909 213.219 317.23 216.276L321.018 252.316Z"/>
      <path   class="${getPetalClass(10)}" d="M92.4262 320.02C89.1948 320.36 86.1122 318.58 84.7906 315.612L70.0512 282.506C68.8008 279.698 69.3908 276.411 71.54 274.213L118.463 226.219C120.858 223.769 124.609 223.253 127.576 224.966L149.535 237.644C152.502 239.357 153.931 242.863 153.007 246.162L134.904 310.796C134.075 313.756 131.523 315.911 128.466 316.232L92.4262 320.02Z"/>
      <path   class="${getPetalClass(11)}" d="M260.949 315.612C259.627 318.581 256.544 320.36 253.313 320.021L217.273 316.233C214.216 315.911 211.664 313.757 210.835 310.797L192.732 246.163C191.808 242.864 193.237 239.358 196.204 237.645L218.163 224.967C221.13 223.254 224.881 223.769 227.276 226.219L274.199 274.213C276.348 276.411 276.938 279.699 275.688 282.507L260.949 315.612Z"/>
      <path   class="${getPetalClass(12)}" d="M177.277 340.394C174.648 342.304 171.089 342.304 168.46 340.394L139.143 319.094C136.656 317.287 135.523 314.145 136.285 311.167L152.925 246.141C153.774 242.822 156.765 240.5 160.191 240.5H185.547C188.973 240.5 191.963 242.822 192.812 246.141L209.452 311.167C210.214 314.145 209.082 317.287 206.595 319.094L177.277 340.394Z"/>
    </svg>
  ` : `
    <svg width="230" height="232" viewBox="0 0 230 232" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle class="${getPetalClass(0)}" cx="114.914" cy="116.006" r="36"/>
      <path   class="${getPetalClass(1)}" d="M144.899 22.3131L119.616 3.94427C116.812 1.90712 113.016 1.90712 110.212 3.94427L84.9293 22.3131C82.1254 24.3502 80.9521 27.9612 82.0231 31.2574L91.6802 60.9787C92.7512 64.2749 95.8228 66.5066 99.2886 66.5066H130.54C134.005 66.5066 137.077 64.2749 138.148 60.9787L147.805 31.2574C148.876 27.9612 147.703 24.3502 144.899 22.3131Z"/>
      <path   class="${getPetalClass(2)}" d="M18.7332 95.1305L15.4666 64.0508C15.1043 60.604 17.0027 57.3159 20.1689 55.9062L48.718 43.1953C51.8842 41.7857 55.598 42.5751 57.9171 45.1507L78.828 68.3746C81.1471 70.9502 81.5439 74.7262 79.811 77.7277L64.1856 104.792C62.4527 107.793 58.9842 109.337 55.5941 108.617L25.0261 102.119C21.636 101.399 19.0955 98.5773 18.7332 95.1305Z"/>
      <path   class="${getPetalClass(3)}" d="M211.04 95.13L214.307 64.0503C214.669 60.6035 212.771 57.3154 209.605 55.9057L181.055 43.1949C177.889 41.7852 174.175 42.5746 171.856 45.1502L150.945 68.3741C148.626 70.9497 148.23 74.7257 149.962 77.7272L165.588 104.791C167.321 107.793 170.789 109.337 174.179 108.616L204.747 102.119C208.137 101.398 210.678 98.5768 211.04 95.13Z"/>
      <path   class="${getPetalClass(4)}" d="M48.7727 188.817L20.2236 176.106C17.0574 174.696 15.159 171.408 15.5213 167.961L18.7879 136.882C19.1502 133.435 21.6907 130.613 25.0808 129.893L55.6488 123.395C59.0389 122.675 62.5074 124.219 64.2403 127.221L79.8657 154.285C81.5986 157.286 81.2017 161.062 78.8827 163.638L57.9717 186.862C55.6527 189.437 51.9389 190.227 48.7727 188.817Z"/>
      <path   class="${getPetalClass(5)}" d="M181.001 188.817L209.55 176.107C212.716 174.697 214.614 171.409 214.252 167.962L210.986 136.882C210.623 133.435 208.083 130.614 204.693 129.893L174.125 123.396C170.735 122.675 167.266 124.22 165.533 127.221L149.908 154.285C148.175 157.287 148.572 161.063 150.891 163.638L171.802 186.862C174.121 189.438 177.835 190.227 181.001 188.817Z"/>
      <path   class="${getPetalClass(6)}" d="M144.899 209.687L119.616 228.056C116.812 230.093 113.016 230.093 110.212 228.056L84.9293 209.687C82.1254 207.65 80.9521 204.039 82.0231 200.743L91.6802 171.021C92.7512 167.725 95.8228 165.493 99.2886 165.493H130.54C134.005 165.493 137.077 167.725 138.148 171.021L147.805 200.743C148.876 204.039 147.703 207.65 144.899 209.687Z"/>
    </svg>
  `;
  full.appendChild(flower);
  // 立刻触发展开动画
  setTimeout(() => {
    flower.classList.add(Style.flowerShow);
  }, 0);

  // 为花瓣添加点击事件
  const petals = flower.children[0].children;
  for (const i in types) {
    if (i > size) break;
    if (types[i] === "") continue;
    // 花瓣中可点击元素
    const btn = document.createElement("div");
    btn.className = Style.text;
    // 设置花瓣坐标及角度
    let x = 172
    let y = 172
    if (i === "0" && source === "+") {
      btn.style.left = x + "px";
      btn.style.top  = y + 1 + "px";
      btn.style.lineHeight = "25px";
    } else if (i === "0" && source !== "+") {
      btn.style.left = x + "px";
      btn.style.top  = y + "px";
    } else {
      btn.style.left = x + Math.sin((angles[i] / 180) * Math.PI) * r + "px";
      btn.style.top  = y - Math.cos((angles[i] / 180) * Math.PI) * r + "px";
    }
    // 添加花瓣内部文本
    const p = document.createElement('p')
    const str = types[i]
    if(str.split('\n')[0].length > 6) {
      p.style.fontSize = '12px'
    }else if(str.length === 4) {
      p.style.paddingInline = '4px'
    }
    btn.appendChild(p)
    p.innerText = str;
    flower.appendChild(btn);
    // 添加花瓣点击事件
    petals[i].onclick = (e) => {
      let str = types[i].split("\n")[0];
      window.position = {
        left: e.clientX,
        top: e.clientY,
      };
      callback(str);
    };
  }
}


