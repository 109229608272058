import { BlockType, IBlock } from "../core/block/block.class";
import { RhineBlock } from "../core/RhineBlock";
import { FieldType } from "../core/block/arg.class";

const loopBlocks: IBlock[] = [
  {
    name: "loop_times",
    type: BlockType.Statement,
    color: "#ffac5d",
    lines: [
      [
        { text: "重复" },
        { field: FieldType.Text, default: "10" },
        { text: "次" },
      ],
      [{ text: "执行" }, { statement: null }],
    ],
    toolbox: [
      // {block: 'math_add', args: [{block: 'math_add', args: [{block: 'number_random_float'}]}]},
      // {block: 'control_if', args: [null, {block: 'console_print'}]},
    ],
    compiler: (args) => {
      const [times, statement] = args;
      const v = RhineBlock.Compiler.VariableProvider.newVariable("i").name;
      return `for(let ${v}=0;${v}<${times};${v}++){\n${statement}}\n`;
    },
  },
  {
    name: "loop_single",
    type: BlockType.Statement,
    color: "#ffac5d",
    lines: [
      [
        { text: "无限重复 " },
      ],
      [{ text: "执行" }, { statement: null }],
    ],
    toolbox: [
      // {block: 'math_add', args: [{block: 'math_add', args: [{block: 'number_random_float'}]}]},
      // {block: 'control_if', args: [null, {block: 'console_print'}]},
    ],
    compiler: "while(true){\n$0}\n",
  },
  {
    name: "loop_conditions",
    type: BlockType.Statement,
    color: "#ffac5d",
    lines: [
      [{ text: "当满足" }, { value: "bool" }, { text: "时重复" }],
      [{ text: "执行" }, { statement: null }],
    ],
    toolbox: [
      // {block: 'math_add', args: [{block: 'math_add', args: [{block: 'number_random_float'}]}]},
      // {block: 'control_if', args: [null, {block: 'console_print'}]},
    ],
    compiler: "while($0){\n$1}\n",
  },
  {
    name: "loop_break",
    type: BlockType.Finish,
    color: "#ffac5d",
    lines: [[{ text: "跳出循环" }]],
    compiler: "break\n",
  },
];

RhineBlock.registerBlocksData(loopBlocks);

export default loopBlocks;
