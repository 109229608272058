import React from "react";
import Style from "./DeviceNode.module.css";
import { getDeviceById } from "../../../../data/devices/devices";
import "./DeviceNodeX6.css";
import { onPortClick } from "../../ports/PublicPort/NodeCallbacks";
import Paper from "@mui/material/Paper";
import { Popover } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import PublicPort from "../../ports/PublicPort/PublicPort";
import RX from "../../../RX";
import RxHistory from "../../../history/RxHistory";

// 获取端口是否已有连线
export function portHadEdge(portId){
  if(!RX.graph) return false;
  for (const edge of RX.graph.getEdges()) {
    if (edge.getTarget().port === portId) {
      return true
    }
    if(edge.getSource().port === portId){
      return true
    }
  }
  return false
}

// 设置端口是否可连接
export function setCanConnect(view, canConnect){
  if(view.classList.contains(Style.portCanConnect)){
    if(!canConnect){
      view.classList.remove(Style.portCanConnect)
    }
  }else{
    if(canConnect){
      view.classList.add(Style.portCanConnect)
    }
  }
}

export class DeviceNode extends React.Component {
  constructor() {
    super();
    // 初始化状态参数
    this.state = {
      anchorEl: null,
      anchorElNode: null,
      disable: false,
      anchorPort: null,
      anchorPortEl: null,
      portDisableMode: -1, // -1不显示 0禁用 1启用
      showNote: false,
    };
    this.updateFlag = false;
  }

  // 设置禁用状态
  setDisable(status) {
    this.setState({
      disable: status
    })
    // this.updateFlag = true;
  }

  // 设置注释展示状态
  setShowNote(status) {
    this.setState({
      showNote: status
    })
  }

  // 检查是否要更新渲染
  shouldComponentUpdate() {
    if (this.updateFlag) {
      this.updateFlag = false;
      return true;
    }
    const node = this.props.node;
    if (node) {
      if (node.hasChanged("data")) {
        return true;
      }
    }
    return false;
  }

  setAnchorEl(v) {
    this.setState({
      anchorEl: v,
    });
    this.updateFlag = true;
  }
  setAnchorElNode(v) {
    this.setState({
      anchorElNode: v,
    });
    this.updateFlag = true;
  }
  setAnchorElPort(el, port) {
    this.setState({
      anchorElPort: el,
      anchorPort: port,
    });
    this.updateFlag = true;
  }

  setPortDisableMode(v){
    if(v !== 0 && v !== 1) v = -1
    this.setState({
      portDisableMode: v,
    });
    this.updateFlag = true;
  }

  render() {
    const { node } = this.props;
    const data = node?.getData();
    let device = "";
    if (data) device = getDeviceById(data.device);
    const ports = node.getPorts();

    let getPosition = () => {
      return window.position;
    };
    // 展开右键菜单
    const handleContextMenu = (e) => {
      // 设置右键菜单锚点
      this.setAnchorEl(e.currentTarget);
      this.setAnchorElNode(e.currentTarget);
      // 设置右键菜单坐标
      window.position = { top: e.clientY, left: e.clientX };
    };
    // 关闭右键菜单
    const handleClose = () => {
      // 清空锚点
      this.setAnchorEl(null)
    };
    // 右键菜单是否展开
    const open = Boolean(this.state.anchorEl)
    const id = open ? "popover" : undefined
    // 删除节点
    const onDeleteNode = () => {
      RxHistory.startBatch('DeviceNode DeleteNode')
      RX.graph?.getConnectedEdges(node).forEach((edge) => {
        edge.attr(["data", "disable"], false)
        RX.onChangeEdgeStatus(edge, false)
      })
      RX.removeCell(node)
      RxHistory.stopBatch('DeviceNode DeleteNode')
    }

    // 设置端口禁用状态
    const setPortDomDisable = (node, port, disable) => {
      let data = port.attrs['data'];
      const dom = document.querySelector(`[id='${port.id}']`)
      let originColor = dom.style['backgroundColor']

      if (!data) {// 防止attr为空
        node.setPortProp(port, ['attrs', 'data', 'disable'], disable);
        try {
          port.attrs['data'].disable
        } catch (e) { // todo 紧急使用，并非持久化方案
          port = node.getPort(port.id)
        }
        data = port.attrs['data'];
      }
      node.setPortProp(port, ['attrs', 'data'], {disable: disable});
      if (!port.attrs['data'].originColor) {
        port = node.getPort(port.id)
      }
      const isLeft = port.group === "left";
      if (disable) {
        dom.style['background-color'] = isLeft ? "#529BF1" : "#B375E0";
      } else {
        dom.style['background-color'] = isLeft ? "#abccff" : "#d6b8ff";
      }
    }

    // 变更设备的状态
    const changeStatus = (disable = !this.state.disable, ignore = false) => {
      RxHistory.startBatch('DeviceNode ChangeStatus')

      this.setDisable(disable)

      const cells = RX.graph.isSelected(node) ? RX.graph.getSelectedCells() : [node]
      cells.map(cell=>{
        if(!cell.isNode()) return

        if(!ignore){
          cell.setData({
            isDisable: disable,
            update: data.update ? data.update + 1 : 1,
          })
        }
        // console.log(this.state.disable)
        cell.attr(['data', 'disable', this.state.disable])

        // 子端口也禁用
        for (let port of cell.ports.items) {
          setPortDomDisable(cell, port, this.state.disable)
        }

        if(RX.graph){
          for (const edge of RX.graph.getConnectedEdges(cell)) {
            let state = !this.state.disable
            const edgeState = edge.attr(['data', 'disable'])
            const anotherNode = edge.getSourceCell() === cell ? edge.getTargetCell() : edge.getSourceCell()
            const anotherState = anotherNode.getData().isDisable
            RX.onChangeEdgeStatus(edge, state || anotherState || edgeState)
          }
        }
      })

      handleClose()
      RxHistory.stopBatch('DeviceNode ChangeStatus')
    }

    const checkDisable = () => {
      const dataIsDisable = node.getData().isDisable || false
      if(dataIsDisable !== this.state.disable){
        this.setDisable(dataIsDisable)
      }
      return dataIsDisable
    }
    const dataIsDisable = checkDisable()

    // 同步数据中禁用状态

    // dom禁用这状态 置灰文本
    const el = this.state.anchorElNode
    if(el){
      if (!this.state.disable) { // todo 临时方案 直接动dom
        el.classList.remove('device', 'disable')
        el.parentElement.parentElement.children[1].style['color'] =  "#000"
      } else {
        el.classList.add('device', 'disable')
        el.parentElement.parentElement.children[1].style['color'] =  "#666"
      }
    }

    // 右键菜单相关
    const openPort = Boolean(this.state.anchorElPort);
    const idPort = open ? "popover" : undefined;
    const handleContextPort = (port, e) => {
      if (port.text === "+") {
        onPortClick(node, port, e);
      } else {
        this.setAnchorElPort(e.currentTarget, port);
        window.position = { top: e.clientY, left: e.clientX };
        const edge = RX.getEdgeByPort(node, port.id)
        let sourceDisable = edge?.getSourceCell().getData().isDisable
        let targetDisable = edge?.getTargetCell().getData().isDisable
        if(!edge || sourceDisable || targetDisable){
          this.setPortDisableMode()
        }else{
          let edgeDisable = edge.attr(['data', 'disable']) || false
          this.setPortDisableMode(edgeDisable ? 1 : 0)
        }
      }
    };
    const handleClosePort = () => {
      this.setAnchorElPort(null, null);
      this.setPortDisableMode()
    };
    const onDeletePort = () => {
      handleClosePort()
      if (this.state.anchorElPort) {
        RxHistory.startBatch('DeviceNode DeletePort')
        node.removePort(this.state.anchorElPort);
        // 计算端口数
        let ln = 0
        let rn = 0
        node.getPorts().map(p => {
          p.group === 'left' ? ln++ : rn++
        })
        let mn = ln > rn ? ln : rn
        // 需要时变化大小
        if (mn > 3) {
          node.resize(208, 112 + (mn - 4) * 32)
        }
        RxHistory.stopBatch('DeviceNode DeletePort')
      }
    };

    // 端口点击事件传递
    const handleClickPort = (port, e) => {
      onPortClick(node, port, e);
    }
    // 端口禁用
    const setPortDisable = (disable) => {
      handleClosePort()
      const port = this.state.anchorPort
      const edge = RX.getEdgeByPort(node, port.id)
      if(!edge) return
      RxHistory.startBatch('DeviceNode PortDisable')
      edge.attr(['data', 'disable'], disable)
      RX.onChangeEdgeStatus(edge, disable)
      RxHistory.stopBatch('DeviceNode PortDisable')
    }

    const stopPropagation = e => e.stopPropagation()
    const pdm = this.state.portDisableMode // 端口禁用状态

    const note = data.note || ''
    const changeShowNote = e => {
      e.stopPropagation()
      this.setState({showNote: !this.state.showNote})
    }

    return (
      <div className={Style.node}>
        <div className={Style.holder}>
          <div className={Style.connectors}  style={{left: 0, padding: '10px 0'}} onMouseDown={stopPropagation}>
            {ports.map((port, i) => {
              if (port.group === "left") {
                // eslint-disable-next-line react/jsx-key
                return <PublicPort port={port} node={node} key={i} i={i} handleClickPort={handleClickPort} handleContextPort={handleContextPort}/>
              }
            })}
          </div>
          <img
            className={Style.note}
            src={'./icon/comment-blue.svg'}
            style={{display: note.length ? 'block' : 'none'}}
            alt={''}
            onClick={changeShowNote}/>
          <div className={Style.img} onContextMenu={handleContextMenu}>
            <div className={"node-border"}>
              <div />
              <div />
              <div />
              <div />
            </div>
            <img src={device.img} style={{opacity: dataIsDisable ? '0.5' : '1.0'}} alt={""} className={['eCatEye', 'fishTank', 'gate', 'gateway', 'sensor'].includes(device.id) ? 'defaultSize' : Style.needSize} />
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={this.state.anchorEl}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={getPosition()}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Paper sx={{ width: 136 }} className={Style.OptionPaper}>
              <MenuList dense>
                <MenuItem onClick={onDeleteNode}>删除设备</MenuItem>
                <MenuItem onClick={e => changeStatus()}>
                  { checkDisable() ? "启用" : "禁用" }
                </MenuItem>
                {/*<MenuItem onClick={e => this.setShowNote(true)}>*/}
                {/*  { note.length ? "添加注释" : "删除注释" }*/}
                {/*</MenuItem>*/}
              </MenuList>
            </Paper>
          </Popover>
          <div className={Style.connectors} style={{right: 0, padding: '10px 0'}} onMouseDown={stopPropagation}>
            {ports.map((port, i) => {
              if (port.group === "right") {
                // eslint-disable-next-line react/jsx-key
                return <PublicPort port={port} node={node} key={i} i={i} handleClickPort={handleClickPort} handleContextPort={handleContextPort}/>
              }
            })}
          </div>
        </div>
        <div className={Style.label} style={{color: dataIsDisable ? '#666' : '#000'}}>{device.name}</div>
        <Popover
          id={idPort}
          open={openPort}
          anchorEl={this.state.anchorElPort}
          onClose={handleClosePort}
          anchorReference="anchorPosition"
          anchorPosition={getPosition()}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Paper sx={{ width: 136 }} className={Style.OptionPaper}>
            <MenuList dense>
              <MenuItem onClick={onDeletePort}>删除接口</MenuItem>
              {
                pdm !== -1 ? <MenuItem onClick={()=>setPortDisable(pdm === 0)}>{ pdm === 1 ? '启用' : '禁用' }</MenuItem> : null
              }
            </MenuList>
          </Paper>
        </Popover>
      </div>
    );
  }
}

export default DeviceNode;
