
// 调出更亮的颜色
export function adjustColorBright(color: string, range: number) {
  let newColor = "#";
  for (let i = 0; i < 3; i++) {
    const hxStr = color.substr(i * 2 + 1, 2);
    let val = parseInt(hxStr, 16);
    val += range;
    if (val < 0) val = 0;
    else if (val > 255) val = 255;
    newColor += val.toString(16).padStart(2, "0");
  }
  return newColor;
}

//hex -> rgb
export const hexToRgb = (hex: string) => {
  return 'rgb(' + parseInt('0x' + hex.slice(1, 3)) + ',' + parseInt('0x' + hex.slice(3, 5))
      + ',' + parseInt('0x' + hex.slice(5, 7)) + ')';
}

//hex -> rgba
export const hexToRgba = (hex: string, opacity: number) => {
  return 'rgba(' + parseInt('0x' + hex.slice(1, 3)) + ',' + parseInt('0x' + hex.slice(3, 5)) + ','
      + parseInt('0x' + hex.slice(5, 7)) + ',' + opacity + ')';
}

export const toRGBObject = ( rgbStr: any) => {
  const [red, green, blue] = rgbStr.match(/\d+/g).map(Number);

  return {red, green, blue};
}
