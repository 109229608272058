import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#42A4FF",
      contrastText: "#ffffff",
    },
    neutral: {
      main: "#666666",
      contrastText: "#666666",
    },
    gray: {
      main: "#999999",
      contrastText: "#999999",
    },
  },
});

export default lightTheme;
