import Style from "./EnterExist.module.css";
import React, {useState} from "react";
import {
  Alert,
  Snackbar,
} from "@mui/material";
import {tip} from "../../App";

function EnterExist(props) {
  const project = props.project
  const name = project.name
  const thumbnail = project.thumbnail
  const date = project.updateTime
  const state = project.status === 0 ? 0 : 1

  const [openToast, setOpenToast] = React.useState(false);
  const [toastMsg, setToastMsg] = React.useState("");
  const toast = (msg) => {
    // setToastMsg(msg);
    // setOpenToast(true);
    tip(msg, "success")
  };

  return (
    <div
      className={Style.EnterExist}
      onContextMenu={props.handleClick}
      onClick={props.onClick}
    >
      <img
        className={Style.img}
        // style={{display: thumbnail ? 'inline' : 'none'}}
        src={thumbnail && thumbnail.length ? thumbnail : "/app/start/frame1.png"}
        alt={""}
      />
      <div className={Style.info}>
        <span className={Style.name}>{name}</span>
        <span className={Style.date}>
          {(props.create ? "创建于 " : "更新于 ") + new Date(date).toLocaleDateString()}
        </span>
      </div>
      <div
        className={Style.state}
        style={{
          background: state === 0 ? "#FF842C" : "#42A4FF"
        }}
      >
        <span className={Style.stateText}>
          {state === 0 ? "草稿" : "已发布"}
        </span>
      </div>
      <p className={Style.coverDiv} />
      <Snackbar
          open={openToast}
          autoHideDuration={3000}
          onClose={undefined}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert
            severity="success"
            variant="outlined"
            sx={{ width: "320px", background: "#f7fff7" }}
        >
          {toastMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default EnterExist;
