import RX from "../../RX";

// 创建设备节点X6数据
export function createNodeData(dn, device, p, scale) {
  return {
    id: "N" + dn,
    shape: "device-node",
    data: {
      device: device.id,
    },
    x: p.x - 104 * scale,
    y: p.y - 66 * scale,
    ports: [
      {
        id: dn + "-IN-ADD",
        group: "left",
        text: "+",
        attrs: {circle: {r: 0}},
      },
      {
        id: dn + "-OUT-ADD",
        group: "right",
        text: "+",
        attrs: {circle: {r: 0}},
      },
    ],
  };
}

// 创建逻辑板节点X6数据
export function createLogicNodeData(dn, x, y, blocks = [], w, h) {
  return {
    id: "N" + dn,
    shape: "logic-node",
    data: {
      blocks: blocks,
    },
    x: x,
    y: y,
    width: w,
    height: h,
    ports: [
      {
        id: dn + "-IN-1",
        group: "left",
        text: "A1",
      },
      {
        id: dn + "-IN-ADD",
        group: "left",
        text: "+",
        attrs: {rect: {w: 34, h: 20}},
      },
      {
        id: dn + "-OUT-1",
        group: "right",
        text: "B1",
      },
      {
        id: dn + "-OUT-ADD",
        group: "right",
        text: "+",
        attrs: {rect: {w: 34, h: 20}},
      },
    ],
  };
}

// 创建连线数据
export function createEdgeData(sourceCellId, sourcePortId, targetCellId, targetPortId) {

}

