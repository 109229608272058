import Block from "./block.class";
import { parseType, sourceType } from "../render/base/type-parse";

// 参数类 用于表示图形块中的每一个元素 如文本，输入框，下拉菜单，图形块拼接口，等
export default class Arg {
  content: Block | string | boolean | number | null = null // 当前内容值
  option: string[] = [] // 当ArgType==Field且FieldType==Dropdown时生效，表示下拉菜单列表
  optionCode: string[] = []
  direct: boolean = false
  default: any // 默认值

  text: string = ""; // 仅当文本模式启用，表示文本内容
  fieldType: FieldType = FieldType.None; // 仅当填充模式启用，表示填充类型
  valueType: string[] = [""]; // 仅当输入图形块模式启用，表示输入图形块类型
  statementType: sourceType = ""; // 仅当语句块输入模式启用，表示下方语句块类型

  onValueChange: (arg: Arg) => void = () => {}; // 当数据发生变化时

  view: SVGElement | null = null; // dom元素
  x: number = 0;
  y: number = 0;
  w: number = 0;
  h: number = 0;

  constructor(public id: number, public type: ArgType) {}

  // 更新布局位置
  updateViewPosition(bias: number[] = [0, 0]): void {
    if (this.view) {
      this.view.setAttribute(
        "transform",
        `translate(${this.x + bias[0]}, ${this.y + bias[1]})`
      );
    }
  }

  // 是图形块拼接口参数
  isBlockType(): boolean {
    return this.type === ArgType.Statement || this.type === ArgType.Value;
  }

  // 清空参数布局
  clearView(): void {
    this.content = null;
    if (this.view) {
      this.view.remove();
    }
    this.x = 0;
    this.y = 0;
    this.w = 0;
    this.h = 0;
    this.view = null;
  }

  // 通过接口构造参数实例
  static fromJson(id: number, data: IArg): Arg {
    if (!data.type) {
      if (data.text !== undefined) {
        data.type = ArgType.Text;
      } else if (data.field !== undefined) {
        data.type = ArgType.Field;
      } else if (data.value !== undefined) {
        data.type = ArgType.Value;
      } else if (data.statement !== undefined) {
        data.type = ArgType.Statement;
      } else {
        data.type = ArgType.Text;
      }
    }
    const arg = new Arg(id, data.type);
    arg.text = data.text || "";
    arg.fieldType = data.field || FieldType.Text;
    arg.valueType = parseType(data.value);
    arg.statementType = data.statement || "";
    arg.option = data.option || [];
    arg.optionCode = data.optionCode || [];
    if (data.direct !== undefined) {
      arg.direct = data.direct;
    }
    if (data.content) {
      arg.content = data.content;
    }
    if (data.default) {
      arg.default = data.default;
    } else {
      arg.default = arg.fieldType === FieldType.Dropdown ? 0 : "";
    }
    return arg;
  }

  static fromLineStr(id: number, line: string): Arg {
    return new Arg(id, ArgType.Text);
  }
}

// 参数接口
export interface IArg {
  type?: ArgType;
  content?: any;
  default?: any;
  direct?: boolean;

  text?: string;
  field?: FieldType;
  value?: sourceType;
  statement?: sourceType;

  option?: string[];
  optionCode?: string[] | null;
}

// 参数类型
export enum ArgType {
  Text, // 文本
  Field, // 填充 如: 输入框，下拉菜单
  Value, // 值型图形块品接口
  Statement, // 语句型图形块品接口
}

// 填充类型
export enum FieldType {
  None = -1, // 无
  Text, // 文本输入框
  Number, // 数字输入框
  Password, // 密码输入框
  Dropdown, // 下拉菜单
  Checkbox, // 复选框
  Image, // 图片选择器
  Color, // 颜色选择器
}
