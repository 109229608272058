import { createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#42A4FF",
      contrastText: "#ffffff",
    },
    neutral: {
      main: "#666666",
    },
  },
});

export default darkTheme;
