import {Graph, Node} from "@antv/x6";
import RX from "../RX";
import Direction from "./Direction"

class DealOverlay {
  static graph: Graph
  static nodes : DoNode[] = []

  static INTERVAL = 10 // 节点之间基本间隔距离

  // 处理一个节点移动后周围节点的联动
  static dealFromMoveOne(node: Node<Node.Properties>) {
    this.freshRange()
    const [ni, nn] = this.getNodeIndexAndDoNode(node)
    for (const cn of this.nodes.concat()) {
      if (cn.node.id === nn.node.id) continue
      if (this.isOverlay(cn, nn)) {
        // console.log(nn, cn)
        const [d, dis] = this.findNearestMoveDirection(nn, cn)
        this.dealFromOneSingleDirection(cn.node, d, dis)
      }
    }
  }

  // 处理单个节点单向移动重叠逻辑及联动情况 该节点将保持不动
  static dealFromOne(node: Node<Node.Properties>, direction: Direction) {
    this.freshRange()

    for(const d of this.parseDirection(direction)) {
      this.dealFromOneSingleDirection(node, d)
    }
  }

  static dealFromOneSingleDirection(node: Node<Node.Properties>, direction: Direction, distance?: number) {
    this.sortNodes(direction)
    const isHorizontal = this.isHorizontal(direction)
    const [ni, nn] = this.getNodeIndexAndDoNode(node)
    if (distance) {
      if (direction == Direction.top) nn.move(0, -distance - this.INTERVAL)
      if (direction == Direction.left) nn.move(-distance - this.INTERVAL, 0)
      if (direction == Direction.bottom) nn.move(0, distance + this.INTERVAL)
      if (direction == Direction.right) nn.move(distance + this.INTERVAL, 0)
    }
    for(let ci = ni + 1; ci<this.nodes.length; ci++){
      const cn = this.nodes[ci] // 当前被动移动节点
      let dst = -1
      for(let ei = ni; ei < ci; ei++) {
        const en = this.nodes[ei] // 最终比较节点
        if (this.isOverlayLine(cn, en, !isHorizontal)) {
          if (direction == Direction.right && cn.x < en.x + en.w + this.INTERVAL) {
            dst = en.x + en.w + this.INTERVAL
          } else if (direction == Direction.bottom && cn.y < en.y + en.h + this.INTERVAL) {
            dst = en.y + en.h + this.INTERVAL
          } else if (direction == Direction.left && cn.x + cn.w > en.x - this.INTERVAL) {
            dst = en.x - cn.w - this.INTERVAL
          } else if (direction == Direction.top && cn.y + cn.h > en.y - this.INTERVAL) {
            dst = en.y - cn.h - this.INTERVAL
          }
        }
      }
      if (dst != -1) {
        if (isHorizontal) {
          cn.setPosition(dst, cn.y)
        } else {
          cn.setPosition(cn.x, dst)
        }
      }
    }
  }

  // 预处理方向 将复合方向转为多个单向方向
  static parseDirection(direction: Direction): Direction[] {
    switch (direction) {
      case Direction.left:
        return [Direction.left];
      case Direction.right:
        return [Direction.right];
      case Direction.top:
        return [Direction.top];
      case Direction.bottom:
        return [Direction.bottom];
      case Direction.leftBottom:
        return [Direction.left, Direction.bottom];
      case Direction.leftTop:
        return [Direction.left, Direction.top];
      case Direction.rightBottom:
        return [Direction.right, Direction.bottom];
      case Direction.rightTop:
        return [Direction.right, Direction.top];
    }
  }

  // 判断一个四向方向是否为一个水平方向,否则为垂直
  static isHorizontal(direction: Direction): boolean {
    return direction === Direction.left || direction === Direction.right
  }

  // 按照指定方向排序节点 不支持复合方向
  static sortNodes(direction: Direction): void {
    const isHorizontal = this.isHorizontal(direction)
    const isReverse = direction == Direction.left || direction == Direction.top
    this.nodes.sort((n1, n2) => {
      const a = isHorizontal ? n1.x : n1.y
      const b = isHorizontal ? n2.x : n2.y
      return isReverse ? b - a : a - b
    })
  }
  
  // 比较两个节点是否重叠
  static isOverlay(n1: DoNode, n2: DoNode): boolean {
    return this.isOverlayLine(n1, n2, true) && this.isOverlayLine(n1, n2, false)
  }

  // 比较两个节点在一个方向是否重叠
  static isOverlayLine(n1: DoNode, n2: DoNode, isHorizontal = true): boolean {
    if (isHorizontal) {
      const t1 = this.isMiddle(n1.x, n2.x, n2.getRight())
      const t2 = this.isMiddle(n1.getRight(), n2.x, n2.getRight())
      const t3 = this.isMiddle(n2.x, n1.x, n1.getRight())
      return t1 || t2 || t3
    } else {
      const t1 = this.isMiddle(n1.y, n2.y, n2.getBottom())
      const t2 = this.isMiddle(n1.getBottom(), n2.y, n2.getBottom())
      const t3 = this.isMiddle(n2.y, n1.y, n1.getBottom())
      return t1 || t2 || t3
    }
  }

  // 寻找最近的移动方向
  static findNearestMoveDirection(node: DoNode, moveNode: DoNode): [Direction, number] {
    const ds = []
    ds.push(moveNode.getBottom() - node.getTop())
    ds.push(node.getRight() - moveNode.getLeft())
    ds.push(node.getBottom() - moveNode.getTop())
    ds.push(moveNode.getRight() - node.getLeft())
    let n = Math.min(...ds)
    let i = ds.indexOf(n)
    if (node.x == moveNode.x && node.w == moveNode.w) {
      i = node.y > moveNode.y ? 0 : 2
      n = ds[i]
    }
    if (node.y == moveNode.y && node.h == moveNode.h) {
      i = node.x > moveNode.x ? 3 : 1
      n = ds[i]
    }
    return [[Direction.top, Direction.right, Direction.bottom, Direction.left][i], n]
  }

  // 刷新所有节点范围
  static freshRange(): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.graph = RX.graph!
    this.nodes = []
    this.graph.getNodes().forEach(node => {
      this.nodes.push(new DoNode(node))
    })
  }
  
  static isMiddle(n: number, min: number, max: number) {
    return n >= min && n <= max
  }

  // 获取节点所在序号 注:需判断是否找到请检查第一个返回参数是否为-1
  static getNodeIndexAndDoNode(node: Node<Node.Properties>): [number, DoNode] {
    for (let i = 0; i < this.nodes.length; i++) {
      if (this.nodes[i].node == node) return [i, this.nodes[i]]
    }
    return [-1, this.nodes[0]]
  }
}

export default DealOverlay;

class DoNode {
  node: Node<Node.Properties>
  x: number = -1
  y: number = -1
  w: number = -1
  h: number = -1
  isLogic: boolean = false

  getBottom() {
    return this.y + this.h
  }
  getRight() {
    return this.x + this.w
  }
  getTop() {
    return this.y
  }
  getLeft() {
    return this.x
  }

  constructor(node: Node<Node.Properties>) {
    this.node = node
    this.isLogic = this.node.data.blocks !== undefined
    this.freshRange()
  }

  setPosition(x: number = -1, y: number = -1) {
    if (x == -1) x = this.x
    if (y == -1) y = this.y
    this.x = x
    this.y = y
    this.node.setPosition(x, y + (this.isLogic ? 54 : 10))
  }

  move(x: number = 0, y: number = 0) {
    this.setPosition(this.x + x, this.y + y)
  }

  freshRange() {
    const {x, y, width, height} = this.node.getBBox()
    this.x = x
    this.y = y - (this.isLogic ? 54 : 10)
    this.w = width
    this.h = height + (this.isLogic ? 74 : 44)
  }
}


