import Style from "../../cells/DeviceNode/DeviceNode.module.css";
import {portHadEdge, setCanConnect} from "../../cells/DeviceNode/DeviceNode";
import {dispatchConnect, getAllow, getBackground} from "./NodeCallbacks";
import React, {useState} from "react";
import RX from "../../../RX";
import {getDeviceById} from "../../../../data/devices/devices";

// 公共端口
export default function PublicPort(props) {
  const {port, node, i, handleClickPort, handleContextPort, isLogic} = props
  const graph = RX.graph;
  if(!graph) return null;

  // 端口状态
  const isLeft = port.group === "left";
  const isAdd = port.text !== "+";
  const [marquee, setMarquee] = useState(graph.isSelected(node) && port.text.length>2);
  // console.log('addFreshMarqueeEventListeners', node.id, port.id)
  RX.addFreshMarqueeEventListeners(port.id, ()=>{
    setMarquee(graph.isSelected(node) && port.text.length>2);
  })

  let status = true;
  // 获取端口状态
  const portStatus = node.getPortProp(port, ['attrs', 'data', 'disable'])
  if (undefined !== portStatus) { // 判断是否为空，如果为空则初始化
    status = portStatus // 状态反转
  } else {// 初始化附加状态
    node.setPortProp(port, ['attrs', 'data', 'disable'], true, {ignore: true})
  }

  // 点击事件
  const onClick = (e) => {
    RX.cleanSelection()
    if(!isAdd || !isLogic) {
      handleClickPort(port, e)
    }
    e.stopPropagation()
  }
  // 右键事件
  const onContextMenu = (e) => {
    RX.cleanSelection()
    if(handleContextPort){
      handleContextPort(port, e)
    }else{
      handleClickPort(port, e)
    }
    e.preventDefault()
  }

  // 获取接口详情信息
  const getPortData = () => {
    const node = RX.getNodeByPort(port)
    let deviceName = '无'
    if (node) {
      const device = node.data.device
      if (!device) {
        deviceName = '逻辑板'
      } else {
        deviceName = getDeviceById(device).name
      }
    }
    const edge = RX.getEdgeByPort(node, port.id)
    let toPort = '未使用'
    if (edge) {
      let node = RX.getNodeById(edge.source.cell)
      let toPortId = edge.source.port
      if (toPortId === port.id) {
        node = RX.getNodeById(edge.target.cell)
        toPortId = edge.target.port
      }
      if (node) {
        toPort = node.getPort(toPortId).text
      }
    }
    return [
      ["接口名", port.text],
      ["所属设备", deviceName],
      ["接口类型", isLeft ? "输入接口" : "输出接口"],
      [isLeft ? "接受数据类型" : "输出数据类型", "状态"],
      [isLeft ? "数据来源接口" : "输出到接口", toPort],
    ]
  }
  // 鼠标悬浮事件
  const onMouseOver = (e) => {
    if (isAdd) {
      const pageX = e.clientX - e.nativeEvent.offsetX + 19;
      const pageY = e.clientY - e.nativeEvent.offsetY - 10;
      RX.readyShowPort = true
      RX.setPortHover(pageX, pageY, getPortData())
      setTimeout(() => {
        if (RX.readyShowPort) {
          RX.setPortShow(true)
        }
      }, 400)
      setCanConnect(e.target, !portHadEdge(port.id))
    }else{
      setCanConnect(e.target, false)
    }
  }
  // 鼠标移出事件
  const onMouseOut = () => {
    RX.setPortShow(false)
    RX.readyShowPort = false
  }
  // 鼠标点下事件
  const onMouseDown = (e) => {
    e.stopPropagation()
    if (isLogic) {
      RX.setDispatchPosition(e.clientX, e.clientY)
      dispatchConnect(port.id)
    }
  }
  const style = {
    background: getBackground(node, port, isLeft,status),
    pointerEvents: getAllow(node, port, isLeft) ? "auto" : "none",
    cursor: isLogic ? "crosshair" : "pointer",
  }
  let className = Style.port + ' ' + (isLeft ? Style.portLeft : Style.portRight)
  return (
    <div
      className={Style.portHolder}
      key={(isLeft ? "L" : "R") + i}
    >
      <span
        id={port.id}
        className={className}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onContextMenu={onContextMenu}
        onMouseOverCapture={onMouseOver}
        onMouseOutCapture={onMouseOut}
        style={style}
      >
        {
          marquee ? (
            <marquee scrollamount={3}>
              {port.text}
            </marquee>
          ) : (
            <span>
              {port.text}
            </span>
          )
        }
        <p/>
      </span>
    </div>
  )
}


