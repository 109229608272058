
// 变量管理器 管理编译时用到的变量
export default class VariableProvider {
  static variables: Variable[] = [];

  // 添加变量
  static addVariable(variable: Variable) {
    this.variables.push(variable);
  }

  // 获取变量
  static getVariable(name: string): Variable | null {
    for (const variable of this.variables) {
      if (variable.name === name) {
        return variable;
      }
    }
    return null;
  }

  // 新建变量
  static newVariable(
    name: string = "a",
    type: string = VariableType.Any,
    value: any = "",
    global: boolean = false,
    add = true
  ): Variable {
    name = this.newVariableName(name);
    const v: Variable = {name, type, value, global};
    if (add) {
      this.addVariable(v);
    }
    return v;
  }

  // 新建变量名
  static newVariableName(name = "a"): string {
    if (name.length === 1) {
      let i = name.charCodeAt(0) - "a".charCodeAt(0);
      while (this.checkNameExists(name)) {
        i++;
        if (i > "z".charCodeAt(0)) break;
        name = String.fromCharCode(i + "a".charCodeAt(0));
      }
    }
    if (this.checkNameExists(name)) {
      let i = 2;
      while (this.checkNameExists(name + i)) i++;
      name += i;
    }
    return name
  }

  // 检查变量名是否已存在
  static checkNameExists(name: string): boolean {
    return this.getVariable(name) !== null;
  }

  // 清空变量
  static clear() {
    this.variables = [];
  }

}

// 变量接口
export interface Variable {
  name: string;
  type: string | VariableType;
  value: string;
  global: boolean;
}

// 变量数据类型
export enum VariableType {
  Number = "number",
  String = "string",
  Boolean = "boolean",
  Array = "array",
  Object = "object",
  Function = "function",
  Any = "any",
}

