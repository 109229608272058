import Block, {Item, OpacityType} from "../block/block.class";
import SvgElCreator from "../utils/svg-el-creator";
import Arg, {ArgType} from "../block/arg.class";
import {ChangeEventType, iotZoom, RhineBlock} from "../RhineBlock";
import "./drag-view.css";
import {checkContains} from "../render/base/type-parse";
import {Graph} from "../view/graph/graph";
import {checkInRubbishBin} from "../../../App/Editor/Editor";
import RX from "../../../App/Editor/RX/RX";
import RxHistory from "../../../App/Editor/RX/history/RxHistory";
import {Option} from "../../../App/Editor/RX/Option";

// 图形块拖拽管理器
// 用于管理图形块拖拽 并在落点处显示阴影等相关效果
export default class DragManager {
  static DRAG_VIEW_ID = "rb-drag-view";
  static NEAR_DIS = 24;

  static MOVE_OUT_DIS = 120;

  static USE_RESET = false;
  static RESET_POS_CATCH = 5; // 移动溢出画布时回弹感知范围
  static RESET_POS_MOVE = 40; // 移动溢出画布时回弹感知范围
  static RESET_WIDTH = 20; // 感知默认扩展宽度

  static USE_THROW = true; // 使用覆盖图形块时弹出原图形块，否则则直接删除
  static THROW_BIAS = 60; // 弹出偏移量

  static dragBlock: Block | null = null;
  static fromGraph: Graph | null = null; // 从何逻辑板移出
  static fromToolbox: boolean = false; // 是否来自工具箱画布
  static inputs: InputPuzzle[] = []; // 当前可拼接接口

  static offset: number[] = [0, 0]; // 当前鼠标相对拖拽块左上角的偏移量
  static dragView: SVGElement | null = null; // 当前鼠标拖拽布局
  static borderView: HTMLDivElement | null = null;

  static current: Block | null = null; // 鼠标移动时当前落点的临时渲染图形块实例
  static throwBlock: Block | null = null;
  static downEvent: MouseEvent | null = null;

  // 当拖起图形块
  static onDragBlockDown(
    block: Block,
    e: MouseEvent,
    inToolbox: boolean = false
  ) {
    RhineBlock.onRemoveFocus(e);
    this.dragBlock = block;
    this.fromGraph = this.dragBlock.getGraph()
    this.fromToolbox = block.getGraph()?.isToolbox || false

    const svg = this.newDragView(inToolbox);
    (svg.style as any).zoom = RhineBlock.getZoom();
    RhineBlock.Render.render(block.clone(), svg);
    const rect = (e.target as SVGPathElement).getBoundingClientRect();
    this.offset = [e.clientX - rect.x, e.clientY - rect.y + 2];
    this.downEvent = e

    if (!inToolbox) {
      block.parent?.setArgFromContent(block, null, true);
      block.remove(false);
    } else {
      if (!block.parent) {
        block.view?.setAttribute("filter", "url(#blur)");
        RhineBlock.onRemoveBlur = () => {
          block.view?.setAttribute("filter", "");
        };
      }
    }

    RhineBlock.mapGraph((graph) => {
      const zoom = RhineBlock.getZoom()
      graph.recurBlocks((tb) => {
        if (tb === block) return;
        tb.mapBlockArgs((arg) => {
          if (!tb.view) return;
          if (
            (block.isOutputType() &&
              arg.type === ArgType.Value &&
              checkContains(block.output, arg.valueType)) ||
            (block.hadPrevious() && arg.type === ArgType.Statement)
          ) {
            const rect = tb.view.getBoundingClientRect();
            // this.log(tb.name, rect.y, arg.y)
            this.inputs.push({
              block: tb,
              arg: arg,
              position: [rect.x + arg.x * zoom, rect.y + arg.y * zoom],
              temp: undefined,
            });
          }
        });
      });
    });
    this.log(this.inputs);

    const onDragBlockMove = (e: MouseEvent) => this.onDragBlockMove(e);
    const onDragBlockUp = (e: MouseEvent) => {
      document.removeEventListener("mousemove", onDragBlockMove);
      document.removeEventListener("mouseup", onDragBlockUp);
      this.onDragBlockUpEasy(e);
    };
    onDragBlockMove(e);
    document.addEventListener("mousemove", onDragBlockMove);
    document.addEventListener("mouseup", onDragBlockUp);
    e.stopPropagation();
  }

  // 当图形块移动
  static onDragBlockMove(e: MouseEvent) {
    if (!this.dragView) return;

    const position = this.getEventBlockPosition(e);
    this.setDragViewPosition(position);
    let near: InputPuzzle | null = null;
    let currentDis = this.NEAR_DIS;
    const zoom = RhineBlock.getZoom()
    for (const input of this.inputs) {
      const dis = this.getDis(position, input.position);
      if (dis <= currentDis * zoom) {
        near = input;
        currentDis = dis;
      }
    }
    const graph = this.getPosGraph([e.clientX, e.clientY], 0);
    if (this.dragView && !this.fromToolbox) {
      this.dragView.style.opacity = graph ? '1.0' : '0.3'
    }
    if (graph && near) {
      this.removeDragShadow(near);
      if (near.temp === undefined) {
        if (near.arg.isBlockType() && near.arg.content) {
          near.temp = near.arg.content as Block;
        } else {
          near.temp = null;
        }
        this.log("SetTemp", near.temp);
        if (this.dragBlock) {
          const item: Item = this.dragBlock.getItem()
          this.log("ReRender", item);
          const hadNext = near.temp && near.arg.type === ArgType.Statement;
          near.block.setArgByItem(near.arg, item, !hadNext, true);
          RhineBlock.setSelected(near.arg.content as Block);
          this.current = near.arg.content as Block;
          if (hadNext) {
            this.log("SetNext", item);
            let inner = near.arg.content as Block;
            while (inner.hadNext() && inner.next.content) {
              inner = inner.next.content as Block;
            }
            if (inner.hadNext()) {
              if (near.temp) near.temp.isOpacity = OpacityType.False;
              inner.setArgByBlock(inner.next, near.temp, true);
              this.setThrow(null);
            } else {
              inner.setArgByBlock(inner.next, null, true);
              this.setThrow(near.temp);
            }
          } else {
            this.setThrow(near.temp);
          }
        } else {
          this.log("Remove", near.block.name);
          near.block.setArgByItem(near.arg, null, true);
        }
      }
    } else {
      this.removeDragShadow();
      this.current = null;
      this.setThrow(null);
    }
  }

  // 设置弹出图形块  当移动到原有值型图形块处，需要弹开原来的图形块
  static setThrow(block: Block | null) {
    if (this.throwBlock && !block) this.log("RemoveThrow");
    this.throwBlock = block;
    if (block) this.log("SetThrow", this.throwBlock);
  }

  // 删除拖拽阴影
  static removeDragShadow(expect: InputPuzzle | null = null): void {
    for (const input of this.inputs) {
      if (expect && input === expect) continue;
      if (input.temp !== undefined) {
        this.log("RemoveFrom", input.block.name, input.temp);
        input.block.setArgByBlock(input.arg, input.temp, true);
        input.temp = undefined;
      }
    }
  }

  // 当放下图形块
  static onDragBlockUpEasy(e: MouseEvent) {
    RhineBlock.onRemoveBlur();
    DragManager.clearDragView();
    const item = this.dragBlock?.getItem();
    if (!item) return;

    const pos = this.getEventBlockPosition(e);
    const graph = this.getPosGraph([e.clientX, e.clientY], 0);
    if (graph) {
      if(checkInRubbishBin(e.clientX, e.clientY)) {
        this.dragBlock?.onChange(ChangeEventType.REMOVE);
      }else{
        const rect = graph.svg.getBoundingClientRect();
        if (this.current) {
          RhineBlock.Render.clearOpacity(this.current);
          if (this.throwBlock && this.USE_THROW) {
            const throwItem = this.throwBlock.getItem();
            const cr = this.current.view!.getBoundingClientRect();
            throwItem.x = cr.x - rect.x + this.THROW_BIAS;
            throwItem.y = cr.y - rect.y + this.THROW_BIAS;
            const num = graph.render([throwItem], undefined, false);
            const block = graph.blocks[num - 1];
            RhineBlock.setSelected(block);
          }
          this.current.onChange(ChangeEventType.MOVE);
        } else {
          item.x = pos[0] - rect.x;
          item.y = pos[1] - rect.y;
          if(this.USE_RESET){
            if (item.x > rect.width - this.RESET_POS_CATCH) item.x = rect.width - this.RESET_POS_MOVE - this.RESET_WIDTH
            if (item.y > rect.height - this.RESET_POS_CATCH) item.y = rect.height - this.RESET_POS_MOVE - this.RESET_WIDTH
            if (item.x < this.RESET_POS_CATCH) item.x = this.RESET_POS_MOVE
            if (item.y < this.RESET_POS_CATCH) item.y = this.RESET_POS_MOVE
          }
          this.log("RenderToGraph", item);
          const num = graph.render([item], undefined, false);
          const block = graph.blocks[num - 1];
          if(this.getEventMoveLen(this.downEvent!, e) < 3) {
            RhineBlock.setSelected(block);
          }
          if (block.getGraph() == this.fromGraph) {
            block.onChange(ChangeEventType.MOVE);
          } else {
            RxHistory.startBatch('DragManager Drag')
            this.fromGraph?.onChange(ChangeEventType.MOVE, block)
            block.onChange(ChangeEventType.MOVE);
            RxHistory.stopBatch('DragManager Drag')
          }
        }
      }
      this.final(true);
    } else {
      if (this.fromToolbox) {
        const BLOCK_BIAS = 25;
        const pos = this.getEventBlockPosition(e);
        if (
          pos[0] > 301 &&
          pos[0] < document.documentElement.clientWidth - 301 &&
          pos[1] > 60
        ) {
          const iotGraph = RX.graph!;
          const posG = iotGraph.clientToLocal(pos[0], pos[1]);
          item.x = BLOCK_BIAS;
          item.y = BLOCK_BIAS;
          RX.newLogicNode(posG.x - 100, posG.y - 100, [item]);
        }
      } else {
        this.dragBlock?.onChange(ChangeEventType.REMOVE);
      }
      this.final(false);
    }
  }

  static getEventMoveLen(e1: MouseEvent, e2:MouseEvent) {
    const a = Math.abs(e1.clientX - e2.clientX)
    const b = Math.abs(e1.clientY - e2.clientY)
    return Math.sqrt(a*a + b*b)
  }

  static onDragBlockUp(e: MouseEvent) {
    DragManager.clearDragView();
    const item = this.dragBlock?.getItem();

    if (!item) return;

    const pos = this.getEventBlockPosition(e);
    let graph = this.getPosGraph(pos);
    if (!graph) {
      graph = RhineBlock.getFirstGraphWithoutToolbox();
    }
    if (!graph) {
      console.error("No graph for block", item);
      this.final(false);
      return;
    }
    if (graph.isToolbox) {
      this.final(false);
      return;
    }

    const rect = graph.svg.getBoundingClientRect();
    if (this.current) {
      // 接入其他图形块
      RhineBlock.Render.clearOpacity(this.current);
      this.current.onChange(ChangeEventType.MOVE);
      if (this.throwBlock && this.USE_THROW) {
        // 弹出此处原有图形块
        const throwItem = this.throwBlock.getItem();
        const cr = this.current.view!.getBoundingClientRect();
        throwItem.x = cr.x - rect.x + this.THROW_BIAS;
        throwItem.y = cr.y - rect.y + this.THROW_BIAS;
        graph.render([throwItem], undefined, false);
        this.throwBlock.onChange(ChangeEventType.MOVE);
      }
    } else {
      // 画布空白处新增图形块
      item.x = pos[0] - rect.x;
      item.y = pos[1] - rect.y;
      if (item.x > rect.width - this.RESET_POS_CATCH)
        item.x = rect.width - this.RESET_POS_MOVE - this.RESET_WIDTH;
      if (item.y > rect.height - this.RESET_POS_CATCH)
        item.y = rect.height - this.RESET_POS_MOVE - this.RESET_WIDTH;
      if (item.x < this.RESET_POS_CATCH) item.x = this.RESET_POS_MOVE;
      if (item.y < this.RESET_POS_CATCH) item.y = this.RESET_POS_MOVE;
      this.log("RenderToGraph", item);
      const num = graph.render([item], undefined, false);
      if (num === 1) {
        graph.blocks[graph.blocks.length - 1].onChange(ChangeEventType.MOVE);
      }
    }
    this.final(true);
  }

  // 拖拽流程结束处理
  static final(success: boolean) {
    this.clearAllArgs();
    RX.graph?.cleanSelection()
  }

  // 获取指定位置上的画布
  static getPosGraph(pos: number[], out: number = 0): Graph | null {
    let graph: Graph | null = null;
    RhineBlock.graphs.some((tg) => {
      if (tg.isToolbox) return false;
      const rect = tg.svg.getBoundingClientRect();
      if (
        pos[0] >= rect.x - out &&
        pos[0] <= rect.x + rect.width + out &&
        pos[1] >= rect.y - out &&
        pos[1] <= rect.y + rect.height + out
      ) {
        graph = tg;
        return true;
      }
    });
    return graph;
  }

  // 新建拖拽布局
  static newDragView(hadBorder = false): SVGElement {
    this.clearDragView();
    const holder = document.createElement("div");
    document.body.appendChild(holder);
    holder.id = this.DRAG_VIEW_ID;
    holder.classList.add("rb-drag-view");
    SvgElCreator.appendSvg(holder, true);
    this.dragView = holder.children[0] as SVGElement;
    if (hadBorder) {
      this.borderView = document.createElement("div");
      this.borderView.classList.add("rb-drag-border");
      holder.appendChild(this.borderView);
    }
    return this.dragView;
  }

  // 通过鼠标点击事件及当前偏差量记录，计算图形块左上坐标。
  static getEventBlockPosition(e: MouseEvent): number[] {
    return [e.clientX - this.offset[0], e.clientY - this.offset[1] + 2];
  }

  // 计算两点之间距离
  static getDis(p1: number[], p2: number[]): number {
    return Math.sqrt(Math.pow(p1[0] - p2[0], 2) + Math.pow(p1[1] - p2[1], 2));
  }

  // 设置拖拽块位置
  static setDragViewPosition(position: number[]): void {
    if (!this.dragView) return;
    const r = RhineBlock.getZoom()
    this.dragView.style.left = `${position[0] / r}px`;
    this.dragView.style.top = `${position[1] / r}px`;
    const box = this.dragView
      .getElementsByTagName("g")[0]
      .getBoundingClientRect();
    const padding = 12;
    if (this.borderView) {
      this.borderView.style.left = `${(box.left - padding) * r}px`;
      this.borderView.style.top = `${(box.top - padding - 6) * r}px`;
      this.borderView.style.width = `${(box.width + padding * 2) * r}px`;
      this.borderView.style.height = `${(box.height + padding * 2) * r}px`;
    }
  }

  // 清空拖拽布局
  static clearDragView() {
    document.getElementById(this.DRAG_VIEW_ID)?.remove();
    this.dragView = null;
  }

  // 清除所有相关信息
  static clearAllArgs() {
    this.current = null;
    this.dragBlock = null;
    this.fromGraph = null;
    this.inputs = [];
    this.clearDragView();
  }

  static DEBUG_MODE = false;

  static log(...args: any[]) {
    if (this.DEBUG_MODE) console.log(...args);
  }
}

// 可拼接缺口接口
interface InputPuzzle {
  block: Block;
  arg: Arg;
  position: number[];
  temp: Block | null | undefined;
}
